// Memo: These initial prompts were designed to match a 20s video lenght
// but to be honest the Storytelling sucked, so that's why we changed to
// more scenes

// import { getPrompt as aBedStoryAboutTopic } from './aBedStoryAboutTopic';
// import { getPrompt as aDayInTheLifeOfTopic } from './aDayInTheLifeOfTopic';
// import { getPrompt as anHorrorStoryAboutTopic } from './anHorrorStoryAboutTopic';
// import { getPrompt as funFacts } from './funFacts';
// import { getPrompt as hiddenSecrets } from './hiddenSecrets';
// import { getPrompt as howTo } from './howTo';
// import { getPrompt as jokes } from './jokes';
// import { getPrompt as originsOfTopic } from './originsOfTopic';
// import { getPrompt as quotes } from './quotes';
// import { getPrompt as tips } from './tips';
// import { getPrompt as topicFAQ } from './topicFAQ';
// import { getPrompt as whatIf } from './whatIf';
// import { getPrompt as whatIfTopicDissapears } from './whatIfTopicDissapears';
// import { getPrompt as whatOrWhoIs } from './whatOrWhoIs';

// Memo: These prompts offer a more complete storrytelling YEY!!!!

import { getPrompt as aBedStoryAboutTopic } from './gpt3_5_turbo/aBedStoryAboutTopic';
import { getPrompt as aDayInTheLifeOfTopic } from './gpt3_5_turbo/aDayInTheLifeOfTopic';
import { getPrompt as anHorrorStoryAboutTopic } from './gpt3_5_turbo/anHorrorStoryAboutTopic';
import { getPrompt as funFacts } from './gpt3_5_turbo/funFacts';
import { getPrompt as hiddenSecrets } from './gpt3_5_turbo/hiddenSecrets';
import { getPrompt as howTo } from './gpt3_5_turbo/howTo';
import { getPrompt as jokes } from './gpt3_5_turbo/jokes';
import { getPrompt as originsOfTopic } from './gpt3_5_turbo/originsOfTopic';
import { getPrompt as quotes } from './gpt3_5_turbo/quotes';
import { getPrompt as tips } from './gpt3_5_turbo/tips';
import { getPrompt as topicFAQ } from './gpt3_5_turbo/topicFAQ';
import { getPrompt as whatIf } from './gpt3_5_turbo/whatIf';
import { getPrompt as whatIfTopicDissapears } from './gpt3_5_turbo/whatIfTopicDissapears';
import { getPrompt as whatOrWhoIs } from './gpt3_5_turbo/whatOrWhoIs';
import { getPrompt as freeStyle } from './gpt3_5_turbo/freeStyle';

export const prompts = [
  freeStyle,
  aBedStoryAboutTopic,
  aDayInTheLifeOfTopic,
  anHorrorStoryAboutTopic,
  funFacts,
  hiddenSecrets,
  howTo,
  jokes,
  originsOfTopic,
  quotes,
  tips,
  topicFAQ,
  whatIf,
  whatIfTopicDissapears,
  whatOrWhoIs
];

export const data = [
  {
    id: 'b9b8f7af-dd53-45e0-aa2f-49e11e31cd7a',
    text: 'Fun facts about "Taylor Swift"',
    videos: [
      {
        id: 'f0ff3fbf-a2da-4829-bb93-a89b203d9be5',
        proportion: 0.2,
        width: 1080,
        height: 1920,
        customFonts: [
          {
            fontFamily: 'Montserrat-ExtraBold',
            path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
          }
        ],
        audioTracks: [
          {
            path: 'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Happy/Morning_Walk_-_Jingle_Punks.mp3',
            mixVolume: 0.2
          },
          {
            path: 'https://vod.front10.cloud/output/CLGHUVC7TZ/zLHj6.mp3',
            loop: false
          }
        ],
        clips: [
          {
            duration: 3.869583,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'https://res.cloudinary.com/dakp804eh/image/upload/v1683857924/Woxo/Idea2video/data_set/Taylor_Swift/new/2998427767_A_picture_of_Taylor_Swift_smiling_and_looking_at_t_xl-beta-v2-2-2.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-out'
                }
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: 'HEY ',
                width: 1080,
                height: 1920,
                start: 0,
                stop: 0.228271
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' EVERYONE, ',
                width: 1080,
                height: 1920,
                start: 0.228271,
                stop: 0.641292
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' HERE ',
                width: 1080,
                height: 1920,
                start: 0.641292,
                stop: 1
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' ARE ',
                width: 1080,
                height: 1920,
                start: 1,
                stop: 1.097833
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' SOME ',
                width: 1080,
                height: 1920,
                start: 1.097833,
                stop: 1.271729
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *FUN* ',
                width: 1080,
                height: 1920,
                start: 1.271729,
                stop: 1.521729
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *FACTS* ',
                width: 1080,
                height: 1920,
                start: 1.521729,
                stop: 1.869562
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' ABOUT ',
                width: 1080,
                height: 1920,
                start: 1.869562,
                stop: 2.119583
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *TAYLOR* ',
                width: 1080,
                height: 1920,
                start: 2.119583,
                stop: 2.423917
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *SWIFT!*',
                width: 1080,
                height: 1920,
                start: 2.423917
              }
            ]
          },
          {
            duration: 9.717374999999999,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'https://res.cloudinary.com/dakp804eh/image/upload/v1683857988/Woxo/Idea2video/data_set/Taylor_Swift/new/2648304698_A_picture_of_Taylor_Swift_smiling_and_looking_at_t_xl-beta-v2-2-2.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-in'
                }
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: '*FUN* ',
                width: 1080,
                height: 1920,
                start: 1,
                stop: 2.021709
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' FACT ',
                width: 1080,
                height: 1920,
                start: 2.021709,
                stop: 2.326063
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' 1: ',
                width: 1080,
                height: 1920,
                start: 2.326063,
                stop: 2.565209
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *TAYLOR* ',
                width: 1080,
                height: 1920,
                start: 2.565209,
                stop: 3.108667
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *SWIFT* ',
                width: 1080,
                height: 1920,
                start: 3.108667,
                stop: 3.510855
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' IS ',
                width: 1080,
                height: 1920,
                start: 3.510855,
                stop: 3.6412920000000004
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THE ',
                width: 1080,
                height: 1920,
                start: 3.6412920000000004,
                stop: 3.739105
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' ONLY ',
                width: 1080,
                height: 1920,
                start: 3.739105,
                stop: 4.054334000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' ARTIST ',
                width: 1080,
                height: 1920,
                start: 4.054334000000001,
                stop: 4.402167
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' IN ',
                width: 1080,
                height: 1920,
                start: 4.402167,
                stop: 4.499979
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' HISTORY ',
                width: 1080,
                height: 1920,
                start: 4.499979,
                stop: 4.9130210000000005
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' WITH ',
                width: 1080,
                height: 1920,
                start: 4.9130210000000005,
                stop: 5.119541999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' 10 ',
                width: 1080,
                height: 1920,
                start: 5.119541999999999,
                stop: 5.358687999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' CONSECUTIVE ',
                width: 1080,
                height: 1920,
                start: 5.358687999999999,
                stop: 5.902146
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' ALBUMS ',
                width: 1080,
                height: 1920,
                start: 5.902146,
                stop: 6.282584
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' TO ',
                width: 1080,
                height: 1920,
                start: 6.282584,
                stop: 6.380417
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' DEBUT ',
                width: 1080,
                height: 1920,
                start: 6.380417,
                stop: 6.836938
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' AT ',
                width: 1080,
                height: 1920,
                start: 6.836938,
                stop: 6.978228999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' NUMBER ',
                width: 1080,
                height: 1920,
                start: 6.978228999999999,
                stop: 7.260833999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' ONE ',
                width: 1080,
                height: 1920,
                start: 7.260833999999999,
                stop: 7.478249999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' ON ',
                width: 1080,
                height: 1920,
                start: 7.478249999999999,
                stop: 7.608687999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THE ',
                width: 1080,
                height: 1920,
                start: 7.608687999999999,
                stop: 7.684749999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *BILLBOARD* ',
                width: 1080,
                height: 1920,
                start: 7.684749999999999,
                stop: 8.141271
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *200.*',
                width: 1080,
                height: 1920,
                start: 8.141271
              }
            ]
          },
          {
            duration: 11.173917,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'https://res.cloudinary.com/dakp804eh/image/upload/v1683857924/Woxo/Idea2video/data_set/Taylor_Swift/new/2998427767_A_picture_of_Taylor_Swift_smiling_and_looking_at_t_xl-beta-v2-2-2.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-in-slider'
                }
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: '*FUN* ',
                width: 1080,
                height: 1920,
                start: 1,
                stop: 1.3043340000000008
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' FACT ',
                width: 1080,
                height: 1920,
                start: 1.3043340000000008,
                stop: 1.6195630000000012
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' 2: ',
                width: 1080,
                height: 1920,
                start: 1.6195630000000012,
                stop: 1.858709000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *TAYLOR* ',
                width: 1080,
                height: 1920,
                start: 1.858709000000001,
                stop: 2.4565210000000004
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' HAS ',
                width: 1080,
                height: 1920,
                start: 2.4565210000000004,
                stop: 2.5869590000000002
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *WON* ',
                width: 1080,
                height: 1920,
                start: 2.5869590000000002,
                stop: 2.8152300000000015
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' OVER ',
                width: 1080,
                height: 1920,
                start: 2.8152300000000015,
                stop: 3.054354
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' 400 ',
                width: 1080,
                height: 1920,
                start: 3.054354,
                stop: 3.608709000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' AWARDS, ',
                width: 1080,
                height: 1920,
                start: 3.608709000000001,
                stop: 4.086959
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' INCLUDING ',
                width: 1080,
                height: 1920,
                start: 4.086959,
                stop: 4.771730000000002
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' 23 ',
                width: 1080,
                height: 1920,
                start: 4.771730000000002,
                stop: 5.326084000000002
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *GRAMMY* ',
                width: 1080,
                height: 1920,
                start: 5.326084000000002,
                stop: 5.652167000000002
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *AWARDS,* ',
                width: 1080,
                height: 1920,
                start: 5.652167000000002,
                stop: 6.141313
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' 32 ',
                width: 1080,
                height: 1920,
                start: 6.141313,
                stop: 6.815208999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *AMERICAN* ',
                width: 1080,
                height: 1920,
                start: 6.815208999999999,
                stop: 7.239125000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *MUSIC* ',
                width: 1080,
                height: 1920,
                start: 7.239125000000001,
                stop: 7.543479900000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *AWARDS,* ',
                width: 1080,
                height: 1920,
                start: 7.543479900000001,
                stop: 8.032625
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' AND ',
                width: 1080,
                height: 1920,
                start: 8.032625,
                stop: 8.347834000000002
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' 23 ',
                width: 1080,
                height: 1920,
                start: 8.347834000000002,
                stop: 8.913042
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *BILLBOARD* ',
                width: 1080,
                height: 1920,
                start: 8.913042,
                stop: 9.369563
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *MUSIC* ',
                width: 1080,
                height: 1920,
                start: 9.369563,
                stop: 9.684792000000002
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *AWARDS.*',
                width: 1080,
                height: 1920,
                start: 9.684792000000002
              }
            ]
          },
          {
            duration: 8.467375,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'https://res.cloudinary.com/dakp804eh/image/upload/v1683857988/Woxo/Idea2video/data_set/Taylor_Swift/new/2648304698_A_picture_of_Taylor_Swift_smiling_and_looking_at_t_xl-beta-v2-2-2.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-slider'
                }
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: '*FUN* ',
                width: 1080,
                height: 1920,
                start: 1,
                stop: 2.043458000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' FACT ',
                width: 1080,
                height: 1920,
                start: 2.043458000000001,
                stop: 2.347812900000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' 3: ',
                width: 1080,
                height: 1920,
                start: 2.347812900000001,
                stop: 2.695646
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *TAYLOR* ',
                width: 1080,
                height: 1920,
                start: 2.695646,
                stop: 3.228271000000003
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' WAS ',
                width: 1080,
                height: 1920,
                start: 3.228271000000003,
                stop: 3.3586869999999998
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' NAMED ',
                width: 1080,
                height: 1920,
                start: 3.3586869999999998,
                stop: 3.652167000000002
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' ONE ',
                width: 1080,
                height: 1920,
                start: 3.652167000000002,
                stop: 3.804354
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' OF ',
                width: 1080,
                height: 1920,
                start: 3.804354,
                stop: 3.902167000000002
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *TIME* ',
                width: 1080,
                height: 1920,
                start: 3.902167000000002,
                stop: 4.163042000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: " MAGAZINE'S ",
                width: 1080,
                height: 1920,
                start: 4.163042000000001,
                stop: 4.728271000000003
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' 100 ',
                width: 1080,
                height: 1920,
                start: 4.728271000000003,
                stop: 5.326083000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *MOST* ',
                width: 1080,
                height: 1920,
                start: 5.326083000000001,
                stop: 5.608687
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *INFLUENTIAL* ',
                width: 1080,
                height: 1920,
                start: 5.608687,
                stop: 6.195646
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' PEOPLE ',
                width: 1080,
                height: 1920,
                start: 6.195646,
                stop: 6.5
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' IN ',
                width: 1080,
                height: 1920,
                start: 6.5,
                stop: 6.554354
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THE ',
                width: 1080,
                height: 1920,
                start: 6.554354,
                stop: 6.630417000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *WORLD* ',
                width: 1080,
                height: 1920,
                start: 6.630417000000001,
                stop: 6.923896000000003
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' IN ',
                width: 1080,
                height: 1920,
                start: 6.923896000000003,
                stop: 7.010854000000002
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' 2010.',
                width: 1080,
                height: 1920,
                start: 7.010854000000002
              }
            ]
          },
          {
            duration: 4.728271000000003,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'https://res.cloudinary.com/dakp804eh/image/upload/v1683857924/Woxo/Idea2video/data_set/Taylor_Swift/new/2998427767_A_picture_of_Taylor_Swift_smiling_and_looking_at_t_xl-beta-v2-2-2.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-rotate'
                }
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: '*FOLLOW* ',
                width: 1080,
                height: 1920,
                start: 1,
                stop: 1.6304580000000009
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' ME ',
                width: 1080,
                height: 1920,
                start: 1.6304580000000009,
                stop: 1.7391460000000016
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' FOR ',
                width: 1080,
                height: 1920,
                start: 1.7391460000000016,
                stop: 1.8695830000000022
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' MORE ',
                width: 1080,
                height: 1920,
                start: 1.8695830000000022,
                stop: 2.086957999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *FUN* ',
                width: 1080,
                height: 1920,
                start: 2.086957999999999,
                stop: 2.336957999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *FACTS* ',
                width: 1080,
                height: 1920,
                start: 2.336957999999999,
                stop: 2.6739169999999994
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' ABOUT ',
                width: 1080,
                height: 1920,
                start: 2.6739169999999994,
                stop: 2.9239380000000033
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *TAYLOR* ',
                width: 1080,
                height: 1920,
                start: 2.9239380000000033,
                stop: 3.228271000000003
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *SWIFT!*',
                width: 1080,
                height: 1920,
                start: 3.228271000000003
              }
            ]
          }
        ],
        meta: {
          title: 'Fun Facts About Taylor Swift',
          description: 'Learn some fun facts about Taylor Swift in this short video!',
          hashtags: '#TaylorSwift #FunFacts #Billboard200 #shorts',
          mediaDescription: 'A picture of Taylor Swift smiling and looking at the camera.',
          category: 'Fun Facts',
          prompt: 'Taylor Swift'
        }
      }
    ],
    category: 'Fun Facts',
    isLoading: false,
    errors: [],
    voiceover: [
      {
        scenes: [
          {
            voiceover: 'Hey everyone, here are some fun facts about Taylor Swift!',
            captions:
              'Hey ~ everyone, ~ here ~ are ~ some ~ *fun* ~ *facts* ~ about ~ *Taylor* ~ *Swift!*'
          },
          {
            voiceover:
              'Fun fact 1: Taylor Swift is the only artist in history with 10 consecutive albums to debut at number one on the Billboard 200.',
            captions:
              '*Fun* ~ fact ~ 1: ~ *Taylor* ~ *Swift* ~ is ~ the ~ only ~ artist ~ in ~ history ~ with ~ 10 ~ consecutive ~ albums ~ to ~ debut ~ at ~ number ~ one ~ on ~ the ~ *Billboard* ~ *200.*'
          },
          {
            voiceover:
              'Fun fact 2: Taylor has won over 400 awards, including 23 Grammy Awards, 32 American Music Awards, and 23 Billboard Music Awards.',
            captions:
              '*Fun* ~ fact ~ 2: ~ *Taylor* ~ has ~ *won* ~ over ~ 400 ~ awards, ~ including ~ 23 ~ *Grammy* ~ *Awards,* ~ 32 ~ *American* ~ *Music* ~ *Awards,* ~ and ~ 23 ~ *Billboard* ~ *Music* ~ *Awards.*'
          },
          {
            voiceover:
              "Fun fact 3: Taylor was named one of Time magazine's 100 Most Influential People in the World in 2010.",
            captions:
              "*Fun* ~ fact ~ 3: ~ *Taylor* ~ was ~ named ~ one ~ of ~ *Time* ~ magazine's ~ 100 ~ *Most* ~ *Influential* ~ People ~ in ~ the ~ *World* ~ in ~ 2010."
          },
          {
            voiceover: 'Follow me for more fun facts about Taylor Swift!',
            captions: '*Follow* ~ me ~ for ~ more ~ *fun* ~ *facts* ~ about ~ *Taylor* ~ *Swift!*'
          }
        ]
      }
    ],
    creation: {
      time: '00:00',
      step: 0
    },
    voiceoverAudio: {
      disabled: false,
      audios: [
        {
          audio: {
            path: 'https://vod.front10.cloud/output/RRV47ILLj2/nT6gi.mp3',
            text: '<speak xmlns="http://www.w3.org/2001/10/synthesis" xmlns:mstts="http://www.w3.org/2001/mstts" xmlns:emo="http://www.w3.org/2009/10/emotionml" version="1.0" xml:lang="en-US"><voice name="en-US-TonyNeural"><mstts:express-as style="Default"><prosody rate="+15.00%">Hey  everyone,  here  are  some  fun  facts  about  Taylor  Swift!</prosody><break time="1000ms"/><bookmark name="scene_0"/><prosody rate="+15.00%">Fun  fact  1:  Taylor  Swift  is  the  only  artist  in  history  with  10  consecutive  albums  to  debut  at  number  one  on  the  Billboard  200.</prosody><break time="1000ms"/><bookmark name="scene_1"/><prosody rate="+15.00%">Fun  fact  2:  Taylor  has  won  over  400  awards,  including  23  Grammy  Awards,  32  American  Music  Awards,  and  23  Billboard  Music  Awards.</prosody><break time="1000ms"/><bookmark name="scene_2"/><prosody rate="+15.00%">Fun  fact  3:  Taylor  was  named  one  of  Time  magazine\'s  100  Most  Influential  People  in  the  World  in  2010.</prosody><break time="1000ms"/><bookmark name="scene_3"/><prosody rate="+15.00%">Follow  me  for  more  fun  facts  about  Taylor  Swift!</prosody><break time="1000ms"/><bookmark name="scene_4"/></mstts:express-as></voice></speak>',
            timepoints: [
              {
                markName: 'scene_0',
                timeSeconds: 2.869625
              },
              {
                markName: 'scene_1',
                timeSeconds: 11.597875
              },
              {
                markName: 'scene_2',
                timeSeconds: 22.52175
              },
              {
                markName: 'scene_3',
                timeSeconds: 30.021688
              },
              {
                markName: 'scene_4',
                timeSeconds: 34.413062
              }
            ],
            wordBoundaryTimepoints: [
              {
                word: 'Hey',
                timeSeconds: 0.0435,
                boundaryType: 'WordBoundary',
                duration: 0.184812,
                textOffset: 262,
                wordLength: 3
              },
              {
                word: 'everyone',
                timeSeconds: 0.239125,
                boundaryType: 'WordBoundary',
                duration: 0.402188,
                textOffset: 267,
                wordLength: 8
              },
              {
                word: ',',
                timeSeconds: 0.72825,
                boundaryType: 'PunctuationBoundary',
                duration: 0.163062,
                textOffset: 275,
                wordLength: 1
              },
              {
                word: 'here',
                timeSeconds: 0.891312,
                boundaryType: 'WordBoundary',
                duration: 0.108688,
                textOffset: 278,
                wordLength: 4
              },
              {
                word: 'are',
                timeSeconds: 1.010875,
                boundaryType: 'WordBoundary',
                duration: 0.086938,
                textOffset: 284,
                wordLength: 3
              },
              {
                word: 'some',
                timeSeconds: 1.108688,
                boundaryType: 'WordBoundary',
                duration: 0.163062,
                textOffset: 289,
                wordLength: 4
              },
              {
                word: 'fun',
                timeSeconds: 1.282625,
                boundaryType: 'WordBoundary',
                duration: 0.239125,
                textOffset: 295,
                wordLength: 3
              },
              {
                word: 'facts',
                timeSeconds: 1.532625,
                boundaryType: 'WordBoundary',
                duration: 0.336938,
                textOffset: 300,
                wordLength: 5
              },
              {
                word: 'about',
                timeSeconds: 1.891312,
                boundaryType: 'WordBoundary',
                duration: 0.22825,
                textOffset: 307,
                wordLength: 5
              },
              {
                word: 'Taylor',
                timeSeconds: 2.130438,
                boundaryType: 'WordBoundary',
                duration: 0.2935,
                textOffset: 314,
                wordLength: 6
              },
              {
                word: 'Swift',
                timeSeconds: 2.434812,
                boundaryType: 'WordBoundary',
                duration: 0.434812,
                textOffset: 322,
                wordLength: 5
              },
              {
                word: '!',
                timeSeconds: 2.880438,
                boundaryType: 'PunctuationBoundary',
                duration: 0.086938,
                textOffset: 327,
                wordLength: 1
              },
              {
                word: 'Fun',
                timeSeconds: 4.619562,
                boundaryType: 'WordBoundary',
                duration: 0.27175,
                textOffset: 410,
                wordLength: 3
              },
              {
                word: 'fact',
                timeSeconds: 4.902188,
                boundaryType: 'WordBoundary',
                duration: 0.2935,
                textOffset: 415,
                wordLength: 4
              },
              {
                word: '1',
                timeSeconds: 5.2065,
                boundaryType: 'WordBoundary',
                duration: 0.22825,
                textOffset: 421,
                wordLength: 1
              },
              {
                word: ':',
                timeSeconds: 5.52175,
                boundaryType: 'PunctuationBoundary',
                duration: 0.1304379,
                textOffset: 422,
                wordLength: 1
              },
              {
                word: 'Taylor',
                timeSeconds: 5.652188,
                boundaryType: 'WordBoundary',
                duration: 0.326062,
                textOffset: 425,
                wordLength: 6
              },
              {
                word: 'Swift',
                timeSeconds: 5.989125,
                boundaryType: 'WordBoundary',
                duration: 0.391312,
                textOffset: 433,
                wordLength: 5
              },
              {
                word: 'is',
                timeSeconds: 6.413062,
                boundaryType: 'WordBoundary',
                duration: 0.097812,
                textOffset: 440,
                wordLength: 2
              },
              {
                word: 'the',
                timeSeconds: 6.52175,
                boundaryType: 'WordBoundary',
                duration: 0.086938,
                textOffset: 444,
                wordLength: 3
              },
              {
                word: 'only',
                timeSeconds: 6.619562,
                boundaryType: 'WordBoundary',
                duration: 0.304375,
                textOffset: 449,
                wordLength: 4
              },
              {
                word: 'artist',
                timeSeconds: 6.934812,
                boundaryType: 'WordBoundary',
                duration: 0.336938,
                textOffset: 455,
                wordLength: 6
              },
              {
                word: 'in',
                timeSeconds: 7.304375,
                boundaryType: 'WordBoundary',
                duration: 0.065188,
                textOffset: 463,
                wordLength: 2
              },
              {
                word: 'history',
                timeSeconds: 7.380438,
                boundaryType: 'WordBoundary',
                duration: 0.402188,
                textOffset: 467,
                wordLength: 7
              },
              {
                word: 'with',
                timeSeconds: 7.815188,
                boundaryType: 'WordBoundary',
                duration: 0.1739379,
                textOffset: 476,
                wordLength: 4
              },
              {
                word: '10',
                timeSeconds: 8,
                boundaryType: 'WordBoundary',
                duration: 0.22825,
                textOffset: 482,
                wordLength: 2
              },
              {
                word: 'consecutive',
                timeSeconds: 8.239125,
                boundaryType: 'WordBoundary',
                duration: 0.532625,
                textOffset: 486,
                wordLength: 11
              },
              {
                word: 'albums',
                timeSeconds: 8.782625,
                boundaryType: 'WordBoundary',
                duration: 0.369562,
                textOffset: 499,
                wordLength: 6
              },
              {
                word: 'to',
                timeSeconds: 9.184812,
                boundaryType: 'WordBoundary',
                duration: 0.065188,
                textOffset: 507,
                wordLength: 2
              },
              {
                word: 'debut',
                timeSeconds: 9.260875,
                boundaryType: 'WordBoundary',
                duration: 0.445625,
                textOffset: 511,
                wordLength: 5
              },
              {
                word: 'at',
                timeSeconds: 9.739125,
                boundaryType: 'WordBoundary',
                duration: 0.108688,
                textOffset: 518,
                wordLength: 2
              },
              {
                word: 'number',
                timeSeconds: 9.858688,
                boundaryType: 'WordBoundary',
                duration: 0.27175,
                textOffset: 522,
                wordLength: 6
              },
              {
                word: 'one',
                timeSeconds: 10.141312,
                boundaryType: 'WordBoundary',
                duration: 0.2065,
                textOffset: 530,
                wordLength: 3
              },
              {
                word: 'on',
                timeSeconds: 10.380438,
                boundaryType: 'WordBoundary',
                duration: 0.097812,
                textOffset: 535,
                wordLength: 2
              },
              {
                word: 'the',
                timeSeconds: 10.489125,
                boundaryType: 'WordBoundary',
                duration: 0.065188,
                textOffset: 539,
                wordLength: 3
              },
              {
                word: 'Billboard',
                timeSeconds: 10.565188,
                boundaryType: 'WordBoundary',
                duration: 0.445625,
                textOffset: 544,
                wordLength: 9
              },
              {
                word: '200',
                timeSeconds: 11.02175,
                boundaryType: 'WordBoundary',
                duration: 0.5761239,
                textOffset: 555,
                wordLength: 3
              },
              {
                word: '.',
                timeSeconds: 11.608688,
                boundaryType: 'PunctuationBoundary',
                duration: 0.097812,
                textOffset: 558,
                wordLength: 1
              },
              {
                word: 'Fun',
                timeSeconds: 13.358688,
                boundaryType: 'WordBoundary',
                duration: 0.2935,
                textOffset: 641,
                wordLength: 3
              },
              {
                word: 'fact',
                timeSeconds: 13.663062,
                boundaryType: 'WordBoundary',
                duration: 0.304375,
                textOffset: 646,
                wordLength: 4
              },
              {
                word: '2',
                timeSeconds: 13.97825,
                boundaryType: 'WordBoundary',
                duration: 0.22825,
                textOffset: 652,
                wordLength: 1
              },
              {
                word: ':',
                timeSeconds: 14.304375,
                boundaryType: 'PunctuationBoundary',
                duration: 0.163,
                textOffset: 653,
                wordLength: 1
              },
              {
                word: 'Taylor',
                timeSeconds: 14.467375,
                boundaryType: 'WordBoundary',
                duration: 0.336938,
                textOffset: 656,
                wordLength: 6
              },
              {
                word: 'has',
                timeSeconds: 14.815188,
                boundaryType: 'WordBoundary',
                duration: 0.119562,
                textOffset: 664,
                wordLength: 3
              },
              {
                word: 'won',
                timeSeconds: 14.945625,
                boundaryType: 'WordBoundary',
                duration: 0.217375,
                textOffset: 669,
                wordLength: 3
              },
              {
                word: 'over',
                timeSeconds: 15.173938,
                boundaryType: 'WordBoundary',
                duration: 0.22825,
                textOffset: 674,
                wordLength: 4
              },
              {
                word: '400',
                timeSeconds: 15.413062,
                boundaryType: 'WordBoundary',
                duration: 0.543438,
                textOffset: 680,
                wordLength: 3
              },
              {
                word: 'awards',
                timeSeconds: 15.967375,
                boundaryType: 'WordBoundary',
                duration: 0.467375,
                textOffset: 685,
                wordLength: 6
              },
              {
                word: ',',
                timeSeconds: 16.52175,
                boundaryType: 'PunctuationBoundary',
                duration: 0.108688,
                textOffset: 691,
                wordLength: 1
              },
              {
                word: 'including',
                timeSeconds: 16.6304379,
                boundaryType: 'WordBoundary',
                duration: 0.489125,
                textOffset: 694,
                wordLength: 9
              },
              {
                word: '23',
                timeSeconds: 17.1304379,
                boundaryType: 'WordBoundary',
                duration: 0.543499,
                textOffset: 705,
                wordLength: 2
              },
              {
                word: 'Grammy',
                timeSeconds: 17.684812,
                boundaryType: 'WordBoundary',
                duration: 0.315188,
                textOffset: 709,
                wordLength: 6
              },
              {
                word: 'Awards',
                timeSeconds: 18.010875,
                boundaryType: 'WordBoundary',
                duration: 0.47825,
                textOffset: 717,
                wordLength: 6
              },
              {
                word: ',',
                timeSeconds: 18.576062,
                boundaryType: 'PunctuationBoundary',
                duration: 0.086938,
                textOffset: 723,
                wordLength: 1
              },
              {
                word: '32',
                timeSeconds: 18.663062,
                boundaryType: 'WordBoundary',
                duration: 0.4999379,
                textOffset: 726,
                wordLength: 2
              },
              {
                word: 'American',
                timeSeconds: 19.1739379,
                boundaryType: 'WordBoundary',
                duration: 0.413062,
                textOffset: 730,
                wordLength: 8
              },
              {
                word: 'Music',
                timeSeconds: 19.597812,
                boundaryType: 'WordBoundary',
                duration: 0.2935,
                textOffset: 740,
                wordLength: 5
              },
              {
                word: 'Awards',
                timeSeconds: 19.9021879,
                boundaryType: 'WordBoundary',
                duration: 0.47825,
                textOffset: 747,
                wordLength: 6
              },
              {
                word: ',',
                timeSeconds: 20.467375,
                boundaryType: 'PunctuationBoundary',
                duration: 0.097812,
                textOffset: 753,
                wordLength: 1
              },
              {
                word: 'and',
                timeSeconds: 20.5651879,
                boundaryType: 'WordBoundary',
                duration: 0.1304379,
                textOffset: 756,
                wordLength: 3
              },
              {
                word: '23',
                timeSeconds: 20.7065,
                boundaryType: 'WordBoundary',
                duration: 0.554375,
                textOffset: 761,
                wordLength: 2
              },
              {
                word: 'Billboard',
                timeSeconds: 21.27175,
                boundaryType: 'WordBoundary',
                duration: 0.445625,
                textOffset: 765,
                wordLength: 9
              },
              {
                word: 'Music',
                timeSeconds: 21.72825,
                boundaryType: 'WordBoundary',
                duration: 0.304375,
                textOffset: 776,
                wordLength: 5
              },
              {
                word: 'Awards',
                timeSeconds: 22.0435,
                boundaryType: 'WordBoundary',
                duration: 0.47825,
                textOffset: 783,
                wordLength: 6
              },
              {
                word: '.',
                timeSeconds: 22.532625,
                boundaryType: 'PunctuationBoundary',
                duration: 0.097812,
                textOffset: 789,
                wordLength: 1
              },
              {
                word: 'Fun',
                timeSeconds: 24.282625,
                boundaryType: 'WordBoundary',
                duration: 0.27175,
                textOffset: 872,
                wordLength: 3
              },
              {
                word: 'fact',
                timeSeconds: 24.5651879,
                boundaryType: 'WordBoundary',
                duration: 0.2935,
                textOffset: 877,
                wordLength: 4
              },
              {
                word: '3',
                timeSeconds: 24.869562,
                boundaryType: 'WordBoundary',
                duration: 0.336938,
                textOffset: 883,
                wordLength: 1
              },
              {
                word: ':',
                timeSeconds: 25.2935,
                boundaryType: 'PunctuationBoundary',
                duration: 0.1304379,
                textOffset: 884,
                wordLength: 1
              },
              {
                word: 'Taylor',
                timeSeconds: 25.4239379,
                boundaryType: 'WordBoundary',
                duration: 0.2935,
                textOffset: 887,
                wordLength: 6
              },
              {
                word: 'was',
                timeSeconds: 25.72825,
                boundaryType: 'WordBoundary',
                duration: 0.119562,
                textOffset: 895,
                wordLength: 3
              },
              {
                word: 'named',
                timeSeconds: 25.8586879,
                boundaryType: 'WordBoundary',
                duration: 0.282625,
                textOffset: 900,
                wordLength: 5
              },
              {
                word: 'one',
                timeSeconds: 26.1521879,
                boundaryType: 'WordBoundary',
                duration: 0.141312,
                textOffset: 907,
                wordLength: 3
              },
              {
                word: 'of',
                timeSeconds: 26.304375,
                boundaryType: 'WordBoundary',
                duration: 0.086938,
                textOffset: 912,
                wordLength: 2
              },
              {
                word: 'Time',
                timeSeconds: 26.4021879,
                boundaryType: 'WordBoundary',
                duration: 0.25,
                textOffset: 916,
                wordLength: 4
              },
              {
                word: "magazine's",
                timeSeconds: 26.663062,
                boundaryType: 'WordBoundary',
                duration: 0.5435,
                textOffset: 922,
                wordLength: 10
              },
              {
                word: '100',
                timeSeconds: 27.260875,
                boundaryType: 'WordBoundary',
                duration: 0.532563,
                textOffset: 934,
                wordLength: 3
              },
              {
                word: 'Most',
                timeSeconds: 27.804375,
                boundaryType: 'WordBoundary',
                duration: 0.282625,
                textOffset: 939,
                wordLength: 4
              },
              {
                word: 'Influential',
                timeSeconds: 28.130438,
                boundaryType: 'WordBoundary',
                duration: 0.5435,
                textOffset: 945,
                wordLength: 11
              },
              {
                word: 'People',
                timeSeconds: 28.684812,
                boundaryType: 'WordBoundary',
                duration: 0.282625,
                textOffset: 958,
                wordLength: 6
              },
              {
                word: 'in',
                timeSeconds: 28.97825,
                boundaryType: 'WordBoundary',
                duration: 0.032625,
                textOffset: 966,
                wordLength: 2
              },
              {
                word: 'the',
                timeSeconds: 29.02175,
                boundaryType: 'WordBoundary',
                duration: 0.065188,
                textOffset: 970,
                wordLength: 3
              },
              {
                word: 'World',
                timeSeconds: 29.097812,
                boundaryType: 'WordBoundary',
                duration: 0.336938,
                textOffset: 975,
                wordLength: 5
              },
              {
                word: 'in',
                timeSeconds: 29.467375,
                boundaryType: 'WordBoundary',
                duration: 0.097812,
                textOffset: 982,
                wordLength: 2
              },
              {
                word: '2010',
                timeSeconds: 29.576062,
                boundaryType: 'WordBoundary',
                duration: 0.7609379,
                textOffset: 986,
                wordLength: 4
              },
              {
                word: '.',
                timeSeconds: 30.347812,
                boundaryType: 'PunctuationBoundary',
                duration: 0.086938,
                textOffset: 990,
                wordLength: 1
              },
              {
                word: 'Follow',
                timeSeconds: 32.086938,
                boundaryType: 'WordBoundary',
                duration: 0.2935,
                textOffset: 1073,
                wordLength: 6
              },
              {
                word: 'me',
                timeSeconds: 32.391312,
                boundaryType: 'WordBoundary',
                duration: 0.097812,
                textOffset: 1081,
                wordLength: 2
              },
              {
                word: 'for',
                timeSeconds: 32.5,
                boundaryType: 'WordBoundary',
                duration: 0.119562,
                textOffset: 1085,
                wordLength: 3
              },
              {
                word: 'more',
                timeSeconds: 32.630438,
                boundaryType: 'WordBoundary',
                duration: 0.2065,
                textOffset: 1090,
                wordLength: 4
              },
              {
                word: 'fun',
                timeSeconds: 32.847812,
                boundaryType: 'WordBoundary',
                duration: 0.239125,
                textOffset: 1096,
                wordLength: 3
              },
              {
                word: 'facts',
                timeSeconds: 33.097812,
                boundaryType: 'WordBoundary',
                duration: 0.326062,
                textOffset: 1101,
                wordLength: 5
              },
              {
                word: 'about',
                timeSeconds: 33.445625,
                boundaryType: 'WordBoundary',
                duration: 0.22825,
                textOffset: 1108,
                wordLength: 5
              },
              {
                word: 'Taylor',
                timeSeconds: 33.684812,
                boundaryType: 'WordBoundary',
                duration: 0.2935,
                textOffset: 1115,
                wordLength: 6
              },
              {
                word: 'Swift',
                timeSeconds: 33.989125,
                boundaryType: 'WordBoundary',
                duration: 0.423938,
                textOffset: 1123,
                wordLength: 5
              },
              {
                word: '!',
                timeSeconds: 34.423938,
                boundaryType: 'PunctuationBoundary',
                duration: 0.086938,
                textOffset: 1128,
                wordLength: 1
              }
            ]
          }
        }
      ]
    },
    medias: [
      [
        'https://res.cloudinary.com/dakp804eh/image/upload/v1683857924/Woxo/Idea2video/data_set/Taylor_Swift/new/2998427767_A_picture_of_Taylor_Swift_smiling_and_looking_at_t_xl-beta-v2-2-2.png',
        'https://res.cloudinary.com/dakp804eh/image/upload/v1683857988/Woxo/Idea2video/data_set/Taylor_Swift/new/2648304698_A_picture_of_Taylor_Swift_smiling_and_looking_at_t_xl-beta-v2-2-2.png'
      ]
    ],
    music: [
      'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Happy/Morning_Walk_-_Jingle_Punks.mp3'
    ],
    prompt: 'Taylor Swift'
  },
  {
    id: 'a71a00a8-0238-4d06-a966-3f9ac4e35c3b',
    text: 'Quotes about "Taylor Swift"',
    videos: [
      {
        id: '95dfd492-a421-4a5e-bc5c-884da7ed86b1',
        proportion: 0.28,
        width: 1080,
        height: 1920,
        customFonts: [
          {
            fontFamily: 'Montserrat-ExtraBold',
            path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
          }
        ],
        audioTracks: [
          {
            path: 'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Happy/Feels_Good_-_MOODHALO.mp3',
            mixVolume: 0.2
          },
          {
            path: 'https://vod.front10.cloud/output/vYgFvwwlWi/q25JI.mp3',
            loop: false
          }
        ],
        clips: [
          {
            duration: 4.5,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'https://res.cloudinary.com/dakp804eh/image/upload/v1683859676/Woxo/Idea2video/data_set/Taylor_Swift/new/2263577504_A_picture_of_Taylor_Swift_with_a_gentle_smile_and__xl-beta-v2-2-2.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-in-slider'
                },
                optimizedMedia: false
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: 'DID YOU KNOW THIS *QUOTE* ABOUT ',
                width: 1080,
                height: 1920,
                start: 0,
                stop: 1.032625
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *TAYLOR* ',
                width: 1080,
                height: 1920,
                start: 1.032625,
                stop: 1.358688
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *SWIFT?*',
                width: 1080,
                height: 1920,
                start: 1.358688,
                stop: 1.891312
              }
            ]
          },
          {
            duration: 9.25,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'https://res.cloudinary.com/dakp804eh/image/upload/v1683859649/Woxo/Idea2video/data_set/Taylor_Swift/new/2156261779_A_picture_of_Taylor_Swift_with_a_gentle_smile_and__xl-beta-v2-2-2.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-in-slider'
                }
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: "'NO ",
                width: 1080,
                height: 1920,
                start: 0,
                stop: 0.1413120000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *MATTER* ',
                width: 1080,
                height: 1920,
                start: 0.1413120000000001,
                stop: 0.4021250000000003
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' WHAT ',
                width: 1080,
                height: 1920,
                start: 0.4021250000000003,
                stop: 0.5216880000000002
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *HAPPENS* ',
                width: 1080,
                height: 1920,
                start: 0.5216880000000002,
                stop: 0.8586879999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' IN ',
                width: 1080,
                height: 1920,
                start: 0.8586879999999999,
                stop: 0.9565000000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *LIFE,* ',
                width: 1080,
                height: 1920,
                start: 0.9565000000000001,
                stop: 1.5108750000000004
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' BE ',
                width: 1080,
                height: 1920,
                start: 1.5108750000000004,
                stop: 1.641312
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *GOOD* ',
                width: 1080,
                height: 1920,
                start: 1.641312,
                stop: 1.8260620000000003
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' TO ',
                width: 1080,
                height: 1920,
                start: 1.8260620000000003,
                stop: 1.9021249999999998
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' PEOPLE. ',
                width: 1080,
                height: 1920,
                start: 1.9021249999999998,
                stop: 3.0543120000000004
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' BEING ',
                width: 1080,
                height: 1920,
                start: 3.0543120000000004,
                stop: 3.25
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *GOOD* ',
                width: 1080,
                height: 1920,
                start: 3.25,
                stop: 3.43475
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' TO ',
                width: 1080,
                height: 1920,
                start: 3.43475,
                stop: 3.5325620000000004
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' PEOPLE ',
                width: 1080,
                height: 1920,
                start: 3.5325620000000004,
                stop: 3.869562
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' IS ',
                width: 1080,
                height: 1920,
                start: 3.869562,
                stop: 3.9673750000000005
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' A ',
                width: 1080,
                height: 1920,
                start: 3.9673750000000005,
                stop: 4.021688
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *WONDERFUL* ',
                width: 1080,
                height: 1920,
                start: 4.021688,
                stop: 4.4673750000000005
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *LEGACY* ',
                width: 1080,
                height: 1920,
                start: 4.4673750000000005,
                stop: 4.880438
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' TO ',
                width: 1080,
                height: 1920,
                start: 4.880438,
                stop: 5
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' LEAVE ',
                width: 1080,
                height: 1920,
                start: 5,
                stop: 5.2173750000000005
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: " *BEHIND.*' ",
                width: 1080,
                height: 1920,
                start: 5.2173750000000005,
                stop: 6.4673750000000005
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' - ',
                width: 1080,
                height: 1920,
                start: 6.4673750000000005,
                stop: 6.4673750000000005
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *TAYLOR* ',
                width: 1080,
                height: 1920,
                start: 6.4673750000000005,
                stop: 6.815188000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *SWIFT*',
                width: 1080,
                height: 1920,
                start: 6.815188000000001,
                stop: 7.250000000000002
              }
            ]
          },
          {
            duration: 7.521687900000002,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'https://res.cloudinary.com/dakp804eh/image/upload/v1683859676/Woxo/Idea2video/data_set/Taylor_Swift/new/2263577504_A_picture_of_Taylor_Swift_with_a_gentle_smile_and__xl-beta-v2-2-2.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-in-slider'
                }
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: 'THIS ',
                width: 1080,
                height: 1920,
                start: 0,
                stop: 0.17387500000000067
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *SIMPLE* ',
                width: 1080,
                height: 1920,
                start: 0.17387500000000067,
                stop: 0.4456249999999997
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' PHRASE ',
                width: 1080,
                height: 1920,
                start: 0.4456249999999997,
                stop: 0.7608750000000004
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' FROM ',
                width: 1080,
                height: 1920,
                start: 0.7608750000000004,
                stop: 0.9238750000000007
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *TAYLOR* ',
                width: 1080,
                height: 1920,
                start: 0.9238750000000007,
                stop: 1.2608750000000004
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *SWIFT* ',
                width: 1080,
                height: 1920,
                start: 1.2608750000000004,
                stop: 1.6847499999999993
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' IS ',
                width: 1080,
                height: 1920,
                start: 1.6847499999999993,
                stop: 1.8043119999999995
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' A ',
                width: 1080,
                height: 1920,
                start: 1.8043119999999995,
                stop: 1.8695620000000002
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *POWERFUL* ',
                width: 1080,
                height: 1920,
                start: 1.8695620000000002,
                stop: 2.3260620000000003
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' REMINDER ',
                width: 1080,
                height: 1920,
                start: 2.3260620000000003,
                stop: 2.793438
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' OF ',
                width: 1080,
                height: 1920,
                start: 2.793438,
                stop: 2.869562
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THE ',
                width: 1080,
                height: 1920,
                start: 2.869562,
                stop: 2.9347499999999993
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *IMPORTANCE* ',
                width: 1080,
                height: 1920,
                start: 2.9347499999999993,
                stop: 3.4347499999999993
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' OF ',
                width: 1080,
                height: 1920,
                start: 3.4347499999999993,
                stop: 3.543438
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' TREATING ',
                width: 1080,
                height: 1920,
                start: 3.543438,
                stop: 3.902125
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' OTHERS ',
                width: 1080,
                height: 1920,
                start: 3.902125,
                stop: 4.239125
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' WITH ',
                width: 1080,
                height: 1920,
                start: 4.239125,
                stop: 4.402125
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *KINDNESS* ',
                width: 1080,
                height: 1920,
                start: 4.402125,
                stop: 4.902125
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' AND ',
                width: 1080,
                height: 1920,
                start: 4.902125,
                stop: 5.010875
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *RESPECT*',
                width: 1080,
                height: 1920,
                start: 5.010875,
                stop: 5.521749999999999
              }
            ]
          },
          {
            duration: 4.989187099999999,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'https://res.cloudinary.com/dakp804eh/image/upload/v1683859649/Woxo/Idea2video/data_set/Taylor_Swift/new/2156261779_A_picture_of_Taylor_Swift_with_a_gentle_smile_and__xl-beta-v2-2-2.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-in-slider'
                }
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: 'WE ',
                width: 1080,
                height: 1920,
                start: 0,
                stop: 0.09787409999999852
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' CAN ',
                width: 1080,
                height: 1920,
                start: 0.09787409999999852,
                stop: 0.22831210000000013
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' ALL ',
                width: 1080,
                height: 1920,
                start: 0.22831210000000013,
                stop: 0.402187099999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' LEARN ',
                width: 1080,
                height: 1920,
                start: 0.402187099999999,
                stop: 0.5869999999999997
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' FROM ',
                width: 1080,
                height: 1920,
                start: 0.5869999999999997,
                stop: 0.7608740999999988
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *TAYLOR* ',
                width: 1080,
                height: 1920,
                start: 0.7608740999999988,
                stop: 1.1630620999999977
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' AND ',
                width: 1080,
                height: 1920,
                start: 1.1630620999999977,
                stop: 1.2391870999999988
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *STRIVE* ',
                width: 1080,
                height: 1920,
                start: 1.2391870999999988,
                stop: 1.5869999999999997
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' TO ',
                width: 1080,
                height: 1920,
                start: 1.5869999999999997,
                stop: 1.652187099999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' BE ',
                width: 1080,
                height: 1920,
                start: 1.652187099999999,
                stop: 1.7608740999999988
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *BETTER* ',
                width: 1080,
                height: 1920,
                start: 1.7608740999999988,
                stop: 2.0108740999999988
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' PEOPLE ',
                width: 1080,
                height: 1920,
                start: 2.0108740999999988,
                stop: 2.391312099999997
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' EVERY ',
                width: 1080,
                height: 1920,
                start: 2.391312099999997,
                stop: 2.630437099999998
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' DAY',
                width: 1080,
                height: 1920,
                start: 2.630437099999998,
                stop: 2.989125099999999
              }
            ]
          },
          {
            duration: 3.5543128999999993,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'https://res.cloudinary.com/dakp804eh/image/upload/v1683859676/Woxo/Idea2video/data_set/Taylor_Swift/new/2263577504_A_picture_of_Taylor_Swift_with_a_gentle_smile_and__xl-beta-v2-2-2.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-in-slider'
                }
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: '*FOLLOW* ',
                width: 1080,
                height: 1920,
                start: 0,
                stop: 0.3151869999999981
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' US ',
                width: 1080,
                height: 1920,
                start: 0.3151869999999981,
                stop: 0.4456249999999997
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' FOR ',
                width: 1080,
                height: 1920,
                start: 0.4456249999999997,
                stop: 0.5543128999999993
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' MORE ',
                width: 1080,
                height: 1920,
                start: 0.5543128999999993,
                stop: 0.7608129000000012
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *INSPIRING* ',
                width: 1080,
                height: 1920,
                start: 0.7608129000000012,
                stop: 1.271687
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *QUOTES* ',
                width: 1080,
                height: 1920,
                start: 1.271687,
                stop: 1.586936999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' FROM ',
                width: 1080,
                height: 1920,
                start: 1.586936999999999,
                stop: 1.7391250000000014
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *TAYLOR* ',
                width: 1080,
                height: 1920,
                start: 1.7391250000000014,
                stop: 2.0543128999999993
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *SWIFT*',
                width: 1080,
                height: 1920,
                start: 2.0543128999999993,
                stop: 2.478250899999999
              }
            ]
          }
        ],
        meta: {
          title: 'Taylor Swift Quote on Kindness',
          description:
            'This video is a short quote from Taylor Swift about being kind to others and leaving a positive legacy.',
          hashtags: '#taylorswift #kindness #legacy #inspiration #shorts',
          mediaDescription:
            'A picture of Taylor Swift with a gentle smile and her hands clasped together.',
          category: 'Quotes',
          prompt: 'Taylor Swift'
        }
      }
    ],
    category: 'Quotes',
    isLoading: false,
    errors: [],
    voiceover: [
      {
        scenes: [
          {
            voiceover: 'Did you know this quote about  Taylor  Swift?',
            captions: 'Did you know this *quote* about ~ *Taylor* ~ *Swift?*'
          },
          {
            voiceover:
              "'No  matter  what  happens  in  life,  be  good  to  people.  Being  good  to  people  is  a  wonderful  legacy  to  leave  behind.'  -  Taylor  Swift",
            captions:
              "'No ~ *matter* ~ what ~ *happens* ~ in ~ *life,* ~ be ~ *good* ~ to ~ people. ~ Being ~ *good* ~ to ~ people ~ is ~ a ~ *wonderful* ~ *legacy* ~ to ~ leave ~ *behind.*' ~ - ~ *Taylor* ~ *Swift*"
          },
          {
            voiceover:
              'This  simple  phrase  from  Taylor  Swift  is  a  powerful  reminder  of  the  importance  of  treating  others  with  kindness  and  respect',
            captions:
              'This ~ *simple* ~ phrase ~ from ~ *Taylor* ~ *Swift* ~ is ~ a ~ *powerful* ~ reminder ~ of ~ the ~ *importance* ~ of ~ treating ~ others ~ with ~ *kindness* ~ and ~ *respect*'
          },
          {
            voiceover:
              'We  can  all  learn  from  Taylor  and  strive  to  be  better  people  every  day',
            captions:
              'We ~ can ~ all ~ learn ~ from ~ *Taylor* ~ and ~ *strive* ~ to ~ be ~ *better* ~ people ~ every ~ day'
          },
          {
            voiceover: 'Follow  us  for  more  inspiring  quotes  from  Taylor  Swift',
            captions:
              '*Follow* ~ us ~ for ~ more ~ *inspiring* ~ *quotes* ~ from ~ *Taylor* ~ *Swift*'
          }
        ]
      }
    ],
    creation: {
      time: '00:00',
      step: 0
    },
    voiceoverAudio: {
      disabled: false,
      audios: [
        {
          audio: {
            path: 'https://vod.front10.cloud/output/vYgFvwwlWi/q25JI.mp3',
            text: '<speak xmlns="http://www.w3.org/2001/10/synthesis" xmlns:mstts="http://www.w3.org/2001/mstts" xmlns:emo="http://www.w3.org/2009/10/emotionml" version="1.0" xml:lang="en-US"><voice name="en-US-TonyNeural"><mstts:express-as style="Default"><prosody rate="+15.00%">Did you know this quote about  Taylor  Swift?</prosody><break time="1000ms"/><bookmark name="scene_0"/><prosody rate="+15.00%">\'No  matter  what  happens  in  life,  be  good  to  people.  Being  good  to  people  is  a  wonderful  legacy  to  leave  behind.\'  -  Taylor  Swift</prosody><break time="1000ms"/><bookmark name="scene_1"/><prosody rate="+15.00%">This  simple  phrase  from  Taylor  Swift  is  a  powerful  reminder  of  the  importance  of  treating  others  with  kindness  and  respect</prosody><break time="1000ms"/><bookmark name="scene_2"/><prosody rate="+15.00%">We  can  all  learn  from  Taylor  and  strive  to  be  better  people  every  day</prosody><break time="1000ms"/><bookmark name="scene_3"/><prosody rate="+15.00%">Follow  us  for  more  inspiring  quotes  from  Taylor  Swift</prosody><break time="1000ms"/><bookmark name="scene_4"/></mstts:express-as></voice></speak>',
            timepoints: [
              {
                markName: 'scene_0',
                timeSeconds: 1.782625
              },
              {
                markName: 'scene_1',
                timeSeconds: 10.7935
              },
              {
                markName: 'scene_2',
                timeSeconds: 17.31525
              },
              {
                markName: 'scene_3',
                timeSeconds: 21.304312
              },
              {
                markName: 'scene_4',
                timeSeconds: 24.782625
              }
            ],
            wordBoundaryTimepoints: [
              {
                word: 'Did',
                timeSeconds: 0.0435,
                boundaryType: 'WordBoundary',
                duration: 0.119562,
                textOffset: 262,
                wordLength: 3
              },
              {
                word: 'you',
                timeSeconds: 0.1739379,
                boundaryType: 'WordBoundary',
                duration: 0.054375,
                textOffset: 266,
                wordLength: 3
              },
              {
                word: 'know',
                timeSeconds: 0.239125,
                boundaryType: 'WordBoundary',
                duration: 0.1304379,
                textOffset: 270,
                wordLength: 4
              },
              {
                word: 'this',
                timeSeconds: 0.380438,
                boundaryType: 'WordBoundary',
                duration: 0.141312,
                textOffset: 275,
                wordLength: 4
              },
              {
                word: 'quote',
                timeSeconds: 0.532625,
                boundaryType: 'WordBoundary',
                duration: 0.25,
                textOffset: 280,
                wordLength: 5
              },
              {
                word: 'about',
                timeSeconds: 0.7935,
                boundaryType: 'WordBoundary',
                duration: 0.22825,
                textOffset: 286,
                wordLength: 5
              },
              {
                word: 'Taylor',
                timeSeconds: 1.032625,
                boundaryType: 'WordBoundary',
                duration: 0.315188,
                textOffset: 293,
                wordLength: 6
              },
              {
                word: 'Swift',
                timeSeconds: 1.358688,
                boundaryType: 'WordBoundary',
                duration: 0.423938,
                textOffset: 301,
                wordLength: 5
              },
              {
                word: '?',
                timeSeconds: 1.7935,
                boundaryType: 'PunctuationBoundary',
                duration: 0.097812,
                textOffset: 306,
                wordLength: 1
              },
              {
                word: 'No',
                timeSeconds: 3.5435,
                boundaryType: 'WordBoundary',
                duration: 0.1304379,
                textOffset: 390,
                wordLength: 2
              },
              {
                word: 'matter',
                timeSeconds: 3.684812,
                boundaryType: 'WordBoundary',
                duration: 0.25,
                textOffset: 394,
                wordLength: 6
              },
              {
                word: 'what',
                timeSeconds: 3.945625,
                boundaryType: 'WordBoundary',
                duration: 0.108688,
                textOffset: 402,
                wordLength: 4
              },
              {
                word: 'happens',
                timeSeconds: 4.065188,
                boundaryType: 'WordBoundary',
                duration: 0.326062,
                textOffset: 408,
                wordLength: 7
              },
              {
                word: 'in',
                timeSeconds: 4.402188,
                boundaryType: 'WordBoundary',
                duration: 0.086938,
                textOffset: 417,
                wordLength: 2
              },
              {
                word: 'life',
                timeSeconds: 4.5,
                boundaryType: 'WordBoundary',
                duration: 0.391312,
                textOffset: 421,
                wordLength: 4
              },
              {
                word: ',',
                timeSeconds: 4.97825,
                boundaryType: 'PunctuationBoundary',
                duration: 0.076062,
                textOffset: 425,
                wordLength: 1
              },
              {
                word: 'be',
                timeSeconds: 5.054375,
                boundaryType: 'WordBoundary',
                duration: 0.119562,
                textOffset: 428,
                wordLength: 2
              },
              {
                word: 'good',
                timeSeconds: 5.184812,
                boundaryType: 'WordBoundary',
                duration: 0.1739379,
                textOffset: 432,
                wordLength: 4
              },
              {
                word: 'to',
                timeSeconds: 5.369562,
                boundaryType: 'WordBoundary',
                duration: 0.065188,
                textOffset: 438,
                wordLength: 2
              },
              {
                word: 'people',
                timeSeconds: 5.445625,
                boundaryType: 'WordBoundary',
                duration: 0.402188,
                textOffset: 442,
                wordLength: 6
              },
              {
                word: '.',
                timeSeconds: 5.858688,
                boundaryType: 'PunctuationBoundary',
                duration: 0.086938,
                textOffset: 448,
                wordLength: 1
              },
              {
                word: 'Being',
                timeSeconds: 6.597812,
                boundaryType: 'WordBoundary',
                duration: 0.184812,
                textOffset: 451,
                wordLength: 5
              },
              {
                word: 'good',
                timeSeconds: 6.7935,
                boundaryType: 'WordBoundary',
                duration: 0.1739379,
                textOffset: 458,
                wordLength: 4
              },
              {
                word: 'to',
                timeSeconds: 6.97825,
                boundaryType: 'WordBoundary',
                duration: 0.086938,
                textOffset: 464,
                wordLength: 2
              },
              {
                word: 'people',
                timeSeconds: 7.076062,
                boundaryType: 'WordBoundary',
                duration: 0.315188,
                textOffset: 468,
                wordLength: 6
              },
              {
                word: 'is',
                timeSeconds: 7.413062,
                boundaryType: 'WordBoundary',
                duration: 0.086938,
                textOffset: 476,
                wordLength: 2
              },
              {
                word: 'a',
                timeSeconds: 7.510875,
                boundaryType: 'WordBoundary',
                duration: 0.0435,
                textOffset: 480,
                wordLength: 1
              },
              {
                word: 'wonderful',
                timeSeconds: 7.565188,
                boundaryType: 'WordBoundary',
                duration: 0.434812,
                textOffset: 483,
                wordLength: 9
              },
              {
                word: 'legacy',
                timeSeconds: 8.010875,
                boundaryType: 'WordBoundary',
                duration: 0.391312,
                textOffset: 494,
                wordLength: 6
              },
              {
                word: 'to',
                timeSeconds: 8.423938,
                boundaryType: 'WordBoundary',
                duration: 0.108688,
                textOffset: 502,
                wordLength: 2
              },
              {
                word: 'leave',
                timeSeconds: 8.5435,
                boundaryType: 'WordBoundary',
                duration: 0.2065,
                textOffset: 506,
                wordLength: 5
              },
              {
                word: 'behind',
                timeSeconds: 8.760875,
                boundaryType: 'WordBoundary',
                duration: 0.489125,
                textOffset: 513,
                wordLength: 6
              },
              {
                word: '.',
                timeSeconds: 9.260875,
                boundaryType: 'PunctuationBoundary',
                duration: 0.097812,
                textOffset: 519,
                wordLength: 1
              },
              {
                word: 'Taylor',
                timeSeconds: 10.010875,
                boundaryType: 'WordBoundary',
                duration: 0.336938,
                textOffset: 526,
                wordLength: 6
              },
              {
                word: 'Swift',
                timeSeconds: 10.358688,
                boundaryType: 'WordBoundary',
                duration: 0.434812,
                textOffset: 534,
                wordLength: 5
              },
              {
                word: 'This',
                timeSeconds: 11.7935,
                boundaryType: 'WordBoundary',
                duration: 0.163062,
                textOffset: 621,
                wordLength: 4
              },
              {
                word: 'simple',
                timeSeconds: 11.967375,
                boundaryType: 'WordBoundary',
                duration: 0.260875,
                textOffset: 627,
                wordLength: 6
              },
              {
                word: 'phrase',
                timeSeconds: 12.239125,
                boundaryType: 'WordBoundary',
                duration: 0.304375,
                textOffset: 635,
                wordLength: 6
              },
              {
                word: 'from',
                timeSeconds: 12.554375,
                boundaryType: 'WordBoundary',
                duration: 0.1521879,
                textOffset: 643,
                wordLength: 4
              },
              {
                word: 'Taylor',
                timeSeconds: 12.717375,
                boundaryType: 'WordBoundary',
                duration: 0.326062,
                textOffset: 649,
                wordLength: 6
              },
              {
                word: 'Swift',
                timeSeconds: 13.054375,
                boundaryType: 'WordBoundary',
                duration: 0.391312,
                textOffset: 657,
                wordLength: 5
              },
              {
                word: 'is',
                timeSeconds: 13.47825,
                boundaryType: 'WordBoundary',
                duration: 0.108688,
                textOffset: 664,
                wordLength: 2
              },
              {
                word: 'a',
                timeSeconds: 13.597812,
                boundaryType: 'WordBoundary',
                duration: 0.054375,
                textOffset: 668,
                wordLength: 1
              },
              {
                word: 'powerful',
                timeSeconds: 13.663062,
                boundaryType: 'WordBoundary',
                duration: 0.445625,
                textOffset: 671,
                wordLength: 8
              },
              {
                word: 'reminder',
                timeSeconds: 14.119562,
                boundaryType: 'WordBoundary',
                duration: 0.445625,
                textOffset: 681,
                wordLength: 8
              },
              {
                word: 'of',
                timeSeconds: 14.586938,
                boundaryType: 'WordBoundary',
                duration: 0.065188,
                textOffset: 691,
                wordLength: 2
              },
              {
                word: 'the',
                timeSeconds: 14.663062,
                boundaryType: 'WordBoundary',
                duration: 0.054375,
                textOffset: 695,
                wordLength: 3
              },
              {
                word: 'importance',
                timeSeconds: 14.72825,
                boundaryType: 'WordBoundary',
                duration: 0.489125,
                textOffset: 700,
                wordLength: 10
              },
              {
                word: 'of',
                timeSeconds: 15.22825,
                boundaryType: 'WordBoundary',
                duration: 0.097812,
                textOffset: 712,
                wordLength: 2
              },
              {
                word: 'treating',
                timeSeconds: 15.336938,
                boundaryType: 'WordBoundary',
                duration: 0.347812,
                textOffset: 716,
                wordLength: 8
              },
              {
                word: 'others',
                timeSeconds: 15.695625,
                boundaryType: 'WordBoundary',
                duration: 0.304375,
                textOffset: 726,
                wordLength: 6
              },
              {
                word: 'with',
                timeSeconds: 16.032625,
                boundaryType: 'WordBoundary',
                duration: 0.1521879,
                textOffset: 734,
                wordLength: 4
              },
              {
                word: 'kindness',
                timeSeconds: 16.195625,
                boundaryType: 'WordBoundary',
                duration: 0.4565,
                textOffset: 740,
                wordLength: 8
              },
              {
                word: 'and',
                timeSeconds: 16.695625,
                boundaryType: 'WordBoundary',
                duration: 0.097812,
                textOffset: 750,
                wordLength: 3
              },
              {
                word: 'respect',
                timeSeconds: 16.804375,
                boundaryType: 'WordBoundary',
                duration: 0.510875,
                textOffset: 755,
                wordLength: 7
              },
              {
                word: 'We',
                timeSeconds: 18.3151879,
                boundaryType: 'WordBoundary',
                duration: 0.086938,
                textOffset: 844,
                wordLength: 2
              },
              {
                word: 'can',
                timeSeconds: 18.413062,
                boundaryType: 'WordBoundary',
                duration: 0.119562,
                textOffset: 848,
                wordLength: 3
              },
              {
                word: 'all',
                timeSeconds: 18.5435,
                boundaryType: 'WordBoundary',
                duration: 0.163062,
                textOffset: 853,
                wordLength: 3
              },
              {
                word: 'learn',
                timeSeconds: 18.717375,
                boundaryType: 'WordBoundary',
                duration: 0.1739379,
                textOffset: 858,
                wordLength: 5
              },
              {
                word: 'from',
                timeSeconds: 18.9021879,
                boundaryType: 'WordBoundary',
                duration: 0.163062,
                textOffset: 865,
                wordLength: 4
              },
              {
                word: 'Taylor',
                timeSeconds: 19.076062,
                boundaryType: 'WordBoundary',
                duration: 0.369562,
                textOffset: 871,
                wordLength: 6
              },
              {
                word: 'and',
                timeSeconds: 19.47825,
                boundaryType: 'WordBoundary',
                duration: 0.065188,
                textOffset: 879,
                wordLength: 3
              },
              {
                word: 'strive',
                timeSeconds: 19.554375,
                boundaryType: 'WordBoundary',
                duration: 0.336938,
                textOffset: 884,
                wordLength: 6
              },
              {
                word: 'to',
                timeSeconds: 19.9021879,
                boundaryType: 'WordBoundary',
                duration: 0.054375,
                textOffset: 892,
                wordLength: 2
              },
              {
                word: 'be',
                timeSeconds: 19.967375,
                boundaryType: 'WordBoundary',
                duration: 0.097812,
                textOffset: 896,
                wordLength: 2
              },
              {
                word: 'better',
                timeSeconds: 20.076062,
                boundaryType: 'WordBoundary',
                duration: 0.239125,
                textOffset: 900,
                wordLength: 6
              },
              {
                word: 'people',
                timeSeconds: 20.326062,
                boundaryType: 'WordBoundary',
                duration: 0.336938,
                textOffset: 908,
                wordLength: 6
              },
              {
                word: 'every',
                timeSeconds: 20.7065,
                boundaryType: 'WordBoundary',
                duration: 0.22825,
                textOffset: 916,
                wordLength: 5
              },
              {
                word: 'day',
                timeSeconds: 20.945625,
                boundaryType: 'WordBoundary',
                duration: 0.358688,
                textOffset: 923,
                wordLength: 3
              },
              {
                word: 'Follow',
                timeSeconds: 22.304375,
                boundaryType: 'WordBoundary',
                duration: 0.304375,
                textOffset: 1008,
                wordLength: 6
              },
              {
                word: 'us',
                timeSeconds: 22.619562,
                boundaryType: 'WordBoundary',
                duration: 0.097812,
                textOffset: 1016,
                wordLength: 2
              },
              {
                word: 'for',
                timeSeconds: 22.75,
                boundaryType: 'WordBoundary',
                duration: 0.097812,
                textOffset: 1020,
                wordLength: 3
              },
              {
                word: 'more',
                timeSeconds: 22.8586879,
                boundaryType: 'WordBoundary',
                duration: 0.195625,
                textOffset: 1025,
                wordLength: 4
              },
              {
                word: 'inspiring',
                timeSeconds: 23.0651879,
                boundaryType: 'WordBoundary',
                duration: 0.5,
                textOffset: 1031,
                wordLength: 9
              },
              {
                word: 'quotes',
                timeSeconds: 23.576062,
                boundaryType: 'WordBoundary',
                duration: 0.304375,
                textOffset: 1042,
                wordLength: 6
              },
              {
                word: 'from',
                timeSeconds: 23.891312,
                boundaryType: 'WordBoundary',
                duration: 0.141312,
                textOffset: 1050,
                wordLength: 4
              },
              {
                word: 'Taylor',
                timeSeconds: 24.0435,
                boundaryType: 'WordBoundary',
                duration: 0.304375,
                textOffset: 1056,
                wordLength: 6
              },
              {
                word: 'Swift',
                timeSeconds: 24.3586879,
                boundaryType: 'WordBoundary',
                duration: 0.423938,
                textOffset: 1064,
                wordLength: 5
              }
            ]
          }
        }
      ]
    },
    medias: [
      [
        'https://res.cloudinary.com/dakp804eh/image/upload/v1683859676/Woxo/Idea2video/data_set/Taylor_Swift/new/2263577504_A_picture_of_Taylor_Swift_with_a_gentle_smile_and__xl-beta-v2-2-2.png',
        'https://res.cloudinary.com/dakp804eh/image/upload/v1683859649/Woxo/Idea2video/data_set/Taylor_Swift/new/2156261779_A_picture_of_Taylor_Swift_with_a_gentle_smile_and__xl-beta-v2-2-2.png',
        'https://res.cloudinary.com/dakp804eh/image/upload/v1683859676/Woxo/Idea2video/data_set/Taylor_Swift/new/2263577504_A_picture_of_Taylor_Swift_with_a_gentle_smile_and__xl-beta-v2-2-2.png',
        'https://res.cloudinary.com/dakp804eh/image/upload/v1683859649/Woxo/Idea2video/data_set/Taylor_Swift/new/2156261779_A_picture_of_Taylor_Swift_with_a_gentle_smile_and__xl-beta-v2-2-2.png',
        'https://res.cloudinary.com/dakp804eh/image/upload/v1683859676/Woxo/Idea2video/data_set/Taylor_Swift/new/2263577504_A_picture_of_Taylor_Swift_with_a_gentle_smile_and__xl-beta-v2-2-2.png'
      ]
    ],
    music: [
      'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Happy/Feels_Good_-_MOODHALO.mp3'
    ],
    prompt: 'Taylor Swift'
  },
  {
    id: '603222ce-a81c-4c7c-a552-bea8873a8857',
    text: 'What if "Taylor Swift"',
    videos: [
      {
        id: 'ae8144cd-8478-44cd-98e9-22e936d37411',
        proportion: 0.28,
        width: 1080,
        height: 1920,
        customFonts: [
          {
            fontFamily: 'Yesteryear-Regular',
            path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1672870881/Woxo/Idea2video/fonts/Yesteryear-Regular.ttf'
          }
        ],
        audioTracks: [
          {
            path: 'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Funny/IN_LOVE_MOODHALO.mp3',
            mixVolume: 0.2
          },
          {
            path: 'https://vod.front10.cloud/output/BdHMFWCgN4/RRjF7.mp3',
            loop: false
          }
        ],
        clips: [
          {
            duration: 3.15,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'https://res.cloudinary.com/dakp804eh/image/upload/v1683861117/Woxo/Idea2video/data_set/Taylor_Swift/new/4036725402_A_portrait_of_Taylor_Swift_dressed_as_a_super_hero_xl-beta-v2-2-2.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-rotate'
                }
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '',
                customFont: {
                  fontFamily: 'Yesteryear-Regular',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1672870881/Woxo/Idea2video/fonts/Yesteryear-Regular.ttf'
                },
                fontSize: 75,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: null,
                type: 'woxo-custom-text-basic',
                hide: false,
                text: 'What if  Taylor  Swift  was  a  super  hero?',
                width: 1080,
                height: 1920,
                fontFamily: 'Yesteryear-Regular',
                animation: true,
                animationSettings: {
                  type: 'typewriter',
                  duration: 1.65
                }
              }
            ]
          },
          {
            duration: 5.300000000000001,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'https://res.cloudinary.com/dakp804eh/image/upload/v1683861117/Woxo/Idea2video/data_set/Taylor_Swift/new/1081043561_A_portrait_of_Taylor_Swift_dressed_as_a_super_hero_xl-beta-v2-2-2.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-rotate'
                }
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '',
                customFont: {
                  fontFamily: 'Yesteryear-Regular',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1672870881/Woxo/Idea2video/fonts/Yesteryear-Regular.ttf'
                },
                fontSize: 75,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: null,
                type: 'woxo-custom-text-basic',
                hide: false,
                text: 'She  would  save  the  world  with  her  powerful  voice!',
                width: 1080,
                height: 1920,
                fontFamily: 'Yesteryear-Regular',
                animation: true,
                animationSettings: {
                  type: 'typewriter',
                  duration: 3.8000000000000007
                }
              }
            ]
          },
          {
            duration: 5.5375000000000005,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'https://res.cloudinary.com/dakp804eh/image/upload/v1683861117/Woxo/Idea2video/data_set/Taylor_Swift/new/4036725402_A_portrait_of_Taylor_Swift_dressed_as_a_super_hero_xl-beta-v2-2-2.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-rotate'
                }
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '',
                customFont: {
                  fontFamily: 'Yesteryear-Regular',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1672870881/Woxo/Idea2video/fonts/Yesteryear-Regular.ttf'
                },
                fontSize: 75,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: null,
                type: 'woxo-custom-text-basic',
                hide: false,
                text: 'She  would  capture  the  hearts  of  billions  with  her  music!',
                width: 1080,
                height: 1920,
                fontFamily: 'Yesteryear-Regular',
                animation: true,
                animationSettings: {
                  type: 'typewriter',
                  duration: 4.0375000000000005
                }
              }
            ]
          },
          {
            duration: 4.587499999999999,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'https://res.cloudinary.com/dakp804eh/image/upload/v1683861117/Woxo/Idea2video/data_set/Taylor_Swift/new/1081043561_A_portrait_of_Taylor_Swift_dressed_as_a_super_hero_xl-beta-v2-2-2.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-rotate'
                }
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '',
                customFont: {
                  fontFamily: 'Yesteryear-Regular',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1672870881/Woxo/Idea2video/fonts/Yesteryear-Regular.ttf'
                },
                fontSize: 75,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: null,
                type: 'woxo-custom-text-basic',
                hide: false,
                text: 'She  would  be  the  savior  of  the  world!',
                width: 1080,
                height: 1920,
                fontFamily: 'Yesteryear-Regular',
                animation: true,
                animationSettings: {
                  type: 'typewriter',
                  duration: 3.0874999999999986
                }
              }
            ]
          },
          {
            duration: 5.837500000000002,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'https://res.cloudinary.com/dakp804eh/image/upload/v1683861117/Woxo/Idea2video/data_set/Taylor_Swift/new/4036725402_A_portrait_of_Taylor_Swift_dressed_as_a_super_hero_xl-beta-v2-2-2.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-rotate'
                }
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '',
                customFont: {
                  fontFamily: 'Yesteryear-Regular',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1672870881/Woxo/Idea2video/fonts/Yesteryear-Regular.ttf'
                },
                fontSize: 75,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: null,
                type: 'woxo-custom-text-basic',
                hide: false,
                text: 'Follow  me  for  more  exciting  stories  about  Taylor  Swift!',
                width: 1080,
                height: 1920,
                fontFamily: 'Yesteryear-Regular',
                animation: true,
                animationSettings: {
                  type: 'typewriter',
                  duration: 4.337500000000002
                }
              }
            ]
          }
        ],
        meta: {
          title: 'Taylor Swift as a Super Hero',
          description: 'What would happen if Taylor Swift was a super hero? Follow me to find out!',
          hashtags: '#TaylorSwift #Dramatic #SuperHero #shorts',
          mediaDescription:
            'A portrait of Taylor Swift dressed as a super hero with a microphone in her hand.',
          category: 'What if',
          prompt: 'Taylor Swift'
        }
      }
    ],
    category: 'What if',
    isLoading: false,
    errors: [],
    voiceover: [
      {
        scenes: [
          {
            voiceover: 'What if  Taylor  Swift  was  a  super  hero?',
            captions: 'What if ~ Taylor ~ Swift ~ was ~ a ~ *super* ~ *hero?*'
          },
          {
            voiceover: 'She  would  save  the  world  with  her  powerful  voice!',
            captions: 'She ~ would ~ *save* ~ the ~ world ~ with ~ her ~ powerful ~ voice!'
          },
          {
            voiceover: 'She  would  capture  the  hearts  of  billions  with  her  music!',
            captions: 'She ~ would ~ *capture* ~ the ~ hearts ~ of ~ billions ~ with ~ her ~ music!'
          },
          {
            voiceover: 'She  would  be  the  savior  of  the  world!',
            captions: 'She ~ would ~ be ~ the ~ *savior* ~ of ~ the ~ world!'
          },
          {
            voiceover: 'Follow  me  for  more  exciting  stories  about  Taylor  Swift!',
            captions: '*Follow* ~ me ~ for ~ more ~ *exciting* ~ stories ~ about ~ Taylor ~ Swift!'
          }
        ]
      }
    ],
    creation: {
      time: '00:00',
      step: 0
    },
    voiceoverAudio: {
      disabled: false,
      audios: [
        {
          audio: {
            path: 'https://vod.front10.cloud/output/BdHMFWCgN4/RRjF7.mp3',
            text: '<speak xmlns="http://www.w3.org/2001/10/synthesis" xmlns:mstts="http://www.w3.org/2001/mstts" xmlns:emo="http://www.w3.org/2009/10/emotionml" version="1.0" xml:lang="en-US"><voice name="en-US-TonyNeural"><mstts:express-as style="Default">What if  Taylor  Swift  was  a  super  hero?<break time="1000ms"/><bookmark name="scene_0"/>She  would  save  the  world  with  her  powerful  voice!<break time="1000ms"/><bookmark name="scene_1"/>She  would  capture  the  hearts  of  billions  with  her  music!<break time="1000ms"/><bookmark name="scene_2"/>She  would  be  the  savior  of  the  world!<break time="1000ms"/><bookmark name="scene_3"/>Follow  me  for  more  exciting  stories  about  Taylor  Swift!<break time="1000ms"/><bookmark name="scene_4"/></mstts:express-as></voice></speak>',
            timepoints: [
              {
                markName: 'scene_0',
                timeSeconds: 2.15
              },
              {
                markName: 'scene_1',
                timeSeconds: 6.45
              },
              {
                markName: 'scene_2',
                timeSeconds: 10.9875
              },
              {
                markName: 'scene_3',
                timeSeconds: 14.575
              },
              {
                markName: 'scene_4',
                timeSeconds: 19.4125
              }
            ],
            wordBoundaryTimepoints: [
              {
                word: 'What',
                timeSeconds: 0.05,
                boundaryType: 'WordBoundary',
                duration: 0.1625,
                textOffset: 238,
                wordLength: 4
              },
              {
                word: 'if',
                timeSeconds: 0.225,
                boundaryType: 'WordBoundary',
                duration: 0.125,
                textOffset: 243,
                wordLength: 2
              },
              {
                word: 'Taylor',
                timeSeconds: 0.3625,
                boundaryType: 'WordBoundary',
                duration: 0.35,
                textOffset: 247,
                wordLength: 6
              },
              {
                word: 'Swift',
                timeSeconds: 0.725,
                boundaryType: 'WordBoundary',
                duration: 0.375,
                textOffset: 255,
                wordLength: 5
              },
              {
                word: 'was',
                timeSeconds: 1.1375,
                boundaryType: 'WordBoundary',
                duration: 0.1375,
                textOffset: 262,
                wordLength: 3
              },
              {
                word: 'a',
                timeSeconds: 1.2875,
                boundaryType: 'WordBoundary',
                duration: 0.0375,
                textOffset: 267,
                wordLength: 1
              },
              {
                word: 'super',
                timeSeconds: 1.3375,
                boundaryType: 'WordBoundary',
                duration: 0.4,
                textOffset: 270,
                wordLength: 5
              },
              {
                word: 'hero',
                timeSeconds: 1.75,
                boundaryType: 'WordBoundary',
                duration: 0.4,
                textOffset: 277,
                wordLength: 4
              },
              {
                word: '?',
                timeSeconds: 2.1625,
                boundaryType: 'PunctuationBoundary',
                duration: 0.1,
                textOffset: 281,
                wordLength: 1
              },
              {
                word: 'She',
                timeSeconds: 4.0125,
                boundaryType: 'WordBoundary',
                duration: 0.25,
                textOffset: 330,
                wordLength: 3
              },
              {
                word: 'would',
                timeSeconds: 4.275,
                boundaryType: 'WordBoundary',
                duration: 0.1375,
                textOffset: 335,
                wordLength: 5
              },
              {
                word: 'save',
                timeSeconds: 4.425,
                boundaryType: 'WordBoundary',
                duration: 0.275,
                textOffset: 342,
                wordLength: 4
              },
              {
                word: 'the',
                timeSeconds: 4.7125,
                boundaryType: 'WordBoundary',
                duration: 0.0875,
                textOffset: 348,
                wordLength: 3
              },
              {
                word: 'world',
                timeSeconds: 4.8125,
                boundaryType: 'WordBoundary',
                duration: 0.35,
                textOffset: 353,
                wordLength: 5
              },
              {
                word: 'with',
                timeSeconds: 5.1875,
                boundaryType: 'WordBoundary',
                duration: 0.125,
                textOffset: 360,
                wordLength: 4
              },
              {
                word: 'her',
                timeSeconds: 5.325,
                boundaryType: 'WordBoundary',
                duration: 0.1375,
                textOffset: 366,
                wordLength: 3
              },
              {
                word: 'powerful',
                timeSeconds: 5.475,
                boundaryType: 'WordBoundary',
                duration: 0.4125,
                textOffset: 371,
                wordLength: 8
              },
              {
                word: 'voice',
                timeSeconds: 5.9,
                boundaryType: 'WordBoundary',
                duration: 0.55,
                textOffset: 381,
                wordLength: 5
              },
              {
                word: '!',
                timeSeconds: 6.4625,
                boundaryType: 'PunctuationBoundary',
                duration: 0.1125,
                textOffset: 386,
                wordLength: 1
              },
              {
                word: 'She',
                timeSeconds: 8.325,
                boundaryType: 'WordBoundary',
                duration: 0.2375,
                textOffset: 435,
                wordLength: 3
              },
              {
                word: 'would',
                timeSeconds: 8.575,
                boundaryType: 'WordBoundary',
                duration: 0.1625,
                textOffset: 440,
                wordLength: 5
              },
              {
                word: 'capture',
                timeSeconds: 8.75,
                boundaryType: 'WordBoundary',
                duration: 0.3875,
                textOffset: 447,
                wordLength: 7
              },
              {
                word: 'the',
                timeSeconds: 9.15,
                boundaryType: 'WordBoundary',
                duration: 0.0625,
                textOffset: 456,
                wordLength: 3
              },
              {
                word: 'hearts',
                timeSeconds: 9.225,
                boundaryType: 'WordBoundary',
                duration: 0.3125,
                textOffset: 461,
                wordLength: 6
              },
              {
                word: 'of',
                timeSeconds: 9.55,
                boundaryType: 'WordBoundary',
                duration: 0.125,
                textOffset: 469,
                wordLength: 2
              },
              {
                word: 'billions',
                timeSeconds: 9.6875,
                boundaryType: 'WordBoundary',
                duration: 0.45,
                textOffset: 473,
                wordLength: 8
              },
              {
                word: 'with',
                timeSeconds: 10.175,
                boundaryType: 'WordBoundary',
                duration: 0.125,
                textOffset: 483,
                wordLength: 4
              },
              {
                word: 'her',
                timeSeconds: 10.3125,
                boundaryType: 'WordBoundary',
                duration: 0.125,
                textOffset: 489,
                wordLength: 3
              },
              {
                word: 'music',
                timeSeconds: 10.45,
                boundaryType: 'WordBoundary',
                duration: 0.5375,
                textOffset: 494,
                wordLength: 5
              },
              {
                word: '!',
                timeSeconds: 11,
                boundaryType: 'PunctuationBoundary',
                duration: 0.1,
                textOffset: 499,
                wordLength: 1
              },
              {
                word: 'She',
                timeSeconds: 12.85,
                boundaryType: 'WordBoundary',
                duration: 0.25,
                textOffset: 548,
                wordLength: 3
              },
              {
                word: 'would',
                timeSeconds: 13.1125,
                boundaryType: 'WordBoundary',
                duration: 0.15,
                textOffset: 553,
                wordLength: 5
              },
              {
                word: 'be',
                timeSeconds: 13.275,
                boundaryType: 'WordBoundary',
                duration: 0.1125,
                textOffset: 560,
                wordLength: 2
              },
              {
                word: 'the',
                timeSeconds: 13.4,
                boundaryType: 'WordBoundary',
                duration: 0.0875,
                textOffset: 564,
                wordLength: 3
              },
              {
                word: 'savior',
                timeSeconds: 13.5,
                boundaryType: 'WordBoundary',
                duration: 0.4375,
                textOffset: 569,
                wordLength: 6
              },
              {
                word: 'of',
                timeSeconds: 13.95,
                boundaryType: 'WordBoundary',
                duration: 0.0625,
                textOffset: 577,
                wordLength: 2
              },
              {
                word: 'the',
                timeSeconds: 14.025,
                boundaryType: 'WordBoundary',
                duration: 0.0875,
                textOffset: 581,
                wordLength: 3
              },
              {
                word: 'world',
                timeSeconds: 14.125,
                boundaryType: 'WordBoundary',
                duration: 0.45,
                textOffset: 586,
                wordLength: 5
              },
              {
                word: '!',
                timeSeconds: 14.5875,
                boundaryType: 'PunctuationBoundary',
                duration: 0.1125,
                textOffset: 591,
                wordLength: 1
              },
              {
                word: 'Follow',
                timeSeconds: 16.45,
                boundaryType: 'WordBoundary',
                duration: 0.375,
                textOffset: 640,
                wordLength: 6
              },
              {
                word: 'me',
                timeSeconds: 16.8375,
                boundaryType: 'WordBoundary',
                duration: 0.125,
                textOffset: 648,
                wordLength: 2
              },
              {
                word: 'for',
                timeSeconds: 16.975,
                boundaryType: 'WordBoundary',
                duration: 0.1375,
                textOffset: 652,
                wordLength: 3
              },
              {
                word: 'more',
                timeSeconds: 17.125,
                boundaryType: 'WordBoundary',
                duration: 0.1875,
                textOffset: 657,
                wordLength: 4
              },
              {
                word: 'exciting',
                timeSeconds: 17.325,
                boundaryType: 'WordBoundary',
                duration: 0.4875,
                textOffset: 663,
                wordLength: 8
              },
              {
                word: 'stories',
                timeSeconds: 17.825,
                boundaryType: 'WordBoundary',
                duration: 0.45,
                textOffset: 673,
                wordLength: 7
              },
              {
                word: 'about',
                timeSeconds: 18.2875,
                boundaryType: 'WordBoundary',
                duration: 0.2625,
                textOffset: 682,
                wordLength: 5
              },
              {
                word: 'Taylor',
                timeSeconds: 18.5625,
                boundaryType: 'WordBoundary',
                duration: 0.35,
                textOffset: 689,
                wordLength: 6
              },
              {
                word: 'Swift',
                timeSeconds: 18.925,
                boundaryType: 'WordBoundary',
                duration: 0.4875,
                textOffset: 697,
                wordLength: 5
              },
              {
                word: '!',
                timeSeconds: 19.425,
                boundaryType: 'PunctuationBoundary',
                duration: 0.1,
                textOffset: 702,
                wordLength: 1
              }
            ]
          }
        }
      ]
    },
    medias: [
      [
        'https://res.cloudinary.com/dakp804eh/image/upload/v1683861117/Woxo/Idea2video/data_set/Taylor_Swift/new/4036725402_A_portrait_of_Taylor_Swift_dressed_as_a_super_hero_xl-beta-v2-2-2.png',
        'https://res.cloudinary.com/dakp804eh/image/upload/v1683861117/Woxo/Idea2video/data_set/Taylor_Swift/new/1081043561_A_portrait_of_Taylor_Swift_dressed_as_a_super_hero_xl-beta-v2-2-2.png',
        'https://res.cloudinary.com/dakp804eh/image/upload/v1683861117/Woxo/Idea2video/data_set/Taylor_Swift/new/4036725402_A_portrait_of_Taylor_Swift_dressed_as_a_super_hero_xl-beta-v2-2-2.png',
        'https://res.cloudinary.com/dakp804eh/image/upload/v1683861117/Woxo/Idea2video/data_set/Taylor_Swift/new/1081043561_A_portrait_of_Taylor_Swift_dressed_as_a_super_hero_xl-beta-v2-2-2.png',
        'https://res.cloudinary.com/dakp804eh/image/upload/v1683861117/Woxo/Idea2video/data_set/Taylor_Swift/new/4036725402_A_portrait_of_Taylor_Swift_dressed_as_a_super_hero_xl-beta-v2-2-2.png'
      ]
    ],
    music: [
      'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Funny/IN_LOVE_MOODHALO.mp3'
    ],
    prompt: 'Taylor Swift'
  },
  {
    id: '8d0cbdb2-0f4a-4f4a-a63d-6f00936050f9',
    text: 'What if "Taylor Swift" disappears',
    videos: [
      {
        id: '13249ceb-d33d-4cc2-9b57-8c863636f3de',
        proportion: 0.3,
        width: 1080,
        height: 1920,
        customFonts: [
          {
            fontFamily: 'Montserrat-SemiBold',
            path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-SemiBold.ttf'
          }
        ],
        audioTracks: [
          {
            path: 'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Inspirational/Night_Sounds_MOODHALO.mp3',
            mixVolume: 0.2
          },
          {
            path: 'https://vod.front10.cloud/output/9qLeqLW8uE/hu018.mp3',
            loop: false
          }
        ],
        clips: [
          {
            duration: 6.3875,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'https://res.cloudinary.com/dakp804eh/image/upload/v1683861419/Woxo/Idea2video/data_set/Taylor_Swift/new/4122074712_Portrait_of_Taylor_Swift_looking_out_at_the_camera_xl-beta-v2-2-2.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-rotate'
                }
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#000000',
                backgroundColor: '#FFFFFF',
                customFont: {
                  fontFamily: 'Montserrat-SemiBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-SemiBold.ttf'
                },
                fontSize: 60,
                fontWeight: 600,
                stroke: null,
                strokeWidth: null,
                shadow: null,
                type: 'woxo-custom-text-step-up',
                hide: false,
                text: 'What  if  Taylor  Swift  suddenly  disappeared?  What  would  the  music  industry  look  like  without  her?',
                width: 1080,
                height: 1920,
                fontFamily: 'Montserrat-SemiBold',
                animation: true,
                animationSettings: {
                  type: 'typewriter',
                  duration: 4.8875
                }
              }
            ]
          },
          {
            duration: 8.412500000000001,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'https://res.cloudinary.com/dakp804eh/image/upload/v1683861419/Woxo/Idea2video/data_set/Taylor_Swift/new/4136646688_Portrait_of_Taylor_Swift_looking_out_at_the_camera_xl-beta-v2-2-2.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-rotate'
                }
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#000000',
                backgroundColor: '#FFFFFF',
                customFont: {
                  fontFamily: 'Montserrat-SemiBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-SemiBold.ttf'
                },
                fontSize: 60,
                fontWeight: 600,
                stroke: null,
                strokeWidth: null,
                shadow: null,
                type: 'woxo-custom-text-step-up',
                hide: false,
                text: 'The  world  of  music  would  be  deprived  of  her  unique  perspective  on  life  and  the  stories  she  tells  through  her  songs',
                width: 1080,
                height: 1920,
                fontFamily: 'Montserrat-SemiBold',
                animation: true,
                animationSettings: {
                  type: 'typewriter',
                  duration: 6.912500000000001
                }
              }
            ]
          },
          {
            duration: 6.050000000000001,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'https://res.cloudinary.com/dakp804eh/image/upload/v1683861419/Woxo/Idea2video/data_set/Taylor_Swift/new/4122074712_Portrait_of_Taylor_Swift_looking_out_at_the_camera_xl-beta-v2-2-2.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-rotate'
                }
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#000000',
                backgroundColor: '#FFFFFF',
                customFont: {
                  fontFamily: 'Montserrat-SemiBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-SemiBold.ttf'
                },
                fontSize: 60,
                fontWeight: 600,
                stroke: null,
                strokeWidth: null,
                shadow: null,
                type: 'woxo-custom-text-step-up',
                hide: false,
                text: 'The  world  would  be  poorer  without  her  voice  that  unites  us  in  times  of  joy  and  sorrow',
                width: 1080,
                height: 1920,
                fontFamily: 'Montserrat-SemiBold',
                animation: true,
                animationSettings: {
                  type: 'typewriter',
                  duration: 4.550000000000001
                }
              }
            ]
          },
          {
            duration: 7.087499999999999,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'https://res.cloudinary.com/dakp804eh/image/upload/v1683861419/Woxo/Idea2video/data_set/Taylor_Swift/new/4136646688_Portrait_of_Taylor_Swift_looking_out_at_the_camera_xl-beta-v2-2-2.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-rotate'
                }
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#000000',
                backgroundColor: '#FFFFFF',
                customFont: {
                  fontFamily: 'Montserrat-SemiBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-SemiBold.ttf'
                },
                fontSize: 60,
                fontWeight: 600,
                stroke: null,
                strokeWidth: null,
                shadow: null,
                type: 'woxo-custom-text-step-up',
                hide: false,
                text: "Let's  celebrate  the  music  of  Taylor  Swift  and  remember  that  we're  all  connected  by  the  power  of  music",
                width: 1080,
                height: 1920,
                fontFamily: 'Montserrat-SemiBold',
                animation: true,
                animationSettings: {
                  type: 'typewriter',
                  duration: 5.587499999999999
                }
              }
            ]
          },
          {
            duration: 9.524999999999999,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'https://res.cloudinary.com/dakp804eh/image/upload/v1683861419/Woxo/Idea2video/data_set/Taylor_Swift/new/4122074712_Portrait_of_Taylor_Swift_looking_out_at_the_camera_xl-beta-v2-2-2.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-rotate'
                }
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#000000',
                backgroundColor: '#FFFFFF',
                customFont: {
                  fontFamily: 'Montserrat-SemiBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-SemiBold.ttf'
                },
                fontSize: 60,
                fontWeight: 600,
                stroke: null,
                strokeWidth: null,
                shadow: null,
                type: 'woxo-custom-text-step-up',
                hide: false,
                text: "Let's  keep  her  music  alive,  and  stay  connected  to  the  power  of  music  and  its  ability  to  bring  us  together.  Follow  me  for  more",
                width: 1080,
                height: 1920,
                fontFamily: 'Montserrat-SemiBold',
                animation: true,
                animationSettings: {
                  type: 'typewriter',
                  duration: 8.024999999999999
                }
              }
            ]
          }
        ],
        meta: {
          title: 'What if Taylor Swift Disappears?',
          description:
            'A video about the impact of Taylor Swift and her music on the music industry.',
          hashtags: '#taylorswift #musicscene #musician #musicindustry #powerofmusic #shorts',
          mediaDescription:
            'Portrait of Taylor Swift looking out at the camera with a determined expression.',
          category: 'What if dissapeared?',
          prompt: 'Taylor Swift'
        }
      }
    ],
    category: 'What if dissapeared?',
    isLoading: false,
    errors: [],
    voiceover: [
      {
        scenes: [
          {
            voiceover:
              'What  if  Taylor  Swift  suddenly  disappeared?  What  would  the  music  industry  look  like  without  her?',
            captions:
              'What ~ if ~ *Taylor* ~ *Swift* ~ suddenly ~ *disappeared?* ~ What ~ would ~ the ~ music ~ industry ~ *look* ~ *like* ~ *without* ~ *her?*'
          },
          {
            voiceover:
              'The  world  of  music  would  be  deprived  of  her  unique  perspective  on  life  and  the  stories  she  tells  through  her  songs',
            captions:
              'The ~ *world* ~ of ~ music ~ would ~ be ~ *deprived* ~ of ~ her ~ *unique* ~ perspective ~ on ~ life ~ and ~ the ~ *stories* ~ she ~ tells ~ *through* ~ her ~ *songs*'
          },
          {
            voiceover:
              'The  world  would  be  poorer  without  her  voice  that  unites  us  in  times  of  joy  and  sorrow',
            captions:
              'The ~ *world* ~ would ~ be ~ *poorer* ~ without ~ her ~ *voice* ~ that ~ *unites* ~ us ~ in ~ times ~ of ~ *joy* ~ and ~ *sorrow*'
          },
          {
            voiceover:
              "Let's  celebrate  the  music  of  Taylor  Swift  and  remember  that  we're  all  connected  by  the  power  of  music",
            captions:
              "Let's ~ *celebrate* ~ the ~ *music* ~ of ~ *Taylor* ~ *Swift* ~ and ~ remember ~ that ~ we're ~ all ~ *connected* ~ by ~ the ~ *power* ~ of ~ music"
          },
          {
            voiceover:
              "Let's  keep  her  music  alive,  and  stay  connected  to  the  power  of  music  and  its  ability  to  bring  us  together.  Follow  me  for  more",
            captions:
              "Let's ~ keep ~ her ~ *music* ~ *alive*, ~ and ~ *stay* ~ *connected* ~ to ~ the ~ *power* ~ of ~ music ~ and ~ its ~ *ability* ~ to ~ *bring* ~ us ~ *together*. ~ *Follow* ~ me ~ for ~ more"
          }
        ]
      }
    ],
    creation: {
      time: '00:00',
      step: 0
    },
    voiceoverAudio: {
      disabled: false,
      audios: [
        {
          audio: {
            path: 'https://vod.front10.cloud/output/9qLeqLW8uE/hu018.mp3',
            text: '<speak xmlns="http://www.w3.org/2001/10/synthesis" xmlns:mstts="http://www.w3.org/2001/mstts" xmlns:emo="http://www.w3.org/2009/10/emotionml" version="1.0" xml:lang="en-US"><voice name="en-US-TonyNeural"><mstts:express-as style="Default">What  if  Taylor  Swift  suddenly  disappeared?  What  would  the  music  industry  look  like  without  her?<break time="1000ms"/><bookmark name="scene_0"/>The  world  of  music  would  be  deprived  of  her  unique  perspective  on  life  and  the  stories  she  tells  through  her  songs<break time="1000ms"/><bookmark name="scene_1"/>The  world  would  be  poorer  without  her  voice  that  unites  us  in  times  of  joy  and  sorrow<break time="1000ms"/><bookmark name="scene_2"/>Let\'s  celebrate  the  music  of  Taylor  Swift  and  remember  that  we\'re  all  connected  by  the  power  of  music<break time="1000ms"/><bookmark name="scene_3"/>Let\'s  keep  her  music  alive,  and  stay  connected  to  the  power  of  music  and  its  ability  to  bring  us  together.  Follow  me  for  more<break time="1000ms"/><bookmark name="scene_4"/></mstts:express-as></voice></speak>',
            timepoints: [
              {
                markName: 'scene_0',
                timeSeconds: 5.3875
              },
              {
                markName: 'scene_1',
                timeSeconds: 12.8
              },
              {
                markName: 'scene_2',
                timeSeconds: 17.85
              },
              {
                markName: 'scene_3',
                timeSeconds: 23.9375
              },
              {
                markName: 'scene_4',
                timeSeconds: 32.4625
              }
            ],
            wordBoundaryTimepoints: [
              {
                word: 'What',
                timeSeconds: 0.05,
                boundaryType: 'WordBoundary',
                duration: 0.15,
                textOffset: 238,
                wordLength: 4
              },
              {
                word: 'if',
                timeSeconds: 0.2125,
                boundaryType: 'WordBoundary',
                duration: 0.125,
                textOffset: 244,
                wordLength: 2
              },
              {
                word: 'Taylor',
                timeSeconds: 0.35,
                boundaryType: 'WordBoundary',
                duration: 0.35,
                textOffset: 248,
                wordLength: 6
              },
              {
                word: 'Swift',
                timeSeconds: 0.7125,
                boundaryType: 'WordBoundary',
                duration: 0.375,
                textOffset: 256,
                wordLength: 5
              },
              {
                word: 'suddenly',
                timeSeconds: 1.1,
                boundaryType: 'WordBoundary',
                duration: 0.375,
                textOffset: 263,
                wordLength: 8
              },
              {
                word: 'disappeared',
                timeSeconds: 1.4875,
                boundaryType: 'WordBoundary',
                duration: 0.725,
                textOffset: 273,
                wordLength: 11
              },
              {
                word: '?',
                timeSeconds: 2.225,
                boundaryType: 'PunctuationBoundary',
                duration: 0.1125,
                textOffset: 284,
                wordLength: 1
              },
              {
                word: 'What',
                timeSeconds: 3.0875,
                boundaryType: 'WordBoundary',
                duration: 0.175,
                textOffset: 287,
                wordLength: 4
              },
              {
                word: 'would',
                timeSeconds: 3.275,
                boundaryType: 'WordBoundary',
                duration: 0.1125,
                textOffset: 293,
                wordLength: 5
              },
              {
                word: 'the',
                timeSeconds: 3.4,
                boundaryType: 'WordBoundary',
                duration: 0.0625,
                textOffset: 300,
                wordLength: 3
              },
              {
                word: 'music',
                timeSeconds: 3.475,
                boundaryType: 'WordBoundary',
                duration: 0.3875,
                textOffset: 305,
                wordLength: 5
              },
              {
                word: 'industry',
                timeSeconds: 3.875,
                boundaryType: 'WordBoundary',
                duration: 0.425,
                textOffset: 312,
                wordLength: 8
              },
              {
                word: 'look',
                timeSeconds: 4.3125,
                boundaryType: 'WordBoundary',
                duration: 0.225,
                textOffset: 322,
                wordLength: 4
              },
              {
                word: 'like',
                timeSeconds: 4.55,
                boundaryType: 'WordBoundary',
                duration: 0.2,
                textOffset: 328,
                wordLength: 4
              },
              {
                word: 'without',
                timeSeconds: 4.7625,
                boundaryType: 'WordBoundary',
                duration: 0.3125,
                textOffset: 334,
                wordLength: 7
              },
              {
                word: 'her',
                timeSeconds: 5.0875,
                boundaryType: 'WordBoundary',
                duration: 0.3,
                textOffset: 343,
                wordLength: 3
              },
              {
                word: '?',
                timeSeconds: 5.4,
                boundaryType: 'PunctuationBoundary',
                duration: 0.1125,
                textOffset: 346,
                wordLength: 1
              },
              {
                word: 'The',
                timeSeconds: 7.2625,
                boundaryType: 'WordBoundary',
                duration: 0.125,
                textOffset: 395,
                wordLength: 3
              },
              {
                word: 'world',
                timeSeconds: 7.4,
                boundaryType: 'WordBoundary',
                duration: 0.275,
                textOffset: 400,
                wordLength: 5
              },
              {
                word: 'of',
                timeSeconds: 7.6875,
                boundaryType: 'WordBoundary',
                duration: 0.0625,
                textOffset: 407,
                wordLength: 2
              },
              {
                word: 'music',
                timeSeconds: 7.7625,
                boundaryType: 'WordBoundary',
                duration: 0.4625,
                textOffset: 411,
                wordLength: 5
              },
              {
                word: 'would',
                timeSeconds: 8.2625,
                boundaryType: 'WordBoundary',
                duration: 0.1125,
                textOffset: 418,
                wordLength: 5
              },
              {
                word: 'be',
                timeSeconds: 8.3875,
                boundaryType: 'WordBoundary',
                duration: 0.1,
                textOffset: 425,
                wordLength: 2
              },
              {
                word: 'deprived',
                timeSeconds: 8.5,
                boundaryType: 'WordBoundary',
                duration: 0.5,
                textOffset: 429,
                wordLength: 8
              },
              {
                word: 'of',
                timeSeconds: 9.0125,
                boundaryType: 'WordBoundary',
                duration: 0.0625,
                textOffset: 439,
                wordLength: 2
              },
              {
                word: 'her',
                timeSeconds: 9.0875,
                boundaryType: 'WordBoundary',
                duration: 0.125,
                textOffset: 443,
                wordLength: 3
              },
              {
                word: 'unique',
                timeSeconds: 9.225,
                boundaryType: 'WordBoundary',
                duration: 0.35,
                textOffset: 448,
                wordLength: 6
              },
              {
                word: 'perspective',
                timeSeconds: 9.5875,
                boundaryType: 'WordBoundary',
                duration: 0.625,
                textOffset: 456,
                wordLength: 11
              },
              {
                word: 'on',
                timeSeconds: 10.225,
                boundaryType: 'WordBoundary',
                duration: 0.125,
                textOffset: 469,
                wordLength: 2
              },
              {
                word: 'life',
                timeSeconds: 10.3625,
                boundaryType: 'WordBoundary',
                duration: 0.3375,
                textOffset: 473,
                wordLength: 4
              },
              {
                word: 'and',
                timeSeconds: 10.7625,
                boundaryType: 'WordBoundary',
                duration: 0.0625,
                textOffset: 479,
                wordLength: 3
              },
              {
                word: 'the',
                timeSeconds: 10.8375,
                boundaryType: 'WordBoundary',
                duration: 0.0875,
                textOffset: 484,
                wordLength: 3
              },
              {
                word: 'stories',
                timeSeconds: 10.9375,
                boundaryType: 'WordBoundary',
                duration: 0.4875,
                textOffset: 489,
                wordLength: 7
              },
              {
                word: 'she',
                timeSeconds: 11.4375,
                boundaryType: 'WordBoundary',
                duration: 0.175,
                textOffset: 498,
                wordLength: 3
              },
              {
                word: 'tells',
                timeSeconds: 11.625,
                boundaryType: 'WordBoundary',
                duration: 0.3125,
                textOffset: 503,
                wordLength: 5
              },
              {
                word: 'through',
                timeSeconds: 11.95,
                boundaryType: 'WordBoundary',
                duration: 0.125,
                textOffset: 510,
                wordLength: 7
              },
              {
                word: 'her',
                timeSeconds: 12.0875,
                boundaryType: 'WordBoundary',
                duration: 0.125,
                textOffset: 519,
                wordLength: 3
              },
              {
                word: 'songs',
                timeSeconds: 12.225,
                boundaryType: 'WordBoundary',
                duration: 0.575,
                textOffset: 524,
                wordLength: 5
              },
              {
                word: 'The',
                timeSeconds: 13.8,
                boundaryType: 'WordBoundary',
                duration: 0.075,
                textOffset: 577,
                wordLength: 3
              },
              {
                word: 'world',
                timeSeconds: 13.8875,
                boundaryType: 'WordBoundary',
                duration: 0.3,
                textOffset: 582,
                wordLength: 5
              },
              {
                word: 'would',
                timeSeconds: 14.2,
                boundaryType: 'WordBoundary',
                duration: 0.1125,
                textOffset: 589,
                wordLength: 5
              },
              {
                word: 'be',
                timeSeconds: 14.325,
                boundaryType: 'WordBoundary',
                duration: 0.1125,
                textOffset: 596,
                wordLength: 2
              },
              {
                word: 'poorer',
                timeSeconds: 14.45,
                boundaryType: 'WordBoundary',
                duration: 0.375,
                textOffset: 600,
                wordLength: 6
              },
              {
                word: 'without',
                timeSeconds: 14.85,
                boundaryType: 'WordBoundary',
                duration: 0.275,
                textOffset: 608,
                wordLength: 7
              },
              {
                word: 'her',
                timeSeconds: 15.1375,
                boundaryType: 'WordBoundary',
                duration: 0.1,
                textOffset: 617,
                wordLength: 3
              },
              {
                word: 'voice',
                timeSeconds: 15.25,
                boundaryType: 'WordBoundary',
                duration: 0.35,
                textOffset: 622,
                wordLength: 5
              },
              {
                word: 'that',
                timeSeconds: 15.6375,
                boundaryType: 'WordBoundary',
                duration: 0.1375,
                textOffset: 629,
                wordLength: 4
              },
              {
                word: 'unites',
                timeSeconds: 15.7875,
                boundaryType: 'WordBoundary',
                duration: 0.35,
                textOffset: 635,
                wordLength: 6
              },
              {
                word: 'us',
                timeSeconds: 16.15,
                boundaryType: 'WordBoundary',
                duration: 0.125,
                textOffset: 643,
                wordLength: 2
              },
              {
                word: 'in',
                timeSeconds: 16.2875,
                boundaryType: 'WordBoundary',
                duration: 0.075,
                textOffset: 647,
                wordLength: 2
              },
              {
                word: 'times',
                timeSeconds: 16.375,
                boundaryType: 'WordBoundary',
                duration: 0.35,
                textOffset: 651,
                wordLength: 5
              },
              {
                word: 'of',
                timeSeconds: 16.7375,
                boundaryType: 'WordBoundary',
                duration: 0.1,
                textOffset: 658,
                wordLength: 2
              },
              {
                word: 'joy',
                timeSeconds: 16.85,
                boundaryType: 'WordBoundary',
                duration: 0.3375,
                textOffset: 662,
                wordLength: 3
              },
              {
                word: 'and',
                timeSeconds: 17.225,
                boundaryType: 'WordBoundary',
                duration: 0.1125,
                textOffset: 667,
                wordLength: 3
              },
              {
                word: 'sorrow',
                timeSeconds: 17.35,
                boundaryType: 'WordBoundary',
                duration: 0.5,
                textOffset: 672,
                wordLength: 6
              },
              {
                word: "Let's",
                timeSeconds: 18.85,
                boundaryType: 'WordBoundary',
                duration: 0.25,
                textOffset: 726,
                wordLength: 5
              },
              {
                word: 'celebrate',
                timeSeconds: 19.1125,
                boundaryType: 'WordBoundary',
                duration: 0.4875,
                textOffset: 733,
                wordLength: 9
              },
              {
                word: 'the',
                timeSeconds: 19.6125,
                boundaryType: 'WordBoundary',
                duration: 0.0625,
                textOffset: 744,
                wordLength: 3
              },
              {
                word: 'music',
                timeSeconds: 19.6875,
                boundaryType: 'WordBoundary',
                duration: 0.4125,
                textOffset: 749,
                wordLength: 5
              },
              {
                word: 'of',
                timeSeconds: 20.1125,
                boundaryType: 'WordBoundary',
                duration: 0.1,
                textOffset: 756,
                wordLength: 2
              },
              {
                word: 'Taylor',
                timeSeconds: 20.225,
                boundaryType: 'WordBoundary',
                duration: 0.3625,
                textOffset: 760,
                wordLength: 6
              },
              {
                word: 'Swift',
                timeSeconds: 20.6,
                boundaryType: 'WordBoundary',
                duration: 0.475,
                textOffset: 768,
                wordLength: 5
              },
              {
                word: 'and',
                timeSeconds: 21.1125,
                boundaryType: 'WordBoundary',
                duration: 0.1,
                textOffset: 775,
                wordLength: 3
              },
              {
                word: 'remember',
                timeSeconds: 21.225,
                boundaryType: 'WordBoundary',
                duration: 0.4375,
                textOffset: 780,
                wordLength: 8
              },
              {
                word: 'that',
                timeSeconds: 21.675,
                boundaryType: 'WordBoundary',
                duration: 0.1625,
                textOffset: 790,
                wordLength: 4
              },
              {
                word: "we're",
                timeSeconds: 21.85,
                boundaryType: 'WordBoundary',
                duration: 0.1625,
                textOffset: 796,
                wordLength: 5
              },
              {
                word: 'all',
                timeSeconds: 22.025,
                boundaryType: 'WordBoundary',
                duration: 0.175,
                textOffset: 803,
                wordLength: 3
              },
              {
                word: 'connected',
                timeSeconds: 22.2125,
                boundaryType: 'WordBoundary',
                duration: 0.4625,
                textOffset: 808,
                wordLength: 9
              },
              {
                word: 'by',
                timeSeconds: 22.6875,
                boundaryType: 'WordBoundary',
                duration: 0.1125,
                textOffset: 819,
                wordLength: 2
              },
              {
                word: 'the',
                timeSeconds: 22.8125,
                boundaryType: 'WordBoundary',
                duration: 0.1125,
                textOffset: 823,
                wordLength: 3
              },
              {
                word: 'power',
                timeSeconds: 22.9375,
                boundaryType: 'WordBoundary',
                duration: 0.35,
                textOffset: 828,
                wordLength: 5
              },
              {
                word: 'of',
                timeSeconds: 23.3,
                boundaryType: 'WordBoundary',
                duration: 0.075,
                textOffset: 835,
                wordLength: 2
              },
              {
                word: 'music',
                timeSeconds: 23.3875,
                boundaryType: 'WordBoundary',
                duration: 0.55,
                textOffset: 839,
                wordLength: 5
              },
              {
                word: "Let's",
                timeSeconds: 24.9375,
                boundaryType: 'WordBoundary',
                duration: 0.2,
                textOffset: 892,
                wordLength: 5
              },
              {
                word: 'keep',
                timeSeconds: 25.15,
                boundaryType: 'WordBoundary',
                duration: 0.2125,
                textOffset: 899,
                wordLength: 4
              },
              {
                word: 'her',
                timeSeconds: 25.375,
                boundaryType: 'WordBoundary',
                duration: 0.1,
                textOffset: 905,
                wordLength: 3
              },
              {
                word: 'music',
                timeSeconds: 25.4875,
                boundaryType: 'WordBoundary',
                duration: 0.35,
                textOffset: 910,
                wordLength: 5
              },
              {
                word: 'alive',
                timeSeconds: 25.85,
                boundaryType: 'WordBoundary',
                duration: 0.5125,
                textOffset: 917,
                wordLength: 5
              },
              {
                word: ',',
                timeSeconds: 26.4625,
                boundaryType: 'PunctuationBoundary',
                duration: 0.125,
                textOffset: 922,
                wordLength: 1
              },
              {
                word: 'and',
                timeSeconds: 26.5875,
                boundaryType: 'WordBoundary',
                duration: 0.1,
                textOffset: 925,
                wordLength: 3
              },
              {
                word: 'stay',
                timeSeconds: 26.7,
                boundaryType: 'WordBoundary',
                duration: 0.2875,
                textOffset: 930,
                wordLength: 4
              },
              {
                word: 'connected',
                timeSeconds: 27,
                boundaryType: 'WordBoundary',
                duration: 0.5125,
                textOffset: 936,
                wordLength: 9
              },
              {
                word: 'to',
                timeSeconds: 27.5375,
                boundaryType: 'WordBoundary',
                duration: 0.0625,
                textOffset: 947,
                wordLength: 2
              },
              {
                word: 'the',
                timeSeconds: 27.6125,
                boundaryType: 'WordBoundary',
                duration: 0.1125,
                textOffset: 951,
                wordLength: 3
              },
              {
                word: 'power',
                timeSeconds: 27.7375,
                boundaryType: 'WordBoundary',
                duration: 0.3625,
                textOffset: 956,
                wordLength: 5
              },
              {
                word: 'of',
                timeSeconds: 28.1125,
                boundaryType: 'WordBoundary',
                duration: 0.0875,
                textOffset: 963,
                wordLength: 2
              },
              {
                word: 'music',
                timeSeconds: 28.2125,
                boundaryType: 'WordBoundary',
                duration: 0.4875,
                textOffset: 967,
                wordLength: 5
              },
              {
                word: 'and',
                timeSeconds: 28.7625,
                boundaryType: 'WordBoundary',
                duration: 0.0875,
                textOffset: 974,
                wordLength: 3
              },
              {
                word: 'its',
                timeSeconds: 28.8625,
                boundaryType: 'WordBoundary',
                duration: 0.125,
                textOffset: 979,
                wordLength: 3
              },
              {
                word: 'ability',
                timeSeconds: 29,
                boundaryType: 'WordBoundary',
                duration: 0.45,
                textOffset: 984,
                wordLength: 7
              },
              {
                word: 'to',
                timeSeconds: 29.4625,
                boundaryType: 'WordBoundary',
                duration: 0.0875,
                textOffset: 993,
                wordLength: 2
              },
              {
                word: 'bring',
                timeSeconds: 29.5625,
                boundaryType: 'WordBoundary',
                duration: 0.2375,
                textOffset: 997,
                wordLength: 5
              },
              {
                word: 'us',
                timeSeconds: 29.8125,
                boundaryType: 'WordBoundary',
                duration: 0.1,
                textOffset: 1004,
                wordLength: 2
              },
              {
                word: 'together',
                timeSeconds: 29.925,
                boundaryType: 'WordBoundary',
                duration: 0.55,
                textOffset: 1008,
                wordLength: 8
              },
              {
                word: '.',
                timeSeconds: 30.4875,
                boundaryType: 'PunctuationBoundary',
                duration: 0.1125,
                textOffset: 1016,
                wordLength: 1
              },
              {
                word: 'Follow',
                timeSeconds: 31.35,
                boundaryType: 'WordBoundary',
                duration: 0.425,
                textOffset: 1019,
                wordLength: 6
              },
              {
                word: 'me',
                timeSeconds: 31.7875,
                boundaryType: 'WordBoundary',
                duration: 0.1125,
                textOffset: 1027,
                wordLength: 2
              },
              {
                word: 'for',
                timeSeconds: 31.9125,
                boundaryType: 'WordBoundary',
                duration: 0.15,
                textOffset: 1031,
                wordLength: 3
              },
              {
                word: 'more',
                timeSeconds: 32.075,
                boundaryType: 'WordBoundary',
                duration: 0.3875,
                textOffset: 1036,
                wordLength: 4
              }
            ]
          }
        }
      ]
    },
    medias: [
      [
        'https://res.cloudinary.com/dakp804eh/image/upload/v1683861419/Woxo/Idea2video/data_set/Taylor_Swift/new/4122074712_Portrait_of_Taylor_Swift_looking_out_at_the_camera_xl-beta-v2-2-2.png',
        'https://res.cloudinary.com/dakp804eh/image/upload/v1683861419/Woxo/Idea2video/data_set/Taylor_Swift/new/4136646688_Portrait_of_Taylor_Swift_looking_out_at_the_camera_xl-beta-v2-2-2.png',
        'https://res.cloudinary.com/dakp804eh/image/upload/v1683861419/Woxo/Idea2video/data_set/Taylor_Swift/new/4122074712_Portrait_of_Taylor_Swift_looking_out_at_the_camera_xl-beta-v2-2-2.png',
        'https://res.cloudinary.com/dakp804eh/image/upload/v1683861419/Woxo/Idea2video/data_set/Taylor_Swift/new/4136646688_Portrait_of_Taylor_Swift_looking_out_at_the_camera_xl-beta-v2-2-2.png',
        'https://res.cloudinary.com/dakp804eh/image/upload/v1683861419/Woxo/Idea2video/data_set/Taylor_Swift/new/4122074712_Portrait_of_Taylor_Swift_looking_out_at_the_camera_xl-beta-v2-2-2.png'
      ]
    ],
    music: [
      'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Inspirational/Night_Sounds_MOODHALO.mp3'
    ],
    prompt: 'Taylor Swift'
  }
];

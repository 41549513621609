import { v4 } from 'uuid';

// !INFO: take into account if the default data the text changes the position
export const videoTemplate = () => {
  return {
    id: v4(),
    proportion: 0.2,
    width: 1080,
    height: 1920,
    customFonts: [
      {
        fontFamily: 'Montserrat-SemiBold',
        path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1673383815/Woxo/Idea2video/fonts/Montserrat-SemiBold.ttf'
      }
    ],
    audioTracks: [],
    clips: [
      {
        duration: 5.2,
        transition: {
          duration: 1,
          name: 'random',
          easing: null
        },
        layers: [
          {
            type: 'woxo-image',
            path: ''
          },
          {
            type: 'woxo-custom-text-step-up',
            text: '',
            width: 1080,
            height: 1920,
            fontSize: '60',
            fontFamily: 'Montserrat-SemiBold',
            fontWeight: 600,
            backgroundColor: '#fff',
            fill: '#000',
            top: 'center',
            animation: true,
            animationSettings: {
              type: 'typewriter'
            }
          }
        ]
      }
    ],
    meta: {
      title: '',
      description: '',
      hashtags: '',
      mediaDescription: ''
    }
  };
};

export const sceneTemplate = () => {
  return {
    duration: 5.2,
    transition: {
      duration: 1,
      name: 'random',
      easing: null
    },
    layers: [
      {
        type: 'woxo-image',
        path: ''
      },
      {
        type: 'woxo-custom-text-step-up',
        text: '',
        width: 1080,
        height: 1920,
        fontSize: '60',
        fontFamily: 'Montserrat-SemiBold',
        fontWeight: 600,
        backgroundColor: '#fff',
        fill: '#000',
        top: 'bottom',
        animation: true,
        animationSettings: {
          type: 'typewriter'
        }
      }
    ]
  };
};

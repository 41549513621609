export const voiceoverEN = [
  {
    label: 'Eric',
    value: 'tony',
    language: 'EN',
    avatar: 'https://i.pravatar.cc/30?img=11',
    styles: [
      {
        value: 'Default',
        label: 'Normal'
      },
      {
        value: 'hopeful',
        label: 'Hopeful'
      },
      {
        value: 'friendly',
        label: 'Friendly'
      },
      {
        value: 'sad',
        label: 'Sad'
      },
      {
        value: 'unfriendly',
        label: 'Unfriendly'
      }
    ]
  },
  {
    label: 'Elsie',
    value: 'aria',
    language: 'EN',
    avatar: 'https://i.pravatar.cc/30?img=48',
    styles: [
      {
        value: 'Default',
        label: 'Normal'
      },
      {
        value: 'chat',
        label: 'Chat'
      },
      {
        value: 'customerservice',
        label: 'Service'
      },
      {
        value: 'narration-professional',
        label: 'Narration'
      },
      {
        value: 'newscast-casual',
        label: 'Casual'
      },
      {
        value: 'newscast-formal',
        label: 'Formal'
      },
      {
        value: 'cheerful',
        label: 'Cheerful'
      },
      {
        value: 'empathetic',
        label: 'Empathetic'
      },
      {
        value: 'sad',
        label: 'Sad'
      },
      {
        value: 'friendly',
        label: 'Friendly'
      },
      {
        value: 'unfriendly',
        label: 'Unfriendly'
      },
      {
        value: 'hopeful',
        label: 'Hopeful'
      }
    ]
  },
  {
    label: 'Bill',
    value: 'davis',
    language: 'EN',
    avatar: 'https://i.pravatar.cc/30?img=13',
    styles: [
      {
        value: 'Default',
        label: 'Normal'
      },
      {
        value: 'chat',
        label: 'Chat'
      },
      {
        value: 'cheerful',
        label: 'Cheerful'
      },
      {
        value: 'friendly',
        label: 'Friendly'
      },
      {
        value: 'hopeful',
        label: 'Hopeful'
      },
      {
        value: 'unfriendly',
        label: 'Unfriendly'
      }
    ]
  },
  {
    label: 'Rebecca',
    value: 'sara',
    language: 'EN',
    avatar: 'https://i.pravatar.cc/30?img=49',
    styles: [
      {
        value: 'Default',
        label: 'Normal'
      },
      {
        value: 'cheerful',
        label: 'Cheerful'
      },
      {
        value: 'friendly',
        label: 'Friendly'
      },
      {
        value: 'hopeful',
        label: 'Hopeful'
      },
      {
        value: 'sad',
        label: 'Sad'
      },
      {
        value: 'unfriendly',
        label: 'Unfriendly'
      }
    ]
  },
  {
    label: 'Andy',
    value: 'guy',
    language: 'EN',
    avatar: 'https://i.pravatar.cc/30?img=14',
    styles: [
      {
        value: 'Default',
        label: 'Normal'
      },
      {
        value: 'newscast',
        label: 'Newscast'
      },
      {
        value: 'cheerful',
        label: 'Cheerful'
      },
      {
        value: 'sad',
        label: 'Sad'
      },
      {
        value: 'friendly',
        label: 'Friendly'
      },
      {
        value: 'unfriendly',
        label: 'Unfriendly'
      },
      {
        value: 'hopeful',
        label: 'Hopeful'
      }
    ]
  },
  {
    label: 'Mia',
    value: 'nancy',
    language: 'EN',
    category: 'W',
    avatar: 'https://i.pravatar.cc/30?img=47',
    styles: [
      {
        value: 'Default',
        label: 'Normal'
      },
      {
        value: 'cheerful',
        label: 'Cheerful'
      },
      {
        value: 'friendly',
        label: 'Friendly'
      },
      {
        value: 'hopeful',
        label: 'Hopeful'
      },
      {
        value: 'sad',
        label: 'Sad'
      },
      {
        value: 'unfriendly',
        label: 'Unfriendly'
      }
    ]
  },
  {
    label: 'Patrick',
    value: 'jason',
    language: 'EN',
    avatar: 'https://i.pravatar.cc/30?img=67',
    styles: [
      {
        value: 'Default',
        label: 'Normal'
      },
      {
        value: 'cheerful',
        label: 'Cheerful'
      },
      {
        value: 'friendly',
        label: 'Friendly'
      },
      {
        value: 'hopeful',
        label: 'Hopeful'
      },
      {
        value: 'sad',
        label: 'Sad'
      },
      {
        value: 'unfriendly',
        label: 'Unfriendly'
      }
    ]
  },
  {
    label: 'Claire',
    value: 'amber',
    language: 'EN',
    avatar: 'https://i.pravatar.cc/30?img=45',
    styles: [
      {
        value: 'Default',
        label: 'Normal'
      }
    ]
  },
  {
    label: 'Brian',
    value: 'christopher',
    language: 'EN',
    avatar: 'https://i.pravatar.cc/30?img=68',
    styles: [
      {
        value: 'Default',
        label: 'Normal'
      }
    ]
  },
  {
    label: 'Monica',
    value: 'ana',
    language: 'EN',
    avatar: 'https://i.pravatar.cc/30?img=46',
    styles: [
      {
        value: 'Default',
        label: 'Normal'
      }
    ]
  },
  {
    label: 'Anthony',
    value: 'steffan',
    language: 'EN',
    avatar: 'https://i.pravatar.cc/30?img=65',
    styles: [
      {
        value: 'Default',
        label: 'Normal'
      }
    ]
  },
  {
    label: 'Naomi',
    value: 'ashley',
    language: 'EN',
    avatar: 'https://i.pravatar.cc/30?img=44',
    styles: [
      {
        value: 'Default',
        label: 'Normal'
      }
    ]
  },
  {
    label: 'Jackson',
    value: 'roger',
    language: 'EN',
    avatar: 'https://i.pravatar.cc/30?img=18',
    styles: [
      {
        value: 'Default',
        label: 'Normal'
      }
    ]
  }
];

export const voiceoverES = [
  {
    label: 'Alejandro',
    value: 'jorge',
    language: 'ES',
    avatar: 'https://i.pravatar.cc/30?img=12',
    styles: [
      {
        value: 'Default',
        label: 'Normal'
      },
      {
        value: 'cheerful',
        label: 'Cheerful'
      },
      {
        value: 'chat',
        label: 'Chat'
      }
    ]
  },
  {
    label: 'Renata',
    value: 'paloma',
    language: 'ES',
    avatar: 'https://i.pravatar.cc/30?img=43',
    styles: [
      {
        value: 'Default',
        label: 'Normal'
      }
    ]
  },
  {
    label: 'Pilar',
    value: 'nuria',
    language: 'ES',
    avatar: 'https://i.pravatar.cc/30?img=42',
    styles: [
      {
        value: 'Default',
        label: 'Normal'
      }
    ]
  },
  {
    label: 'Alberto',
    value: 'cecilio',
    language: 'ES',
    avatar: 'https://i.pravatar.cc/30?img=62',
    styles: [
      {
        value: 'Default',
        label: 'Normal'
      }
    ]
  },
  {
    label: 'Silvio',
    value: 'luciano',
    language: 'ES',
    avatar: 'https://i.pravatar.cc/30?img=61',
    styles: [
      {
        value: 'Default',
        label: 'Normal'
      }
    ]
  },
  {
    label: 'Eugenia',
    value: 'carlota',
    language: 'ES',
    avatar: 'https://i.pravatar.cc/30?img=41',
    styles: [
      {
        value: 'Default',
        label: 'Normal'
      }
    ]
  },
  {
    label: 'Vicente',
    value: 'liberto',
    language: 'ES',
    avatar: 'https://i.pravatar.cc/30?img=60',
    styles: [
      {
        value: 'Default',
        label: 'Normal'
      }
    ]
  },
  {
    label: 'Celia',
    value: 'dalia',
    language: 'ES',
    avatar: 'https://i.pravatar.cc/30?img=40',
    styles: [
      {
        value: 'Default',
        label: 'Normal'
      }
    ]
  },
  {
    label: 'Rosario',
    value: 'beatriz',
    language: 'ES',
    avatar: 'https://i.pravatar.cc/30?img=39',
    styles: [
      {
        value: 'Default',
        label: 'Normal'
      }
    ]
  },
  {
    label: 'Francisco',
    value: 'arnau',
    language: 'ES',
    avatar: 'https://i.pravatar.cc/30?img=33',
    styles: [
      {
        value: 'Default',
        label: 'Normal'
      }
    ]
  },
  {
    label: 'Luna',
    value: 'estrella',
    language: 'ES',
    avatar: 'https://i.pravatar.cc/30?img=5',
    styles: [
      {
        value: 'Default',
        label: 'Normal'
      }
    ]
  },
  {
    label: 'Vanesa',
    value: 'abril',
    language: 'ES',
    avatar: 'https://i.pravatar.cc/30?img=1',
    styles: [
      {
        value: 'Default',
        label: 'Normal'
      }
    ]
  },
  {
    label: 'Adriana',
    value: 'irene',
    language: 'ES',
    avatar: 'https://i.pravatar.cc/30?img=2',
    styles: [
      {
        value: 'Default',
        label: 'Normal'
      }
    ]
  }
];

export const voiceoverFR = [
  {
    label: 'Pierre',
    value: 'henri',
    language: 'FR',
    avatar: 'https://i.pravatar.cc/30?img=59',
    styles: [
      {
        value: 'Default',
        label: 'Normal'
      },
      {
        value: 'cheerful',
        label: 'Cheerful'
      },
      {
        value: 'sad',
        label: 'Sad'
      }
    ]
  },
  {
    label: 'Renard',
    value: 'alain',
    language: 'FR',
    avatar: 'https://i.pravatar.cc/30?img=58',
    styles: [
      {
        value: 'Default',
        label: 'Normal'
      }
    ]
  },
  {
    label: 'Aimeé',
    value: 'brigitte',
    language: 'FR',
    avatar: 'https://i.pravatar.cc/30?img=39',
    styles: [
      {
        value: 'Default',
        label: 'Normal'
      }
    ]
  },
  {
    label: 'Nina',
    value: 'denise',
    language: 'FR',
    avatar: 'https://i.pravatar.cc/30?img=34',
    styles: [
      {
        value: 'Default',
        label: 'Normal'
      }
    ]
  },
  {
    label: 'Désirée',
    value: 'eloise',
    language: 'FR',
    avatar: 'https://i.pravatar.cc/30?img=37',
    styles: [
      {
        value: 'Default',
        label: 'Normal'
      }
    ]
  },
  {
    label: 'Pauline',
    value: 'jacqueline',
    language: 'FR',
    avatar: 'https://i.pravatar.cc/30?img=31',
    styles: [
      {
        value: 'Default',
        label: 'Normal'
      }
    ]
  },
  {
    label: 'Roland',
    value: 'jerome',
    language: 'FR',
    avatar: 'https://i.pravatar.cc/30?img=57',
    styles: [
      {
        value: 'Default',
        label: 'Normal'
      }
    ]
  },
  {
    label: 'Francine',
    value: 'josephine',
    language: 'FR',
    avatar: 'https://i.pravatar.cc/30?img=30',
    styles: [
      {
        value: 'Default',
        label: 'Normal'
      }
    ]
  },
  {
    label: 'Jori',
    value: 'maurice',
    language: 'FR',
    avatar: 'https://i.pravatar.cc/30?img=56',
    styles: [
      {
        value: 'Default',
        label: 'Normal'
      }
    ]
  },
  {
    label: 'Marion',
    value: 'yvette',
    language: 'FR',
    avatar: 'https://i.pravatar.cc/30?img=28',
    styles: [
      {
        value: 'Default',
        label: 'Normal'
      }
    ]
  }
];

export const voiceoverHI = [
  {
    label: 'Aarav',
    value: 'madhur',
    language: 'HI',
    avatar: 'https://i.pravatar.cc/30?img=55',
    styles: [
      {
        value: 'Default',
        label: 'Normal'
      }
    ]
  },
  {
    label: 'Sanjana',
    value: 'swara',
    language: 'HI',
    avatar: 'https://i.pravatar.cc/30?img=27',
    styles: [
      {
        value: 'Default',
        label: 'Normal'
      }
    ]
  }
];

export const voiceoverDE = [
  {
    label: 'Elsa',
    value: 'amala',
    language: 'DE',
    avatar: 'https://i.pravatar.cc/30?img=26',
    styles: [
      {
        value: 'Default',
        label: 'Normal'
      }
    ]
  },
  {
    label: 'Ulrich',
    value: 'bernd',
    language: 'DE',
    avatar: 'https://i.pravatar.cc/30?img=54',
    styles: [
      {
        value: 'Default',
        label: 'Normal'
      }
    ]
  },
  {
    label: 'Lukas',
    value: 'christoph',
    language: 'DE',
    avatar: 'https://i.pravatar.cc/30?img=53',
    styles: [
      {
        value: 'Default',
        label: 'Normal'
      }
    ]
  },
  {
    label: 'Aldo',
    value: 'conrad',
    language: 'DE',
    avatar: 'https://i.pravatar.cc/30?img=52',
    styles: [
      {
        value: 'Default',
        label: 'Normal'
      }
    ]
  }
];

export const voiceoverPT = [
  {
    label: 'Rodrigo',
    value: 'antonio',
    language: 'PT',
    avatar: 'https://i.pravatar.cc/30?img=51',
    styles: [
      {
        value: 'Default',
        label: 'Normal'
      }
    ]
  },
  {
    label: 'Fabiana',
    value: 'brenda',
    language: 'PT',
    avatar: 'https://i.pravatar.cc/30?img=25',
    styles: [
      {
        value: 'Default',
        label: 'Normal'
      }
    ]
  },
  {
    label: 'Mateo',
    value: 'donato',
    language: 'PT',
    avatar: 'https://i.pravatar.cc/30?img=50',
    styles: [
      {
        value: 'Default',
        label: 'Normal'
      }
    ]
  },
  {
    label: 'Anna',
    value: 'elza',
    language: 'PT',
    avatar: 'https://i.pravatar.cc/30?img=24',
    styles: [
      {
        value: 'Default',
        label: 'Normal'
      }
    ]
  },
  {
    label: 'Joao',
    value: 'fabio',
    language: 'PT',
    avatar: 'https://i.pravatar.cc/30?img=23',
    styles: [
      {
        value: 'Default',
        label: 'Normal'
      }
    ]
  }
];

export const usePreviewStore = (set, get) => ({
  // state
  playerStore: null,
  showPreview: false,
  activePreview: 0,
  videoPreview: {},
  imagePreview: null,
  videoPreviewProportion: 0.3,

  // actions
  setPlayerStore: (playerStore) => {
    set({ playerStore });
  },

  setShowPreviewAction: (idx = 0) => {
    let newProportion = Math.min(window.innerWidth / 1080, window.innerHeight / 1920);

    if (window.innerWidth < 962) {
      newProportion = newProportion - 0.0355;
    }
    if (window.innerWidth < 475) {
      newProportion = 0.3;
    }
    if (window.innerWidth < 390) {
      newProportion = 0.25;
    }

    set({ videoPreviewProportion: newProportion });

    set({ showPreview: true });
    get().setPreviewAction(idx);

    setTimeout(() => {
      let body = document.querySelector('body');
      if (body) {
        body.style.overflowY = 'hidden';
      }
    });

    get().trackingAction({
      event: 'onButtonClick',
      category: 'idea-to-video',
      action: 'show-preview'
    });
  },

  hidePreviewAction: () => {
    let body = document.querySelector('body');
    if (body) {
      body.style.overflowY = 'scroll';
    }

    const playerStore = get().playerStore;

    playerStore?.current?.setState({ creationId: '' });
    playerStore?.current?.getState().destroyMovie();
    playerStore?.current?.destroy();

    set({ showPreview: false, playerStore: null });
  },

  nextPreviewAction: () => {
    const idx = get().activePreview + 1;
    get().setPreviewAction(idx);
  },

  backPreviewAction: () => {
    const idx = get().activePreview - 1;
    get().setPreviewAction(idx);
  },

  setPreviewAction: (idx) => {
    const sections = get().activePage === 'create' ? get().sections : get().historicalSections;
    const videosBySections =
      get().activePage === 'create' ? get().videosBySections : get().videosByHistoricalSections;
    let _video = { ...videosBySections[idx]?.video };
    _video.proportion = get().videoPreviewProportion;
    _video.prompt = sections[videosBySections[idx].sectionIdx].prompt;

    const layer = { ..._video.clips[0]?.layers[0] };
    let media = layer.path;
    if (media && media.type !== 'woxo-image') {
      media = media.replaceAll('.mp4', '.jpg');
    }

    set({
      activePreview: idx,
      videoPreview: _video,
      imagePreview: media
    });
  },

  addPreviewToDownloadAction: () => {
    const videosBySections =
      get().activePage === 'create' ? get().videosBySections : get().videosByHistoricalSections;
    const _videos = [...videosBySections];
    _videos.map((v) => {
      if (v.video.id === get().videoPreview.id) {
        get().addVideoToDownloadAction(v.sectionIdx, v.videoIdx);
        return;
      }
    });
  },

  goFromPreviewToEditAction: () => {
    get().hidePreviewAction();
    get().setShowEditAction(get().activePreview);
  },

  addPreviewToScheduleAction: () => {
    const videosBySections =
      get().activePage === 'create' ? get().videosBySections : get().videosByHistoricalSections;
    const _videos = [...videosBySections];
    _videos.map((v) => {
      if (v.video.id === get().videoPreview.id) {
        get().addToScheduleAction(v.video);
        return;
      }
    });
  },

  reusePromptAction: (prompt) => {
    get().setActivePageAction('create');
    get().hidePreviewAction();
    function checkActivePage() {
      let input = document.querySelector('#input-create');
      if (get().activePage === 'create' && input) {
        set({ prompt: prompt });
      } else window.requestAnimationFrame(checkActivePage);
    }
    window.requestAnimationFrame(checkActivePage);
  }
});

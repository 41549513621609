export const data: any = [
  {
    value: 'EN',
    label: 'English'
  },
  {
    value: 'ES',
    label: 'Spanish'
  },
  {
    value: 'FR',
    label: 'French'
  },
  {
    value: 'HI',
    label: 'Hindi'
  },
  {
    value: 'DE',
    label: 'German'
  },
  {
    value: 'PT',
    label: 'Portuguese'
  }
];

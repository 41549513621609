export const getPrompt = (topic, language) => {
  return {
    label: 'Jokes',
    topic: `Jokes about "${topic}"`,
    description: `"Jokes" short-videos are a great way to entertain and make people laugh. They can take the form of stand-up comedy, comedic skits, or even animated cartoons. They can be used to provide a light-hearted and humorous break from the daily grind, and can be enjoyed by audiences of all ages. They can be used to showcase a wide range of comedic styles, from slapstick to observational humor, and can be tailored to different audiences and settings. Whether you're a comedian looking to showcase your talent or a brand looking to create engaging and funny content, "Jokes" short-videos are a great choice. Just keep in mind that humor can be subjective, and it's important to consider the target audience and the platform where the video will be shared.`,
    examples: [
      'https://res.cloudinary.com/dakp804eh/video/upload/v1676946693/Woxo/Idea2video/category_details/jokes/jokes_1.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1676946693/Woxo/Idea2video/category_details/jokes/jokes_2.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1676946693/Woxo/Idea2video/category_details/jokes/jokes_3.mp4'
    ],
    inspiration: [
      {
        url: 'https://www.youtube.com/@ComedyCentral',
        channelLogo:
          'https://res.cloudinary.com/dakp804eh/image/upload/v1676945522/Woxo/Idea2video/category_details/jokes/jzUQkiqje00yMdCaTwVauqclC67mNjSSA30Y9ILLsh7zIGOZYaCigN5L-sQCAp0vYuLlfVTiEws_s176-c-k-c0x00ffffff-no-rj.jpg',
        chanelName: 'Comedy Central',
        channel: '@ComedyCentral',
        subscribers: '11.3M',
        description: `A channel that features a wide variety of comedic content, including stand-up comedy, sketches, and original series.`
      },
      {
        url: 'https://www.youtube.com/@FunnyOrDie',
        channelLogo:
          'https://res.cloudinary.com/dakp804eh/image/upload/v1676945547/Woxo/Idea2video/category_details/jokes/AL5GRJXYPMpfzNEbkvF_TWDSZuItkubTs6OvTDQKdgftzBU_s176-c-k-c0x00ffffff-no-rj.jpg',
        chanelName: 'Funny Or Die',
        channel: '@FunnyOrDie',
        subscribers: '3.58M',
        description: `A channel that features original comedic content, including sketches, parodies, and short films.`
      },
      {
        url: 'https://www.youtube.com/@BadLipReading',
        channelLogo:
          'https://res.cloudinary.com/dakp804eh/image/upload/v1676945587/Woxo/Idea2video/category_details/jokes/AL5GRJWWyhM0qsTpRKws-yPDxZD3yNaMzKG_ZLiWYZGL_s176-c-k-c0x00ffffff-no-rj.jpg',
        chanelName: 'Bad Lip Reading',
        channel: '@BadLipReading',
        subscribers: '8.07M',
        description: `A channel known for its comedic dubbing of popular videos and music with new, humorous dialogue.`
      }
    ],
    tier: 'free',
    promptContent: `
User prompt: ${topic}
Create one or more short video scripts of 5 scenes, each narrating a joke related to the main subject. Follow these guidelines:
Scene 1: Begin with a hook that sets up the joke related to the main subject.
Scene 2: Present the setup of the joke (up to 20 words).
Scene 3: Continue the joke from Scene 2 (up to 20 words).
Scene 4: Deliver the punchline of the joke (up to 20 words).
Scene 5: Conclude with a call to action, such as "Follow us for more jokes about the main subject!"
Ensure each voiceover is under 20 words (Language: ${language}).`
  };
};

const socketTiemout = 10000; // in ms
export function connect(aws_region, aws_iot_endpoint, aws_access_id, aws_secret_key) {
  return new Promise(async (resolve, reject) => {
    const { mqtt, iot } = await import('aws-iot-device-sdk-v2');
    let config = iot.AwsIotMqttConnectionConfigBuilder.new_builder_for_websocket()
      .with_clean_session(true)
      .with_client_id(`pub_sub_sample(${new Date()})`)
      .with_credentials(aws_region, aws_access_id, aws_secret_key)
      .with_endpoint(aws_iot_endpoint)
      .with_use_websockets()
      .with_keep_alive_seconds(30)
      .build();

    console.log('Connecting websocket...');
    const client = new mqtt.MqttClient();

    const connection = client.new_connection(config);
    //Reject if pass much time in connect the socket
    const timeoutControl = setTimeout(() => {
      reject(`The socket connection has delayed more than ${socketTiemout}ms`);
      connection.disconnect();
    }, socketTiemout);

    connection.on('connect', (session_present) => {
      console.log('Websocket connected ...');
      clearTimeout(timeoutControl);
      resolve(connection);
    });
    connection.on('interrupt', (error) => {
      console.log(`Connection interrupted: error=${error}`);
    });
    connection.on('resume', (return_code, session_present) => {
      console.log(`Resumed: rc: ${return_code} existing session: ${session_present}`);
    });
    connection.on('disconnect', () => {
      console.log('Disconnected');
    });
    connection.on('error', (error) => {
      reject(error);
    });
    connection.connect();
  });
}

import { getAuthHeader } from '~/services/security.service';

export async function getCredits(userId, plan) {
  let planParameter = false;
  if (plan) {
    planParameter = plan.id;
  }

  let API_HOST = 'https://woxo.tech';
  const headers = await getAuthHeader();
  if (typeof window !== 'undefined') {
    API_HOST = window.location.origin;
  }
  try {
    const response = await fetch(`${API_HOST}/api/credits?userId=${userId}`, {
      headers: {
        'Content-Type': 'application/json',
        ...headers
      }
    });
    if (!response.ok) {
      throw new Error('HTTP error ' + response.status);
    }
    const creditsData = await response.json();
    return creditsData.creditsRemaining;
  } catch (error) {
    console.error('Error:', error);
    return null;
  }
}

export async function updateCredits(data) {
  const { userId, usage, plan } = data;
  let planParameter = 'false';
  if (plan) {
    planParameter = plan.id;
  }

  let API_HOST = 'https://woxo.tech';
  const headers = await getAuthHeader();
  if (typeof window !== 'undefined') {
    API_HOST = window.location.origin;
  }
  try {
    const response = await fetch(`${API_HOST}/api/credits`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...headers
      },
      body: JSON.stringify({ userId, usage })
    });
    if (!response.ok) {
      throw new Error('HTTP error ' + response.status);
    }
    const creditsData = await response.json();
    return creditsData.creditsRemaining;
  } catch (error) {
    console.error('Error:', error);
    return null;
  }
}

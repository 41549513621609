import { v4 } from 'uuid';
import { data as songsList } from '../data/dataForMusic';
import { getVideosBySections } from '../helpers/helper';
import cloneDeep from 'lodash/cloneDeep';

export const useMusicStore = (set, get) => ({
  // state
  music: { label: 'Christmas', value: 'christmas' },

  // actions
  setMusicAction: (music) => {
    let _sections = [...get().sections];

    if (music.value === 'none') {
      _sections.map((section, sectionIdx) => {
        if (section.errors?.length === 0) {
          section.videos.map((video) => {
            video.id = v4();

            video.audioTracks.forEach((item, index, object) => {
              if (item.path.indexOf('res.cloudinary.com') !== -1) {
                object.splice(index, 1);
              }
            });
          });

          _sections[sectionIdx].id = v4();
          _sections[sectionIdx].isLoading = false;
          _sections[sectionIdx].music = [];
          set(
            { sections: [..._sections], videosBySections: getVideosBySections(_sections) },
            false,
            'Update section - music'
          );

          // !- Update videos in history
          if (_sections[sectionIdx].videos[0]?.isCreated) {
            get().updateVideosHistoryAction(get().user._id, _sections[sectionIdx]);
          }
        }
      });
    } else {
      _sections.map((section, sectionIdx) => {
        if (section.errors?.length === 0) {
          const _music = cloneDeep(songsList[music.value]).sort(() => 0.5 - Math.random());
          _music.length = section.videos.length;

          section.videos.map((video, i) => {
            video.id = v4();

            const audio = { path: _music[i] };

            const containsVoiceover = video.audioTracks.some((v) => {
              if (v.path.indexOf('vod.front10.cloud') !== -1) {
                return true;
              }
              return false;
            });
            if (containsVoiceover) audio.mixVolume = 0.2;

            video.audioTracks.forEach((item, index, object) => {
              if (item.path.indexOf('res.cloudinary.com') !== -1) {
                object.splice(index, 1);
              }
            });
            video.audioTracks.push(audio);
          });

          _sections[sectionIdx].id = v4();
          _sections[sectionIdx].isLoading = false;
          _sections[sectionIdx].music = _music;
          set(
            { sections: [..._sections], videosBySections: getVideosBySections(_sections) },
            false,
            'Update section - music'
          );

          // !- Update videos in history
          if (_sections[sectionIdx].videos[0]?.isCreated) {
            get().updateVideosHistoryAction(get().user._id, _sections[sectionIdx]);
          }
        }
      });
    }

    set({ music, isOpenCustomize: false }, false, 'Set music');
  }
});

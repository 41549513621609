import querystring from 'querystring';
import axios from './axios';
import logger from 'log-level';

const cldEndpoint = 'https://api.cloudinary.com/v1_1/dakp804eh/auto/upload';
// const cldEndpoint = '/cloudinary/upload';

const upload_preset = 'first_preset';

export const uploadMedias = async (medias, options) => {
  const tmp = [];
  for (let index = 0; index < newMedias.length; index++) {
    const local = newMedias[index];
    const remote = await upload({ file: local, options });
    tmp.push({ local, remote });
  }
  return tmp;
};
export const upload = ({ file, options, onUploadProgress }) =>
  new Promise((resolve, reject) => {
    if (!file) {
      logger.error('Url must not be empty');
      reject();
    }
    var formData = new FormData();
    formData.append('file', file);
    formData.append('upload_preset', upload_preset);
    for (const key in options) {
      if (Object.hasOwnProperty.call(options, key)) {
        formData.append(key, options[key]);
      }
    }
    axios({
      url: cldEndpoint,
      method: 'POST',
      data: formData,
      //   data: { ...formData, upload_preset, ...options }

      onUploadProgress
    })
      .then((response) => {
        if (response.data) resolve(response.data);
        else {
          logger.error('Elements must be an array');
          reject();
        }
      })
      .catch(() => {
        logger.error('Ups fail to load elements');
        reject();
      });
  });

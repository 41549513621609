import { v4 } from 'uuid';
import { prompts as categories } from '../data/prompts/index';
import { videoTemplate } from '../data/templates/basic';
import { getVideosBySections } from '../helpers/helper';

export const useCategoriesStore = (set, get) => ({
  // state
  categories: [...categories],
  selectedCategories: ['Free Style'],

  // actions
  applyCategoriesAction: (categories) => {
    if (!get().user) {
      get().redirectSignUpAction();
    } else {
      set({ selectedCategories: categories });

      let _sections = [];
      let _createSection = false;

      let _allSections = [...get().sections];

      categories.forEach((p) => {
        let _isNew = true;
        _allSections.map((s) => {
          if (p === s.category) {
            _sections.push(s);
            _isNew = false;
          }
        });
        if (_isNew) {
          _createSection = true;
          get().categories.forEach((c) => {
            if (p === c().label) {
              let _videos = [{ ...videoTemplate() }];

              _sections.push({
                id: v4(),
                text: c(get().prompt).topic,
                videos: _videos,
                category: c().label,
                isLoading: true,
                errors: [],
                medias: [],
                voiceover: [],
                creation: { time: '00:00', step: 0 },
                prompts: c(get().prompt, get().language.label).promptContent
              });
            }
          });
        }
      });

      set(
        { sections: [..._sections], videosBySections: getVideosBySections(_sections) },
        false,
        'Update sections'
      );

      if (_createSection) {
        get().createSectionsAction();
      }
    }
  },

  upgradeCategoriesAction: (fn) => {
    get().upgradePlanAction('premium-category', fn);
  }
});

export const data: any = {
  christmas: [
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Christmas/Jingle_Bells_-_Scott_Holmes_Music.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Christmas/A_Peaceful_Winter_-_Scott_Holmes_Music.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Christmas/This_is_Christmas_-_Scott_Holmes_Music.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Christmas/jingle-bells-rock-energetic-positive-upbeat-happy-christmas-party-125676.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Christmas/this-christmas-126736.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Christmas/christmas-music-magical-fairy-tale-for-children-kids-125101.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Christmas/we-wish-you-a-merry-christmas-126685.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Christmas/jingle-bells-christmas-hip-hop-128137.mp3'
  ],
  funny: [
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Funny/Circus_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Funny/toucans_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Funny/Snowman_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Funny/Smile_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Funny/IN_LOVE_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Funny/Silly_Clowns_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Funny/Fun_ideas_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Funny/Better_-_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Funny/Feels_Good_-_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Funny/Shake_it_-_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Funny/Fine_-_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Funny/Misfits_-_MOODHALO.mp3'
  ],
  epic: [
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Epic/80s_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Epic/Adrenaline_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Epic/The_Rings_-_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Epic/Hope_-_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Epic/Village_-_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Epic/The_Beginnig_-_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Epic/The_journey_-_MOODHALO.mp3'
  ],
  bright: [
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Bright/with_me_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Bright/Orbits_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Bright/Adrenaline_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Bright/Now_is_the_time_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Bright/Follow_the_Light_-_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Happy/Train_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Happy/Elephants_on_Parade_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Happy/Shake_it_-_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Happy/Smooth_Sail_-_MOODHALO.mp3'
  ],
  happy: [
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Happy/Train_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Happy/Palm_trees_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Happy/Circus_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Happy/Elephants_on_Parade_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Happy/toucans_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Happy/Smile_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Happy/Fun_ideas_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Happy/Let_s_stay_together_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Happy/WHEN_I_M_WITH_YOU_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Happy/IN_LOVE_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Happy/UNCLE_BROWN_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Happy/Silly_Clowns_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Happy/Smooth_Sail_-_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Happy/Joy_-_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Happy/Sunshine_-_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Happy/Happy_Monkeys_-_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Happy/Fun_-_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Happy/Summer_breeze_-_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Happy/I_am_Ready_-_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Happy/New_Day_-_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Happy/Shake_it_-_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Happy/Feels_Good_-_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Happy/Dancing_Night_-_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Happy/Misfits_-_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Happy/Sundays_-_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Happy/Sunshine_in_My_Heart_-_Jingle_Punks.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Happy/Morning_Walk_-_Jingle_Punks.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Happy/For_Mimi_-_Twin_Musicom.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Happy/Beach_Party_-_Kevin_MacLeod.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Happy/B-Roll_ska_-_Islandesque_-_Kevin_MacLeod.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1680500436/woxo-video/audios-v4/Happy/Acoustic__BPM130_1.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1680500436/woxo-video/audios-v4/Happy/Rock__BPM130.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1680500435/woxo-video/audios-v4/Happy/Drum__n__Bass__BPM175.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1680500434/woxo-video/audios-v4/Happy/Corporate__BPM144.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1680500433/woxo-video/audios-v4/Happy/World__BPM130.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1680500433/woxo-video/audios-v4/Happy/Future_House__BPM134_1.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1680500432/woxo-video/audios-v4/Happy/Cinematic__BPM130.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1680500432/woxo-video/audios-v4/Happy/Corporate__BPM136.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1680500431/woxo-video/audios-v4/Happy/Acoustic__BPM130.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1680500428/woxo-video/audios-v4/Happy/Funk__BPM128.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1680500426/woxo-video/audios-v4/Happy/Electro_Pop__BPM140.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1680500426/woxo-video/audios-v4/Happy/Future_House__BPM130.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1680500426/woxo-video/audios-v4/Happy/Pop__BPM136.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1680500426/woxo-video/audios-v4/Happy/Tokyo_Night_Pop__BPM135.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1680500425/woxo-video/audios-v4/Happy/Rock__BPM151_2.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1680500420/woxo-video/audios-v4/Happy/Tropical_House__BPM130.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1680500420/woxo-video/audios-v4/Happy/Future_House__BPM136.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1680500420/woxo-video/audios-v4/Happy/Corporate__BPM129.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1680500420/woxo-video/audios-v4/Happy/Rock__BPM151_1.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1680500420/woxo-video/audios-v4/Happy/Future_Bass__BPM150.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1680500420/woxo-video/audios-v4/Happy/Future_House__BPM132.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1680500414/woxo-video/audios-v4/Happy/Tokyo_Night_Pop__BPM130.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1680500414/woxo-video/audios-v4/Happy/Electro_Pop__BPM128.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1680500414/woxo-video/audios-v4/Happy/Chill_Beats__BPM140.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1680500414/woxo-video/audios-v4/Happy/Techno__BPM140.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1680500414/woxo-video/audios-v4/Happy/Tokyo_Night_Pop__BPM132.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1680500414/woxo-video/audios-v4/Happy/Future_House__BPM134.mp3'
  ],
  inspirational: [
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Inspirational/now_is_the_time.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Inspirational/Always_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Inspirational/WINDMILLS_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Inspirational/Night_Sounds_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Inspirational/Rock_it_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Inspirational/FINALLY_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Inspirational/unbreakable_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Inspirational/Beyond_time_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Inspirational/WHEN_I_M_WITH_YOU_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Inspirational/Hot_Air_Balloons_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Inspirational/Now_is_the_time_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Inspirational/Same_old_blues_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Inspirational/The_Ride_-_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Inspirational/Morning_Song_-_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Inspirational/Hope_-_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Inspirational/Shine_-_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Inspirational/In_the_Sky_-_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Inspirational/Alive_-_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Inspirational/Bottomless_Cup_-_Silent_Partner.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Inspirational/Barton_Springs_-_Bird_Creek.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Inspirational/Bad_Love_Niwel_Remix_-_Niwel.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Inspirational/Aurora_Borealis_-_Bird_Creek.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Inspirational/Around_The_Sea_-_Scandinavianz.mp3'
  ],
  romantic: [
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Romantic/lovers_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Romantic/Dreaming_of_you_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Romantic/Things_I_have_Lost_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Romantic/Hot_Air_Balloons_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Romantic/Same_old_blues_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Romantic/IN_LOVE_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Romantic/Remember_-_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Romantic/The_One_I_Love_-_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Romantic/Together_-_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Romantic/Missing_You_-_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Romantic/Summer_breeze_-_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Romantic/Hounds_-_Silent_Partner.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Romantic/Highway_Wildflowers_-_Bird_Creek.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Romantic/Firework_-_Vendredi.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Romantic/Darling_Ranch_-_Jingle_Punks.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Romantic/As_We_Go_-_The_126ers.mp3'
  ],
  angry: [
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Angry/Rock_it.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Angry/Grandpa_s_Farm.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Angry/the_calling.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Angry/High_Voltage_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Angry/Into_the_woods_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Angry/skaters_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Angry/Don_t_say_a_word_-_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Angry/Battle_set_-_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Angry/Rage_-_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Angry/Crossroads_-_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Angry/Cut_It_-_Silent_Partner.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Angry/Burnt_-_Jingle_Punks.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Angry/Battleground_-_Ethan_Meixsell.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Angry/Back_and_Forth_-_Silent_Partner.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Angry/A_Young_Man_-_ALBIS.mp3'
  ],
  calm: [
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Calm/Palm_trees_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Calm/Distant_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Calm/Down_the_river_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Calm/Hot_Air_Balloons_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Calm/AM_-_Ikson.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Calm/Afternoon_Nap_-_Ghostrifter_Official.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Calm/after_the_rain_-_Rexlambo.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Calm/Acoustic_Folk_Instrumental_-_Hyde_-_Free_Instrumentals.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Calm/A_Little_High_-_GoSoundtrack.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1683813553/woxo-audio-youtube/Motivational/Shameless_Life_Wisdom_Sendoff_-_pATCHES.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1683813492/woxo-audio-youtube/Motivational/Slowly_Until_We_Get_There_-_Joey_Pecoraro.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1683813470/woxo-audio-youtube/Motivational/Hello_6am_-_Mylar_Melodies.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1683813469/woxo-audio-youtube/Motivational/Hovering_Thoughts_-_Spence.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1683739534/woxo-audio-youtube/Motivational/Instant_Crush_-_Corbyn_Kites.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1683739515/woxo-audio-youtube/Motivational/Icelandic_Arpeggios_-_DivKid.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1683739513/woxo-audio-youtube/Motivational/No.10__A_New_Beginning_-_Esther_Abrami.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1683739486/woxo-audio-youtube/Motivational/The_Ten_Names_-_I_Think_I_Can_Help_You.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1683739485/woxo-audio-youtube/Motivational/Subharmonic_Bliss_-_Loopop.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1683739484/woxo-audio-youtube/Motivational/Koto_San_-_Ofshane.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1683739484/woxo-audio-youtube/Motivational/Auckland_-_VYEN.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1683739429/woxo-audio-youtube/Motivational/Borderless_-_Aakash_Gandhi.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1683739429/woxo-audio-youtube/Motivational/Borderless_-_Aakash_Gandhi.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1683739429/woxo-audio-youtube/Motivational/Future_Glider_-_Brian_Bolger.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1683739428/woxo-audio-youtube/Motivational/Saving_the_World_-_Aaron_Kenny.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1683739081/woxo-audio-youtube/Motivational/Grandpa_-_Puddle_of_Infinity.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1683739020/woxo-audio-youtube/Motivational/Wind_Riders_-_Asher_Fulero.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1683739018/woxo-audio-youtube/Motivational/Float_-_Emily_A._Sprague.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1683739017/woxo-audio-youtube/Motivational/A_Face_in_a_Cloud_-_Nathan_Moore.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1683739015/woxo-audio-youtube/Motivational/Wander_-_Emmit_Fenn.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1683739015/woxo-audio-youtube/Motivational/Had_She_Stayed_-_Puddle_of_Infinity.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1683738991/woxo-audio-youtube/Motivational/Liquid_Time_-_Aakash_Gandhi.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1683738990/woxo-audio-youtube/Motivational/Rolling_Hills_-_Sir_Cubworth.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1683738987/woxo-audio-youtube/Motivational/Procession_-_Geographer.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1683738987/woxo-audio-youtube/Motivational/We_Will_Be_-_Puddle_of_Infinity.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1683738987/woxo-audio-youtube/Motivational/When_We_Found_The_Horizon_-_Late_Night_Feeler.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1683738986/woxo-audio-youtube/Motivational/Alone_-_Emmit_Fenn.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1683738985/woxo-audio-youtube/Motivational/Sunburned_Salvation_-_The_129ers.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1683738985/woxo-audio-youtube/Motivational/Song_of_Mirrors_-_Unicorn_Heads_1.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1683738983/woxo-audio-youtube/Motivational/Millicent_-_Max_Surla_Media_Right_Productions.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1683738981/woxo-audio-youtube/Motivational/First_Time_Experience_-_Nate_Blaze.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1683738981/woxo-audio-youtube/Motivational/Depth_Fuse_-_French_Fuse.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1671215607/woxo-audio-youtube/Motivational/Ammil_-_The_Tides.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1671215598/woxo-audio-youtube/Motivational/Cold_Blue_-_Astron.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1671215581/woxo-audio-youtube/Motivational/Dream_Escape_-_The_Tides.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1671215571/woxo-audio-youtube/Motivational/Frolic_-_E_s_Jammy_Jams.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1671215560/woxo-audio-youtube/Motivational/Medieval_Astrology_-_Underbelly_Ty_Mayer.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1671206794/woxo-audio-youtube/Motivational/Vishnu_-_Patrick_Patrikios.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1671206789/woxo-audio-youtube/Motivational/Walking_in_the_Sky_-_Nico_Staf.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1671206761/woxo-audio-youtube/Motivational/Believe_Instrumental_-_NEFFEX.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1671206734/woxo-audio-youtube/Motivational/Born_a_Rockstar_Instrumental_-_NEFFEX.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1671206711/woxo-audio-youtube/Motivational/Ceremonial_Library_-_Asher_Fulero.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1671206662/woxo-audio-youtube/Motivational/Everything_Has_a_Beginning_-_Joel_Cummins.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1671206640/woxo-audio-youtube/Motivational/Glass_-_Anno_Domini_Beats.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1671132398/woxo-audio-youtube/Motivational/World_s_Sunrise_-_Jimena_Contreras.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1671132393/woxo-audio-youtube/Motivational/Goddess_of_the_Sea_-_Jimena_Contreras.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1671132392/woxo-audio-youtube/Motivational/Hopeless_-_Jimena_Contreras.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1671132388/woxo-audio-youtube/Motivational/Devil_s_Organ_-_Jimena_Contreras.mp3'
  ],
  dark: [
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Dark/the_stranger_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Dark/skaters_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Dark/GHOST_SHIP_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Dark/Into_the_woods_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Dark/One_mission_-_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Dark/Dark_Night_-_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Dark/Backwards_-_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Dark/Black_n_Black_-_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Dark/Bonjour_-_Peyruis.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Dark/Beginning_-_Audionautix.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Dark/Battle_Boss_-_BoxCat_Games.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Dark/Atlantean_Twilight_-_Kevin_MacLeod.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Dark/A_Magical_Journey_Through_Space_-_Leonell_Cassio.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1680270727/woxo-video/audios-v4/Scary/Cinematic_Electro_BPM77.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1680270726/woxo-video/audios-v4/Scary/Cinematic_Electro_BPM67.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1680270726/woxo-video/audios-v4/Scary/Cinematic_Electro_BPM130.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1680270726/woxo-video/audios-v4/Scary/Cinematic_Electro_BPM110.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1680270726/woxo-video/audios-v4/Scary/Cinematic_Rock__BPM90.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1680270724/woxo-video/audios-v4/Scary/Cinematic_Rock__BPM110.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1680270722/woxo-video/audios-v4/Scary/Cinematic_Rock_BPM86.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1680270720/woxo-video/audios-v4/Scary/Cinematic_Rock_BPM107.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1680270720/woxo-video/audios-v4/Scary/Suspense_BPM93.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1680270720/woxo-video/audios-v4/Scary/Suspense_BPM85.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1680270718/woxo-video/audios-v4/Scary/Cinematic_Rock_BPM110.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1680270717/woxo-video/audios-v4/Scary/Suspense_BPM95.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1680270714/woxo-video/audios-v4/Scary/Suspense_BPM116_1.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1680270714/woxo-video/audios-v4/Scary/Techno_BPM137.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1680270713/woxo-video/audios-v4/Scary/Suspense_BPM113.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1680270712/woxo-video/audios-v4/Scary/Suspense_BPM136_1.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1680270711/woxo-video/audios-v4/Scary/Suspense_BPM116.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1680270709/woxo-video/audios-v4/Scary/Trap_BPM70_1.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1680270706/woxo-video/audios-v4/Scary/Trap_BPM70.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1680270706/woxo-video/audios-v4/Scary/Trap_BPM75_2.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1680270706/woxo-video/audios-v4/Scary/Trap_BPM70_2.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1680270706/woxo-video/audios-v4/Scary/Trap_BPM80_1.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1680270706/woxo-video/audios-v4/Scary/Trap_BPM75.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1680270703/woxo-video/audios-v4/Scary/Trap_BPM80.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1680270700/woxo-video/audios-v4/Scary/Typebeat__BPM160_1.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1680270700/woxo-video/audios-v4/Scary/Typebeat_BPM160.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1680270700/woxo-video/audios-v4/Scary/Type_Beat_BPM80_2.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1680270699/woxo-video/audios-v4/Scary/Type_Beat_BPM80_1.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1680270699/woxo-video/audios-v4/Scary/Type_Beat_BPM80.mp3'
  ],
  dramatic: [
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Dramatic/the_stranger_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Dramatic/now_is_the_time_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Dramatic/TEARS_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Dramatic/KEEP_GOING_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Dramatic/Mystery_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Dramatic/Things_I_have_Lost_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Dramatic/LEAD_THE_WAY_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Dramatic/Speedy_-_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Dramatic/Moving_Fowards_-_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Dramatic/Disco_Ball_-_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Dramatic/Cataclysmic_Molten_Core_-_Jingle_Punks.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Dramatic/Call_to_Adventure_-_Kevin_MacLeod.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Dramatic/Black_and_White_-_Max_Surla_Media_Right_Productions.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Dramatic/Action_Hero_-_Jingle_Punks.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Dramatic/Achilles_-_Kevin_MacLeod.mp3'
  ],
  sad: [
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Sad/TEARS_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Sad/Things_I_have_Lost_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Sad/GHOST_SHIP_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Sad/Down_the_river_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Sad/Old_pictures_-_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Sad/Nostalgia_-_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Sad/Goodbye_-_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Sad/Remember_-_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Sad/NOCTURNE_-_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Sad/After_the_storm_-_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Sad/Empty_Room_-_MOODHALO.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Sad/Desert_Sky_-_Silent_Partner.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Sad/Days_Are_Long_-_Silent_Partner.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Sad/Called_Upon_-_Silent_Partner.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Sad/Almost_A_Year_Ago_-_John_Deley_and_the_41_Players.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Sad/500_AM_-_Peter_Rudenko.mp3'
  ],
  hiphop: [
    'https://res.cloudinary.com/dakp804eh/video/upload/v1680633122/woxo-video/audios-v4/Hip%20Hop/Chill_Beats__BPM102.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1680633120/woxo-video/audios-v4/Hip%20Hop/Typebeat__BPM175.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1680633119/woxo-video/audios-v4/Hip%20Hop/Type_Beat__BPM71.mp3',
    'https://res.cloudinary.com/dakp804eh/video/upload/v1680633117/woxo-video/audios-v4/Hip%20Hop/Trap__BPM65.mp3'
  ]
};

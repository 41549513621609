export const backgroundStyles = [
  {
    value: 'ai-art',
    label: 'AI Art',
    types: [
      // --- STABILITY
      {
        value: 'ai-art_enhance',
        label: 'Enhance',
        image:
          'https://res.cloudinary.com/dakp804eh/image/upload/v1683310720/Woxo/Idea2video/background_styles/ai-art/new/enhance.png',
        prompts: [],
        style_preset: 'enhance',
        tier: 'free'
      },
      {
        value: 'ai-art_anime',
        label: 'Anime',
        image:
          'https://res.cloudinary.com/dakp804eh/image/upload/v1683313064/Woxo/Idea2video/background_styles/ai-art/new/anime.png',
        prompts: [],
        style_preset: 'anime',
        tier: 'premium'
      },
      {
        value: 'ai-art_photographic',
        label: 'Photographic',
        image:
          'https://res.cloudinary.com/dakp804eh/image/upload/v1683313165/Woxo/Idea2video/background_styles/ai-art/new/photographic.png',
        prompts: [],
        style_preset: 'photographic',
        tier: 'premium'
      },
      {
        value: 'ai-art_digital-art',
        label: 'Digital Art',
        image:
          'https://res.cloudinary.com/dakp804eh/image/upload/v1683313245/Woxo/Idea2video/background_styles/ai-art/new/digital-art.png',
        prompts: [],
        style_preset: 'digital-art',
        tier: 'free'
      },
      {
        value: 'ai-art_comic-book',
        label: 'Comic Book',
        image:
          'https://res.cloudinary.com/dakp804eh/image/upload/v1683313311/Woxo/Idea2video/background_styles/ai-art/new/comic-book.png',
        prompts: [],
        style_preset: 'comic-book',
        tier: 'premium'
      },
      {
        value: 'ai-art_fantasy-art',
        label: 'Fantasy Art',
        image:
          'https://res.cloudinary.com/dakp804eh/image/upload/v1683313396/Woxo/Idea2video/background_styles/ai-art/new/fantasy-art.png',
        prompts: [],
        style_preset: 'fantasy-art',
        tier: 'premium'
      },
      {
        value: 'ai-art_analog-film',
        label: 'Analog Film',
        image:
          'https://res.cloudinary.com/dakp804eh/image/upload/v1683313497/Woxo/Idea2video/background_styles/ai-art/new/analog-film.png',
        prompts: [],
        style_preset: 'analog-film',
        tier: 'free'
      },
      {
        value: 'ai-art_neon-punk',
        label: 'Neon Punk',
        image:
          'https://res.cloudinary.com/dakp804eh/image/upload/v1683313573/Woxo/Idea2video/background_styles/ai-art/new/neon-punk.png',
        prompts: [],
        style_preset: 'neon-punk',
        tier: 'free'
      },
      {
        value: 'ai-art_isometric',
        label: 'Isometric',
        image:
          'https://res.cloudinary.com/dakp804eh/image/upload/v1683313664/Woxo/Idea2video/background_styles/ai-art/new/isometric.png',
        prompts: [],
        style_preset: 'isometric',
        tier: 'free'
      },
      {
        value: 'ai-art_low-poly',
        label: 'Low Poly',
        image:
          'https://res.cloudinary.com/dakp804eh/image/upload/v1683313802/Woxo/Idea2video/background_styles/ai-art/new/low-poly.png',
        prompts: [],
        style_preset: 'low-poly',
        tier: 'premium'
      },
      {
        value: 'ai-art_low-origami',
        label: 'Origami',
        image:
          'https://res.cloudinary.com/dakp804eh/image/upload/v1683313871/Woxo/Idea2video/background_styles/ai-art/new/origami.png',
        prompts: [],
        style_preset: 'origami',
        tier: 'premium'
      },
      {
        value: 'ai-art_low-line-art',
        label: 'Line Art',
        image:
          'https://res.cloudinary.com/dakp804eh/image/upload/v1683313960/Woxo/Idea2video/background_styles/ai-art/new/line-art.png',
        prompts: [],
        style_preset: 'line-art',
        tier: 'premium'
      },
      {
        value: 'ai-art_low-modeling-compound',
        label: 'Craft Clay',
        image:
          'https://res.cloudinary.com/dakp804eh/image/upload/v1683314022/Woxo/Idea2video/background_styles/ai-art/new/modeling-compound.png',
        prompts: [],
        style_preset: 'modeling-compound',
        tier: 'premium'
      },
      {
        value: 'ai-art_low-cinematic',
        label: 'Cinematic',
        image:
          'https://res.cloudinary.com/dakp804eh/image/upload/v1683314142/Woxo/Idea2video/background_styles/ai-art/new/cinematic.png',
        prompts: [],
        style_preset: 'cinematic',
        tier: 'premium'
      },
      {
        value: 'ai-art_low-3d-model',
        label: '3D Model',
        image:
          'https://res.cloudinary.com/dakp804eh/image/upload/v1683314207/Woxo/Idea2video/background_styles/ai-art/new/3d-model.png',
        prompts: [],
        style_preset: '3d-model',
        tier: 'premium'
      },
      {
        value: 'ai-art_low-pixel-art',
        label: 'Pixel Art',
        image:
          'https://res.cloudinary.com/dakp804eh/image/upload/v1683314270/Woxo/Idea2video/background_styles/ai-art/new/pixel-art.png',
        prompts: [],
        style_preset: 'pixel-art',
        tier: 'premium'
      },
      {
        value: 'ai-art_low-tile-texture',
        label: 'Tile Texture',
        image:
          'https://res.cloudinary.com/dakp804eh/image/upload/v1683318784/Woxo/Idea2video/background_styles/ai-art/new/tile-texture.png',
        prompts: [],
        style_preset: 'tile-texture',
        tier: 'free'
      },
      // --- STABILITY
      // --- OUR
      {
        value: 'ai-art_cyberpunk',
        label: 'Cyberpunk',
        image:
          'https://res.cloudinary.com/dakp804eh/image/upload/v1683319033/Woxo/Idea2video/background_styles/ai-art/new/cyberpunk.png',
        prompts: [
          {
            text: ', beautiful cyberpunk, hyper detail, magic neon, cyberpunk art, grunge cyberpunk, an anthropomorphic cyberpunk',
            weight: 1
          }
        ],
        style_preset: 'neon-punk',
        tier: 'free'
      },
      {
        value: 'ai-art_psychedelic',
        label: 'Psychedelic',
        image:
          'https://res.cloudinary.com/dakp804eh/image/upload/v1683319144/Woxo/Idea2video/background_styles/ai-art/new/psychedelic.png',
        prompts: [
          {
            text: ', hypnotic psychedelic art by Dan Mumford, hypnotic illustration, pop surrealism, dark glow neon paint, mystical, Behance',
            weight: 1
          }
        ],
        style_preset: 'none',
        tier: 'premium'
      },
      {
        value: 'ai-art_pop',
        label: 'Pop',
        image:
          'https://res.cloudinary.com/dakp804eh/image/upload/v1683319222/Woxo/Idea2video/background_styles/ai-art/new/pop.png',
        text: 'Pop',
        prompts: [
          {
            text: ', good vibes, pop art, colorful, textured, block colours with geometric shapes, rainbow, 4k high detailed painting in Pop art style, artistic, concept art, high resolution render',
            weight: 1
          }
        ],
        style_preset: 'none',
        tier: 'premium'
      },
      {
        value: 'ai-art_photorealism',
        label: 'Photorealism',
        image:
          'https://res.cloudinary.com/dakp804eh/image/upload/v1683319309/Woxo/Idea2video/background_styles/ai-art/new/photorealism.png',
        prompts: [
          {
            text: ', ultrarealistic uhd faces, Kodak Ultramax 800, detailed skin texture, masterpiece, sharp focus, pexels contest winner, documentary, oscar winning',
            weight: 6
          },
          {
            text: 'colourful 3d crystals and gems, vintage 1950s stamp, full color manga cover, kewpie, two girls, anime, fairytale illustration, chinese ribbon dance, children illustration, flower dress, illustration, silk shoes, classic children’s illustration, adorable and whimsical',
            weight: -2
          }
        ],
        style_preset: 'none',
        tier: 'premium'
      },
      {
        value: 'ai-art_realistic',
        label: 'Realistic',
        image:
          'https://res.cloudinary.com/dakp804eh/image/upload/v1683319397/Woxo/Idea2video/background_styles/ai-art/new/realistic.png',
        prompts: [
          {
            text: ', polycount contest winner, photorealism, uhd 8k cryengine, digital art, colorful, high res beautiful lighting, photogenic details, artistic, concept art, intricate details',
            weight: 1
          }
        ],
        style_preset: 'none',
        tier: 'free'
      },
      {
        value: 'ai-art_ink',
        label: 'Ink',
        image:
          'https://res.cloudinary.com/dakp804eh/image/upload/v1683319669/Woxo/Idea2video/background_styles/ai-art/new/ink.png',
        prompts: [
          {
            text: ', highly detailed, focused gaze, concept art, ink pen sketch, black ink on textured paper, pencil skirt, linocut print, watercolor strokes, high resolution render, pen and ink drawing styles, HQ, 4k, trending in artstation',
            weight: 1
          }
        ],
        style_preset: 'none',
        tier: 'premium'
      },
      {
        value: 'ai-art_comic',
        label: 'Comic',
        image:
          'https://res.cloudinary.com/dakp804eh/image/upload/v1683319771/Woxo/Idea2video/background_styles/ai-art/new/comic.png',
        prompts: [
          {
            text: ', retro comic style artwork, comic book style, comic book cover, marvel, illustration, high detail illustration, a beautiful artwork',
            weight: 1
          }
        ],
        style_preset: 'none',
        tier: 'premium'
      }
      // --- OUR
    ]
  },
  {
    value: 'christmas',
    label: 'Christmas',
    types: [
      {
        value: 'christmas_merry',
        label: 'Merry',
        image:
          'https://res.cloudinary.com/dakp804eh/video/upload/v1671905357/Woxo%20Videos/Experiments/Backgrounds/Chrismas/Christmas_2.jpg',
        tier: 'free'
      },
      {
        value: 'christmas_tiktok',
        label: 'Tiktok',
        image:
          'https://res.cloudinary.com/dakp804eh/video/upload/v1671316069/Woxo%20Videos/Experiments/Backgrounds/Tiktok/Chrismas%20Tree/download_8.jpg',
        tier: 'free'
      }
    ]
  },
  {
    value: 'nature',
    label: 'Nature',
    types: [
      {
        value: 'nature_landscapes',
        label: 'Landscapes',
        image:
          'https://res.cloudinary.com/dakp804eh/video/upload/v1659593967/Woxo/Instagram%20reels/medias/landscape/6.jpg',
        tier: 'free'
      },
      {
        value: 'nature_fancy',
        label: 'The Fancy 😅',
        image:
          'https://res.cloudinary.com/dakp804eh/video/upload/v1671905859/Woxo%20Videos/Experiments/Backgrounds/Nature%20and%20Markups/ls08.jpg',
        tier: 'premium'
      }
    ]
  },
  {
    value: 'colorful',
    label: 'Colorful',
    types: [
      {
        value: 'colorful',
        label: 'Colorful',
        image:
          'https://res.cloudinary.com/dakp804eh/video/upload/v1671049471/Woxo%20Videos/Experiments/Backgrounds/Fun%20Facts/generic_4.jpg',
        tier: 'premium'
      }
    ]
  },
  {
    value: 'food',
    label: 'Food',
    types: [
      {
        value: 'food',
        label: 'Food',
        image:
          'https://res.cloudinary.com/dakp804eh/video/upload/v1671905525/Woxo%20Videos/Experiments/Backgrounds/Food/food11.jpg',
        tier: 'premium'
      }
    ]
  },
  {
    value: 'real-people',
    label: 'Real People',
    types: [
      {
        value: 'real-people_dariel',
        label: 'Dariel',
        image:
          'https://res.cloudinary.com/dakp804eh/video/upload/v1648137369/woxo-videos-user-gallery/darieladmin/VID_20210119_132038_dzkphz.jpg',
        tier: 'premium'
      },
      {
        value: 'real-people_emma',
        label: 'Emma',
        image:
          'https://res.cloudinary.com/dakp804eh/video/upload/v1660564720/Woxo%20Videos/Woxo%20Ambassadors/Emm/01_two_fingers_up.jpg',
        tier: 'premium'
      },
      {
        value: 'real-people_megan',
        label: 'Megan',
        image:
          'https://res.cloudinary.com/dakp804eh/video/upload/v1660567202/Woxo%20Videos/Woxo%20Ambassadors/Megan/IMG_1296.jpg',
        tier: 'premium'
      },
      {
        value: 'real-people_willy',
        label: 'Will',
        image:
          'https://res.cloudinary.com/dakp804eh/video/upload/v1660565589/Woxo%20Videos/Woxo%20Ambassadors/Willy/VID_20220812_160304_0.jpg',
        tier: 'free'
      },
      {
        value: 'real-people_nadielly',
        label: 'Nadi',
        image:
          'https://res.cloudinary.com/dakp804eh/video/upload/v1660566516/Woxo%20Videos/Woxo%20Ambassadors/Nadielly/VID_33330927_001445_612.jpg',
        tier: 'free'
      },
      {
        value: 'real-people_williams',
        label: 'Williams',
        image:
          'https://res.cloudinary.com/dakp804eh/video/upload/v1660564859/Woxo%20Videos/Woxo%20Ambassadors/Williams/G1.jpg',
        tier: 'free'
      }
    ]
  },
  {
    value: 'old movies',
    label: 'Old Movies',
    types: [
      {
        value: 'old movies',
        label: 'Old Movies',
        image:
          'https://res.cloudinary.com/dakp804eh/video/upload/v1671050465/Woxo%20Videos/Experiments/Backgrounds/Old%20Movies/v51.jpg',
        tier: 'premium'
      }
    ]
  },
  {
    value: 'tiktok',
    label: 'TikTok',
    types: [
      {
        value: 'tiktok_dances',
        label: 'Dances',
        image:
          'https://res.cloudinary.com/dakp804eh/video/upload/v1671305656/Woxo%20Videos/Experiments/Backgrounds/Tiktok/Dances/download_1.jpg',
        tier: 'free'
      },
      {
        value: 'tiktok_origami',
        label: 'Origami',
        image:
          'https://res.cloudinary.com/dakp804eh/video/upload/v1671316091/Woxo%20Videos/Experiments/Backgrounds/Tiktok/Origami/download_9.jpg',
        tier: 'free'
      },
      {
        value: 'tiktok_handcraft',
        label: 'Handcraft',
        image:
          'https://res.cloudinary.com/dakp804eh/video/upload/v1671316026/Woxo%20Videos/Experiments/Backgrounds/Tiktok/Handcraft/download_11.jpg',
        tier: 'free'
      }
    ]
  },
  {
    value: 'memes-celebration',
    label: 'Memes (Celebration)',
    types: [
      {
        value: 'memes-celebration',
        label: '(Celebration)',
        image:
          'https://res.cloudinary.com/dakp804eh/video/upload/v1671301101/Woxo%20Videos/Experiments/Backgrounds/Memes/Celebration/parslow-prance-celebration-dance.jpg',
        tier: 'free'
      }
    ]
  }
];

export const getPrompt = (topic, language) => {
  return {
    label: 'What if dissapeared?',
    topic: `What if "${topic}" disappears`,
    description: `"What if X dissapeared" short-videos on a specific topic disappearing are a great way to explore the potential consequences of that topic no longer existing. They can be used to imagine what life would be like if a certain product, technology, or even a species were to suddenly disappear. They can be used to educate and inform the audience by providing a different perspective on the topic, and can also be used to raise awareness about the potential impact of losing that topic. They can be used to show the potential consequences of losing a specific topic and how it can change the course of history or the world we live in. Whether you're a science buff looking to share your knowledge or a brand looking to create engaging and informative content, "What if" short-videos on a specific topic disappearing are a great choice.`,
    examples: [
      'https://res.cloudinary.com/dakp804eh/video/upload/v1676990661/Woxo/Idea2video/category_details/what_if_dissapeared/what_if_dissapeared_1.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1676990661/Woxo/Idea2video/category_details/what_if_dissapeared/what_if_dissapeared_2.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1676990661/Woxo/Idea2video/category_details/what_if_dissapeared/what_if_dissapeared_3.mp4'
    ],
    inspiration: [
      {
        url: 'https://www.youtube.com/@AumSumWhatIf',
        channelLogo:
          'https://res.cloudinary.com/dakp804eh/image/upload/v1676988298/Woxo/Idea2video/category_details/what_if_dissapeared/h6mlebluOEPzYA7vT6rMCSDcXXQ5nMXG5-76vZUeGVggED7nnL9f2flTgVFHOUmXZZZeRHLXUo0_s176-c-k-c0x00ffffff-no-rj.jpg',
        chanelName: 'AumSum - What If',
        channel: '@AumSumWhatIf',
        subscribers: '296k',
        description: `A channel that explores hypothetical scenarios and their potential consequences, focused on children's stories.`
      },
      {
        url: 'https://www.youtube.com/@AsapSCIENCE',
        channelLogo:
          'https://res.cloudinary.com/dakp804eh/image/upload/v1676988330/Woxo/Idea2video/category_details/what_if_dissapeared/AL5GRJU1smsnzPU533Gu1vekyh-uHcLt75Dh67ZuswiRew_s176-c-k-c0x00ffffff-no-rj.jpg',
        chanelName: 'AsapSCIENCE',
        channel: '@AsapSCIENCE',
        subscribers: '10.3M',
        description: `A channel that explores a wide range of scientific topics, including what would happen if certain elements or phenomena disappeared.`
      },
      {
        url: 'https://www.youtube.com/@AlternateHistoryHub',
        channelLogo:
          'https://res.cloudinary.com/dakp804eh/image/upload/v1676988437/Woxo/Idea2video/category_details/what_if_dissapeared/AL5GRJUlgEPBHEf_sVjTrG8EIq1FuifizuZLmJByaZH7nw_s176-c-k-c0x00ffffff-no-rj.jpg',
        chanelName: 'AlternateHistoryHub',
        channel: '@AlternateHistoryHub',
        subscribers: '2.18M',
        description: `A channel that explores different scenarios and possibilities in history if certain events had gone differently.`
      }
    ],
    tier: 'premium',
    promptContent: `
    User prompt: ${topic}
    Create one or more short video scripts of 5 scenes, each pondering a "What if ${topic} disappeared?" scenario. Follow these guidelines:
    Scene 1: Begin with a hook presenting the hypothetical situation "What if the main subject disappeared?"
    Scene 2: Elaborate on the immediate consequences if the main subject disappeared (up to 20 words).
    Scene 3: Continue to explore the implications from Scene 2 (up to 20 words).
    Scene 4: Further delve into the long-term effects from Scene 3 (up to 20 words).
    Scene 5: Conclude with a call to action, such as "Follow us for more 'What if' scenarios about the main subject!"
    Ensure each voiceover is under 20 words (Language: ${language}).`
  };
};

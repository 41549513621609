// El objetivo de este file es tener por idioma una serie de voices
// preconfiguradas que el usuario pueda schitchear en un select

// @ED -> Deberiamos copiar el aproach, las voices y los nombres incluso de
// https://www.lovo.ai/

// Al principio solo tener 2 por idioma -> Un hombre y una mujer. Luego ir agregando mas

export const voices = {
  EN: {
    tony: {
      label: 'Eric',
      apiType: 'azure',
      voice: 'en-US-TonyNeural',
      language: 'en-US'
    },
    davis: {
      label: 'Bill',
      apiType: 'azure',
      voice: 'en-US-DavisNeural',
      language: 'en-US'
    },
    christopher: {
      label: 'Brian',
      apiType: 'azure',
      voice: 'en-US-ChristopherNeural',
      language: 'en-US'
    },
    amber: {
      label: 'Claire',
      apiType: 'azure',
      voice: 'en-US-AmberNeural',
      language: 'en-US'
    },
    guy: {
      label: 'Andy',
      apiType: 'azure',
      voice: 'en-US-GuyNeural',
      language: 'en-US'
    },
    sara: {
      label: 'Rebecca',
      apiType: 'azure',
      voice: 'en-US-SaraNeural',
      language: 'en-US'
    },
    aria: {
      label: 'Elsie',
      apiType: 'azure',
      voice: 'en-US-AriaNeural',
      language: 'en-US'
    },
    nancy: {
      label: 'Mia',
      apiType: 'azure',
      voice: 'en-US-NancyNeural',
      language: 'en-US'
    },
    jason: {
      label: 'Patrick',
      apiType: 'azure',
      voice: 'en-US-JasonNeural',
      language: 'en-US'
    },
    ana: {
      label: 'Monica',
      apiType: 'azure',
      voice: 'en-US-AnaNeural',
      language: 'en-US'
    },
    ashley: {
      label: 'Naomi',
      apiType: 'azure',
      voice: 'en-US-AshleyNeural',
      language: 'en-US'
    },
    roger: {
      label: 'Jackson',
      apiType: 'azure',
      voice: 'en-US-RogerNeural',
      language: 'en-US'
    },
    steffan: {
      label: 'Anthony',
      apiType: 'azure',
      voice: 'en-US-SteffanNeural',
      language: 'en-US'
    }
  },
  ES: {
    jorge: {
      label: 'Alejandro',
      apiType: 'azure',
      voice: 'es-MX-JorgeNeural',
      language: 'es-MX'
    },
    paloma: {
      label: 'Renata',
      apiType: 'azure',
      voice: 'es-US-PalomaNeural',
      language: 'es-US'
    },
    nuria: {
      label: 'Pilar',
      apiType: 'azure',
      voice: 'es-MX-NuriaNeural',
      language: 'es-MX'
    },
    cecilio: {
      label: 'Alberto',
      apiType: 'azure',
      voice: 'es-MX-CecilioNeural',
      language: 'es-MX'
    },
    luciano: {
      label: 'Silvio',
      apiType: 'azure',
      voice: 'es-MX-LucianoNeural',
      language: 'es-MX'
    },
    carlota: {
      label: 'Eugenia',
      apiType: 'azure',
      voice: 'es-MX-CarlotaNeural',
      language: 'es-MX'
    },
    liberto: {
      label: 'Vicente',
      apiType: 'azure',
      voice: 'es-MX-LibertoNeural',
      language: 'es-MX'
    },
    dalia: {
      label: 'Celia',
      apiType: 'azure',
      voice: 'es-MX-DaliaNeural',
      language: 'es-MX'
    },
    beatriz: {
      label: 'Rosario',
      apiType: 'azure',
      voice: 'es-MX-BeatrizNeural',
      language: 'es-MX'
    },

    arnau: {
      label: 'Francisco',
      apiType: 'azure',
      voice: 'es-ES-ArnauNeural',
      language: 'es-ES'
    },
    estrella: {
      label: 'Luna',
      apiType: 'azure',
      voice: 'es-ES-EstrellaNeural',
      language: 'es-ES'
    },
    abril: {
      label: 'Vanesa',
      apiType: 'azure',
      voice: 'es-ES-AbrilNeural',
      language: 'es-ES'
    },
    irene: {
      label: 'Adriana',
      apiType: 'azure',
      voice: 'es-ES-IreneNeural',
      language: 'es-ES'
    }
  },
  FR: {
    alain: {
      label: 'Renard',
      apiType: 'azure',
      voice: 'fr-FR-AlainNeural',
      language: 'fr-FR'
    },
    brigitte: {
      label: 'Aimeé',
      apiType: 'azure',
      voice: 'fr-FR-BrigitteNeural',
      language: 'fr-FR'
    },
    denise: {
      label: 'Nina',
      apiType: 'azure',
      voice: 'fr-FR-DeniseNeural',
      language: 'fr-FR'
    },
    eloise: {
      label: 'Désirée',
      apiType: 'azure',
      voice: 'fr-FR-EloiseNeural',
      language: 'fr-FR'
    },
    henri: {
      label: 'Pierre',
      apiType: 'azure',
      voice: 'fr-FR-HenriNeural',
      language: 'fr-FR'
    },
    jacqueline: {
      label: 'Pauline',
      apiType: 'azure',
      voice: 'fr-FR-JacquelineNeural',
      language: 'fr-FR'
    },
    jerome: {
      label: 'Roland',
      apiType: 'azure',
      voice: 'fr-FR-JeromeNeural',
      language: 'fr-FR'
    },
    josephine: {
      label: 'Francine',
      apiType: 'azure',
      voice: 'fr-FR-JosephineNeural',
      language: 'fr-FR'
    },
    maurice: {
      label: 'Jori',
      apiType: 'azure',
      voice: 'fr-FR-MauriceNeural',
      language: 'fr-FR'
    },
    yvette: {
      label: 'Marion',
      apiType: 'azure',
      voice: 'fr-FR-YvetteNeural',
      language: 'fr-FR'
    }
  },
  HI: {
    madhur: {
      label: 'Aarav',
      apiType: 'azure',
      voice: 'hi-IN-MadhurNeural',
      language: 'hi-IN'
    },
    swara: {
      label: 'Sanjana',
      apiType: 'azure',
      voice: 'hi-IN-SwaraNeural',
      language: 'hi-IN'
    }
  },
  DE: {
    amala: {
      label: 'Elsa',
      apiType: 'azure',
      voice: 'de-DE-AmalaNeural',
      language: 'de-DE'
    },
    bernd: {
      label: 'Ulrich',
      apiType: 'azure',
      voice: 'de-DE-BerndNeural',
      language: 'de-DE'
    },
    christoph: {
      label: 'Lukas',
      apiType: 'azure',
      voice: 'de-DE-ChristophNeural',
      language: 'de-DE'
    },
    conrad: {
      label: 'Aldo',
      apiType: 'azure',
      voice: 'de-DE-ConradNeural',
      language: 'de-DE'
    }
  },
  PT: {
    antonio: {
      label: 'Rodrigo',
      apiType: 'azure',
      voice: 'pt-BR-AntonioNeural',
      language: 'pt-BR'
    },
    brenda: {
      label: 'Fabiana',
      apiType: 'azure',
      voice: 'pt-BR-BrendaNeural',
      language: 'pt-BR'
    },
    donato: {
      label: 'Mateo',
      apiType: 'azure',
      voice: 'pt-BR-DonatoNeural',
      language: 'pt-BR'
    },
    elza: {
      label: 'Anna',
      apiType: 'azure',
      voice: 'pt-BR-ElzaNeural',
      language: 'pt-BR'
    },
    fabio: {
      label: 'Joao',
      apiType: 'azure',
      voice: 'pt-BR-FabioNeural',
      language: 'pt-BR'
    }
  }
};

import { v4 } from 'uuid';
import dayjs from 'dayjs';
import fileDownload from 'js-file-download';
import queryString from 'query-string';
import axios from './axios';
import api from './api';
import { isArray, isObject, stringToColor } from '../helpers/javascript';
import { API_HOST, API_VIDEO, API_BROWSE } from './app.config';
import { getAuthHeader } from './security.service';
import Toast from '~/helpers/notification';
import { connect } from '~/services/iot.services';
import Button from '@material-ui/core/Button';
import Router from 'next/router';
import { Box, LinearProgress } from '@material-ui/core';
import React from 'react';
import * as Sentry from '@sentry/nextjs';

const toastBuildProps = {
  id: 'buildProcess',
  style: {
    minWidth: '250px',
    minHeight: '50px'
  },
  duration: 50 * 1000
};

const toastDownloadProps = {
  id: 'downloadProcess',
  style: {
    minWidth: '250px',
    minHeight: '50px'
  }
};

let buildProgress = 0;

export const check = ({ jobid, limit, onlyTotals }) =>
  new Promise((resolve, reject) => {
    axios({
      url: `${API_VIDEO}/statuslambda?jobid=${jobid}&limit=${limit}&onlyTotals=${onlyTotals}`
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => reject(e));
  });

const status = (data, limit) =>
  new Promise((resolve, reject) => {
    const interval = setInterval(() => {
      check({ jobid: data.jobid, limit })
        .then((response) => {
          if (response.totalRunning === 0) {
            clearInterval(interval);
            resolve(response.data);
          }
        })
        .catch((e) => {});
    }, 4000);
  });

const statusDownload = (downloadJobId) =>
  new Promise((resolve, reject) => {
    const interval = setInterval(() => {
      axios({
        url: `${API_VIDEO}/statusdownload?downloadJobId=${downloadJobId}`
      })
        .then((response) => {
          if (response.data.status === 'done') {
            clearInterval(interval);
            resolve(response.data);
          } else if (response.data.status === 'fail') {
            throw response.data.errors;
          }
        })
        .catch((e) => {
          clearInterval(interval);
          reject(e);
        });
    }, 4000);
  });

export const tags = (v) => {
  const tg =
    v.editSpec && v.editSpec.metadata && isArray(v.editSpec.metadata.tags)
      ? v.editSpec.metadata.tags.map((t) => ({
          name: t,
          color: `#${stringToColor(t)}`
        }))
      : [];
  tg.push({ name: dayjs().format('MMM DD, YYYY'), color: '#0286ff' });
  return tg;
};

export const search = ({ _tags, ...params }) =>
  new Promise((resolve, reject) => {
    const query = queryString.stringify(params);
    axios({
      url: `${process.env.API_VIDEO}/search?tags=${_tags ? _tags.join(',') : ''}&${query}`
    })
      .then((response) => {
        resolve({
          data: response.data.data.map((v, i) => ({
            url: v.videoEndpoint,
            thumbnails: `${v.videoEndpoint.substring(0, v.videoEndpoint.length - 3)}jpg`,
            preview: `${v.videoEndpoint.substring(0, v.videoEndpoint.length - 3)}gif`,
            outPath: v.outPath,
            title:
              (v.editSpec && v.editSpec.metadata && v.editSpec.metadata.title) ||
              (v.editSpec && v.editSpec.fileName) ||
              v.editSpec.metadata?.data?.['Title 1'] ||
              `Video #${i + 1}`,
            id: v._id,
            resolution: { width: v.editSpec.width, height: v.editSpec.height },
            tags: tags(v),
            metadata: v.editSpec.metadata || {},
            downloadLink: v.downloadLink
          })),
          total: response.data.total,
          tags: response.data.tags
        });
      })
      .catch((e) => reject(e));
  });

export const reach = (filter) =>
  new Promise((resolve, reject) => {
    const query = queryString.stringify(filter);

    axios({
      url: `${API_BROWSE}/sheet2video/reach?${query}`
    })
      .then((response) => {
        resolve({
          data: response.data.videos,
          project: response.data.project,
          total: response.data.total || response.data.videos.length
        });
      })
      .catch((e) => resolve({ data: false, error: e }));
  });

export const create = (file, template, setCount, limit) =>
  new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append('files', file);
    formData.append('refId', v4());
    formData.append('ref', 'user');
    formData.append('source', 'users-permissions');
    formData.append('field', 'profileImage');
    formData.append('template', template);

    api({
      url: `${API_HOST}/custom/videos`,
      method: 'POST',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then((response) => {
        setCount(response.data.success.total_task);
        return Promise.all([response.data.success.jobid, status(response.data.success, limit)]);
      })
      .then(([jobid, data]) => {
        resolve({
          data: data.map((v, i) => ({
            url: v.videoEndpoint,
            outPath: v.outPath,
            title:
              (v.editSpec && v.editSpec.metadata && v.editSpec.metadata.title) ||
              v.editSpec.metadata.data['Title 1'],
            id: v._id,
            tags: tags(v)
          })),
          jobid
        });
      })
      .catch((e) => {
        reject(e);
      });
  });

export const find = ({ jobid, page, limit }) =>
  new Promise((resolve, reject) => {
    axios({
      url: `${API_VIDEO}/statuslambda?jobid=${jobid}&page=${page}&limit=${limit}`
    })
      .then((response) => {
        resolve({
          data: response.data.data.map((v, i) => ({
            url: v.videoEndpoint,
            outPath: v.outPath,
            title:
              (v.editSpec && v.editSpec.metadata && v.editSpec.metadata.title) ||
              v.editSpec.metadata.data['Title 1'],
            id: v._id,
            tags: tags(v)
          })),
          jobid
        });
      })
      .catch((e) => reject(e));
  });

export const project = ({ videos, videoCode, title, action, fixToVersion, index, meta }) =>
  new Promise((resolve, reject) => {
    api({
      url: `${API_HOST}/custom/videos/project`,
      method: 'POST',
      data: { videos, videoCode, title, action, fixToVersion, index, meta }
    })
      .then((response) => {
        if (response.data && response.data.success) resolve(response.data.success);
        else reject();
      })
      .catch(() => {
        reject();
      });
  });

export const projectVersions = ({ code }) =>
  new Promise((resolve, reject) => {
    api({
      url: `${API_HOST}/custom/videos/project/versions/${code}`
    })
      .then((response) => {
        if (response.data && isArray(response.data.success)) resolve(response.data.success);
        else reject();
      })
      .catch(() => {
        reject();
      });
  });

export const datasource = ({ file }) =>
  new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append('files', file);
    api({
      url: `${API_HOST}/custom/videos/datasource`,
      method: 'POST',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then((response) => {
        if (response.data.success) resolve(response.data.success);
        else reject();
      })
      .catch((e) => {
        reject(e);
      });
  });

// export const generate = (json, limit, apiLegacy = false) =>
//   new Promise(async (resolve, reject) => {
//     const token = (await getAuthHeader()).Authorization.replace('Bearer ', '');
//     api({
//       url: `${API_VIDEO}/generatelambda`,
//       method: 'POST',
//       data: { wait: false, json, token, apiLegacy }
//     })
//       .then((response) => Promise.all([response.data.jobid, status(response.data, limit)]))
//       .then(([jobid, data]) => {
//         resolve({
//           data: data.map((v, i) => ({
//             url: v.videoEndpoint,
//             outPath: v.outPath,
//             jobid: v.jobid,
//             title:
//               (v.editSpec && v.editSpec.metadata && v.editSpec.metadata.title) ||
//               v.editSpec.metadata.data['Title 1'],
//             id: v._id,
//             tags: tags(v),
//             thumbnailsLink: v.thumbnailsLink
//           })),
//           jobid
//         });
//       })
//       .catch((e) => {
//         reject(e);
//       });
//   });

export const generateDownloadVideoZipOrder = async (jobid) =>
  new Promise(async (resolve, reject) => {
    axios({
      url: `${API_VIDEO}/download?jobid=${jobid}`
    })
      .then(async (response) => {
        connect(
          process.env.AWS_IOT_REGION,
          process.env.AWS_IOT_ENDPOINT,
          process.env.AWS_IOT_ACCESS_ID,
          process.env.AWS_IOT_SECRET_KEY
        )
          .then((iotConnection) => {
            subscribeDownloadEvent(response.data.downloadJobId, iotConnection);
          })
          .catch((e) => {
            console.log(e);
          });

        resolve(statusDownload(response.data.downloadJobId));
      })
      .catch((e) => {
        console.error(e);
        reject(e);
      });
  });

export const getThumbnails = (json) =>
  new Promise((resolve, reject) => {
    axios({
      url: `${API_BROWSE}/sheet2video/thumbnails`,
      method: 'POST',
      data: { json },
      headers: {
        'Content-Type': 'text/plain'
      }
    })
      .then((response) => {
        console.log(response);
        resolve(response.data);
      })
      .catch((e) => {
        console.log({ e });
        reject(e);
      });
  });
export const saveExperience = (json) =>
  new Promise((resolve, reject) => {
    axios({
      url: `${API_BROWSE}/sheet2video/experienceupdate`,
      method: 'POST',
      data: json,
      headers: {
        'Content-Type': 'text/plain'
      }
    })
      .then((response) => resolve(response.data))
      .catch((e) => {
        reject(e);
      });
  });

export const generateWithoutStatus = (
  json,
  code,
  parentJobid,
  limit,
  apiLegacy = false,
  tool = 'default-tool'
) =>
  new Promise(async (resolve, reject) => {
    let iotConnection;

    const token = (await getAuthHeader()).Authorization.replace('Bearer ', '');
    api({
      url: `${API_VIDEO}/generatelambda`,
      method: 'POST',
      data: { wait: false, json, token, code, parentJobid, apiLegacy, tool }
    })
      .then(async (response) => {
        if (code && !Router.pathname.includes('bulk-creator')) {
          connect(
            process.env.AWS_IOT_REGION,
            process.env.AWS_IOT_ENDPOINT,
            process.env.AWS_IOT_ACCESS_ID,
            process.env.AWS_IOT_SECRET_KEY
          )
            .then((iotConnection) => {
              subscribeBuildEvent(response.data.jobid, json.length, code, iotConnection);
            })
            .catch((e) => {
              console.log(e);
            });
        }
        resolve(response);
      })
      .catch((e) => {
        reject(e);
      });
  });
export const cancelGeneration = (jobid) =>
  new Promise((resolve, reject) => {
    api({
      url: `${API_VIDEO}/cancellambda`,
      method: 'POST',
      data: { jobid }
    })
      .then((response) => {
        resolve(response);
      })
      .catch((e) => {
        Sentry.captureException(e);
        reject(e);
      });
  });

export const download = (video) =>
  new Promise((resolve, reject) => {
    const path = video.split('front10/');

    const name = path[1].split('/')[1].split('?')[0];

    const encoded = encodeURI(`/aws/output/front10/${path[1]}`);

    axios
      .get(encoded, {
        responseType: 'blob'
      })
      .then((res) => {
        fileDownload(res.data, name);
      })
      .then((response) => {
        resolve({
          data: 'download finished'
        });
      });
  });

export const currentVideos = () =>
  new Promise((resolve) => {
    api({
      method: 'POST',
      url: `${API_VIDEO}/check`
    })
      .then((response) => {
        if (isObject(response.data) && response.data) {
          resolve(response.data);
        } else resolve(false);
      })
      .catch((e) => {
        resolve(false);
      });
  });

export const downloadCsv = (data) => {
  axios
    .post('/aws/video/downloadcsv', { data }, { responseType: 'blob' })
    .then(function (response) {
      const fileName = response.headers['content-disposition'].split('filename=')[1];

      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          // type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          type: 'text/csv'
        })
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', response.headers['content-disposition'].split('filename=')[1]);
      document.body.appendChild(link);
      link.click();
    });
};

export const save = ({ videos, code }) =>
  new Promise((resolve, reject) => {
    api({
      url: `${API_HOST}/custom/videos`,
      method: 'POST',
      data: { videos, code }
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch(() => {
        reject();
      });
  });
export const saveDynamic = ({ videos, code }) =>
  new Promise((resolve, reject) => {
    api({
      url: `${API_HOST}/custom/dynamic/videos`,
      method: 'POST',
      data: { videos, code }
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch(() => {
        reject();
      });
  });

export const findMyVideos = ({ code, version }) =>
  new Promise((resolve, reject) => {
    api({
      url: `${API_HOST}/custom/videos/${code}/${version}`
    })
      .then((response) => {
        if (response.data && response.data.success) resolve(response.data.success);
        else reject();
      })
      .catch((e) => {
        reject(e);
      });
  });

export const findProjectsByVersion = ({ code, version }) =>
  new Promise((resolve, reject) => {
    api({
      url: `${API_HOST}/custom/videos/${code}/${version}`
    })
      .then((response) => {
        if (response.data && response.data) resolve(response.data);
        else reject();
      })
      .catch((e) => {
        reject(e);
      });
  });

export const findProjects = ({ code, paginateVideos, _limit, _page, query, _sort }) =>
  new Promise((resolve, reject) => {
    api({
      url: `${API_HOST}/custom/videos/project?${queryString.stringify({
        code,
        paginateVideos,
        _limit,
        _page,
        query,
        _sort
      })}`
    })
      .then((response) => {
        if (response.data && response.data.success) resolve(response.data.success);
        else reject();
      })
      .catch((e) => {
        reject(e);
      });
  });

export const paginateVideos = ({ id, page, limit, type }) =>
  new Promise((resolve, reject) => {
    api({
      url: `${API_HOST}/custom/videos/project/paginate/${id}?${queryString.stringify({
        _page: page,
        _limit: limit,
        type
      })}`
    })
      .then((response) => {
        if (response.data && response.data.success) resolve(response.data.success);
        else reject();
      })
      .catch((e) => {
        reject(e);
      });
  });

export const remove = (id) =>
  new Promise((resolve, reject) => {
    api({
      url: `${API_HOST}/custom/videos/${id}`,
      method: 'DELETE'
    })
      .then((response) => {
        if (!response.data.error) resolve();
        else reject();
      })
      .catch(() => {
        reject();
      });
  });

export const removeAll = (idList) =>
  new Promise((resolve, reject) => {
    api({
      url: `${API_HOST}/custom/videos/deleteMany`,
      method: 'POST',
      data: { videos: idList }
    })
      .then((response) => {
        if (!response.data.error) resolve();
        else reject();
      })
      .catch(() => {
        reject();
      });
  });

export const calendarUrl = () =>
  new Promise((resolve, reject) => {
    axios({
      url: `${API_HOST}/custom/videos/calendar/google/oauth`,
      method: 'POST',
      data: { redirectUrl: `${window.location.origin}/connect/google-token` }
    })
      .then((response) => {
        if (response.data.success) resolve(response.data.success);
        else reject();
      })
      .catch(() => {
        reject();
      });
  });

export const googleAuthToken = ({ code }) =>
  new Promise((resolve, reject) => {
    axios({
      url: `${API_HOST}/custom/videos/calendar/google/token`,
      method: 'POST',
      data: { code, redirectUrl: `${window.location.origin}/connect/google-token` }
    })
      .then((response) => {
        if (response.data.success) resolve(response.data.success);
        else reject();
      })
      .catch(() => {
        reject();
      });
  });

export const calendarAddEvents = ({ token, events }) =>
  new Promise((resolve, reject) => {
    axios({
      url: `${API_HOST}/custom/videos/calendar/add-event`,
      method: 'POST',
      data: { code: token, events, redirectUrl: `${window.location.origin}/connect/google-token` }
    })
      .then((response) => {
        if (response.data.success) resolve(response.data.success);
        else reject();
      })
      .catch(() => {
        reject();
      });
  });

export const rename = ({ id, name }) =>
  new Promise(async (resolve, reject) => {
    const headers = await getAuthHeader();
    axios({
      url: `${API_HOST}/custom/videos/rename`,
      method: 'POST',
      data: { name, id },
      headers: headers
    })
      .then((response) => {
        if (response.data.success) resolve();
        else reject();
      })
      .catch(() => {
        reject();
      });
  });

//Subscriptions
function subscribeBuildEvent(jobid, totalTasks, code, iotConnection) {
  return new Promise(async (resolve, reject) => {
    const { mqtt } = await import('aws-iot-device-sdk-v2');
    Toast.success(
      <p>
        The build process is in progress. <b>Attention is not needed!!!</b>
      </p>,
      'Build process',
      toastBuildProps
    );
    const finishSubscription = await iotConnection.subscribe(
      `/video/generate/${jobid}`,
      mqtt.QoS.AtLeastOnce,
      (topic, payload, dup, qos, retain) =>
        onGenerateVideosFinish({ topic, payload, dup, qos, retain }, code, iotConnection)
    );
    const progressSubscription = await iotConnection.subscribe(
      `/video/generate/${jobid}/progress`,
      mqtt.QoS.AtLeastOnce,
      (topic, payload, dup, qos, retain) =>
        onTaskVideoGenerateFinish({ topic, payload, dup, qos, retain }, totalTasks)
    );

    if (progressSubscription && finishSubscription) resolve();
    else reject();
  });
}

function subscribeDownloadEvent(downloadJobId, iotConnection) {
  return new Promise(async (resolve, reject) => {
    const { mqtt } = await import('aws-iot-device-sdk-v2');
    Toast.success(
      <p>
        The download task is in process. <b>Attention is not needed!!!</b>
      </p>,
      'Download task',
      toastDownloadProps
    );
    const finishSubscription = await iotConnection.subscribe(
      `/video/download/${downloadJobId}`,
      mqtt.QoS.AtLeastOnce,
      (topic, payload, dup, qos, retain) => {
        onPrepareDownloadFinish(payload, iotConnection);
      }
    );

    const progressSubscription = await iotConnection.subscribe(
      `/video/download/${downloadJobId}/progress`,
      mqtt.QoS.AtLeastOnce,
      (topic, payload, dup, qos, retain) => {
        onDownloadProgress(topic, payload);
      }
    );

    if (progressSubscription && finishSubscription) resolve();
    else reject();
  });
}

//Event handlers -----------------------
function onGenerateVideosFinish({ topic, payload, dup, qos, retain }, slugPage, iotConnection) {
  const decodeMessage = decodeIotMessage(payload);
  console.log(`Message received: topic=${topic} message=${decodeMessage}`);

  Toast.success(
    <>
      <span> The build task is finish.</span>
      <Button
        onClick={() =>
          Router.push(
            `${
              Router.pathname.includes('sheet-to-video') ? '/sheet-to-video' : '/video'
            }/export?slug=${slugPage}`
          )
        }
      >
        Review
      </Button>
    </>,
    'Build process',
    { ...toastBuildProps, duration: 24 * 60 * 60 * 1000 }
  );
  buildProgress = 0;
  iotConnection.disconnect();
}

function onTaskVideoGenerateFinish({ topic, payload, dup, qos, retain }, totalTasks) {
  const decoder = new TextDecoder('utf8');
  let message = decoder.decode(new Uint8Array(payload));
  console.log(`Message received: topic=${topic} message=${message}`);
  Toast.success(
    <Box sx={{ width: 200 }}>
      <div style={{ height: 10 }}></div>
      <LinearProgress variant="determinate" value={(++buildProgress / totalTasks) * 100} />
    </Box>,
    'Build process',
    toastBuildProps
  );
}

function onPrepareDownloadFinish(payload, iotConnection) {
  let message = decodeIotMessage(payload);
  message = JSON.parse(message);
  Toast.success(<a href={`${message.Location}`}>Here is your download link</a>, 'Download task', {
    ...toastDownloadProps,
    duration: 24 * 60 * 60 * 1000
  });
  iotConnection.disconnect();
}

function onDownloadProgress(topic, payload) {
  const decoder = new TextDecoder('utf8');
  let message = decoder.decode(new Uint8Array(payload));
  console.log(`Message received: topic=${topic} message=${message}`);
  message = JSON.parse(message);
  Toast.success(
    <Box sx={{ width: 200 }}>
      <div style={{ height: 10 }}></div>
      <LinearProgress variant="determinate" value={(message.part / message.total) * 100} />
    </Box>,
    'Download task',
    toastDownloadProps
  );
}

//Auxiliary functions
function decodeIotMessage(payload) {
  const decoder = new TextDecoder('utf8');
  let message = decoder.decode(new Uint8Array(payload));
  return message;
}

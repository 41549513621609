import * as Sentry from '@sentry/nextjs';

export const publishVideos = async (videos) => {
  let API_HOST = 'https://woxo.tech';

  if (typeof window !== 'undefined') {
    API_HOST = window.location.origin;
  }
  const response = await fetch(`${API_HOST}/api/reely/videos`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(videos)
  });

  if (response.ok) {
    console.log('Videos published successfully');
  } else {
    Sentry.withScope((scope) => {
      scope.setExtra('status', response.status);
      scope.setExtra('statusText', response.statusText);
      Sentry.captureMessage('Failed to publish videos');
    });
    console.error('Failed to publish videos:', response.status, response.statusText);
  }
};

export const normalizeVideos = (_videosToDownload, generatedVideos, userData) => {
  const normalizedVideos = [];

  const normalizedUserData = {
    email: userData.email || '',
    username: userData.username || '',
    name: userData.name || '',
    picture: userData.picture || ''
  };

  generatedVideos.forEach((video, index) => {
    const meta = _videosToDownload[index].meta;
    const userPrompt = _videosToDownload[index].userPrompt;

    const normalizedVideo = {
      videoUrl: video.videoUrl,
      videoDownloadUrl: video.videoDownloadUrl,
      thumbnailUrl: video.thumbnailUrl,
      gifUrl: video.gifUrl,
      landingPage: video.landingPage,
      title: meta.title,
      description: meta.description,
      hashtags: meta.hashtags,
      mediaDescription: meta.mediaDescription,
      category: meta.category,
      userPrompt: userPrompt,
      likes: 0,
      userProfile: normalizedUserData
    };

    normalizedVideos.push(normalizedVideo);
  });

  return normalizedVideos;
};

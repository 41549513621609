export const data: any = [
  {
    tags: ['colorful', 'black'],
    medias: [
      'https://res.cloudinary.com/dakp804eh/video/upload/v1681923071/Woxo%20Videos/Experiments/Backgrounds/black/BlackBackground3.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1681923071/Woxo%20Videos/Experiments/Backgrounds/black/BlackBackground33.mp4'
    ]
  },
  {
    tags: ['colorful', 'fun facts'],
    medias: [
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671049471/Woxo%20Videos/Experiments/Backgrounds/Fun%20Facts/generic_4.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671049468/Woxo%20Videos/Experiments/Backgrounds/Fun%20Facts/generic_3.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671049468/Woxo%20Videos/Experiments/Backgrounds/Fun%20Facts/generic_2.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671049466/Woxo%20Videos/Experiments/Backgrounds/Fun%20Facts/bg01.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671049465/Woxo%20Videos/Experiments/Backgrounds/Fun%20Facts/trivia_14.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671049462/Woxo%20Videos/Experiments/Backgrounds/Fun%20Facts/trivia_15.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671049458/Woxo%20Videos/Experiments/Backgrounds/Fun%20Facts/Fun_facts_01.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671049456/Woxo%20Videos/Experiments/Backgrounds/Fun%20Facts/generic_1.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1670529243/Woxo%20Videos/Experiments/Backgrounds/Fun%20Facts/fun_facts_2.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1670529243/Woxo%20Videos/Experiments/Backgrounds/Fun%20Facts/fun_facts_3.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1670529231/Woxo%20Videos/Experiments/Backgrounds/Fun%20Facts/fun_facts_4.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1670529231/Woxo%20Videos/Experiments/Backgrounds/Fun%20Facts/fun_facts.mp4'
    ]
  },
  {
    tags: ['colorful', 'trivia'],
    medias: [
      'https://res.cloudinary.com/dakp804eh/video/upload/v1670529187/Woxo%20Videos/Experiments/Backgrounds/Trivia/trivia_13.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1670529120/Woxo%20Videos/Experiments/Backgrounds/Trivia/trivia_12.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1670529114/Woxo%20Videos/Experiments/Backgrounds/Trivia/trivia_2.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1670529114/Woxo%20Videos/Experiments/Backgrounds/Trivia/trivia_4.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1670529111/Woxo%20Videos/Experiments/Backgrounds/Trivia/trivia_1.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1670529110/Woxo%20Videos/Experiments/Backgrounds/Trivia/trivia_5.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1670529096/Woxo%20Videos/Experiments/Backgrounds/Trivia/trivia_9.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1670529096/Woxo%20Videos/Experiments/Backgrounds/Trivia/trivia_3.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1670529084/Woxo%20Videos/Experiments/Backgrounds/Trivia/trivia_8.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1670529071/Woxo%20Videos/Experiments/Backgrounds/Trivia/trivia_10.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1670529068/Woxo%20Videos/Experiments/Backgrounds/Trivia/trivia_11.mp4'
    ]
  },
  {
    tags: ['colorful', 'quotes'],
    medias: [
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671049509/Woxo%20Videos/Experiments/Backgrounds/Quotes/quote_02.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671049508/Woxo%20Videos/Experiments/Backgrounds/Quotes/quote_03.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1670529026/Woxo%20Videos/Experiments/Backgrounds/Quotes/quotes_2.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1670529026/Woxo%20Videos/Experiments/Backgrounds/Quotes/quotes_7.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1670529020/Woxo%20Videos/Experiments/Backgrounds/Quotes/quotes_10.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1670529020/Woxo%20Videos/Experiments/Backgrounds/Quotes/quotes_6.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1670529015/Woxo%20Videos/Experiments/Backgrounds/Quotes/quotes_11.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1670528997/Woxo%20Videos/Experiments/Backgrounds/Quotes/quotes_12.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1670528981/Woxo%20Videos/Experiments/Backgrounds/Quotes/quotes_14.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1670528978/Woxo%20Videos/Experiments/Backgrounds/Quotes/quotes_8.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1670528972/Woxo%20Videos/Experiments/Backgrounds/Quotes/quotes_13.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1670528971/Woxo%20Videos/Experiments/Backgrounds/Quotes/quotes_9.mp4'
    ]
  },
  {
    tags: ['colorful', 'jokes'],
    medias: [
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671049574/Woxo%20Videos/Experiments/Backgrounds/Jokes/jokes_7.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671049571/Woxo%20Videos/Experiments/Backgrounds/Jokes/jokes09.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671049565/Woxo%20Videos/Experiments/Backgrounds/Jokes/jokes11.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671049560/Woxo%20Videos/Experiments/Backgrounds/Jokes/jokes10.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671049559/Woxo%20Videos/Experiments/Backgrounds/Jokes/jokes_12.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671049555/Woxo%20Videos/Experiments/Backgrounds/Jokes/jokes08.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671049554/Woxo%20Videos/Experiments/Backgrounds/Jokes/jokes_13.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1670683947/Woxo%20Videos/Experiments/Backgrounds/Jokes/jokes_2.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1670683947/Woxo%20Videos/Experiments/Backgrounds/Jokes/jokes_5.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1670683946/Woxo%20Videos/Experiments/Backgrounds/Jokes/jokes_6.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1670683944/Woxo%20Videos/Experiments/Backgrounds/Jokes/jokes_4.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1670683943/Woxo%20Videos/Experiments/Backgrounds/Jokes/jokes_3.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1670683943/Woxo%20Videos/Experiments/Backgrounds/Jokes/jokes_1.mp4'
    ]
  },
  {
    tags: ['nature', 'mountains'],
    medias: [
      'https://res.cloudinary.com/dakp804eh/video/upload/v1659589272/Woxo/Instagram%20reels/medias/mountains/1.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1659589272/Woxo/Instagram%20reels/medias/mountains/3.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1659589272/Woxo/Instagram%20reels/medias/mountains/4.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1659589272/Woxo/Instagram%20reels/medias/mountains/5.mp4'
    ]
  },
  {
    tags: ['nature', 'sea'],
    medias: [
      'https://res.cloudinary.com/dakp804eh/video/upload/v1659590957/Woxo/Instagram%20reels/medias/sea/1.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1659590957/Woxo/Instagram%20reels/medias/sea/2.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1659590957/Woxo/Instagram%20reels/medias/sea/3.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1659590957/Woxo/Instagram%20reels/medias/sea/4.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1659590957/Woxo/Instagram%20reels/medias/sea/5.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1659589272/Woxo/Instagram%20reels/medias/mountains/6.mp4'
    ]
  },
  {
    tags: ['nature', 'forest road'],
    medias: [
      'https://res.cloudinary.com/dakp804eh/video/upload/v1659591885/Woxo/Instagram%20reels/medias/forest%20road/1.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1659591885/Woxo/Instagram%20reels/medias/forest%20road/3.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1659591885/Woxo/Instagram%20reels/medias/forest%20road/4.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1659591885/Woxo/Instagram%20reels/medias/forest%20road/5.mp4'
    ]
  },
  {
    tags: ['hiking'],
    medias: [
      'https://res.cloudinary.com/dakp804eh/video/upload/v1659592368/Woxo/Instagram%20reels/medias/hiking/1.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1659592368/Woxo/Instagram%20reels/medias/hiking/2.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1659592368/Woxo/Instagram%20reels/medias/hiking/3.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1659592368/Woxo/Instagram%20reels/medias/hiking/4.mp4'
    ]
  },
  {
    tags: ['road'],
    medias: [
      'https://res.cloudinary.com/dakp804eh/video/upload/v1659592995/Woxo/Instagram%20reels/medias/%20road/1.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1659592995/Woxo/Instagram%20reels/medias/%20road/2.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1659592995/Woxo/Instagram%20reels/medias/%20road/3.mp4'
    ]
  },
  {
    tags: ['nature', 'night sky', 'nature_landscapes'],
    medias: [
      'https://res.cloudinary.com/dakp804eh/video/upload/v1659593399/Woxo/Instagram%20reels/medias/night%20sky/1.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1659593399/Woxo/Instagram%20reels/medias/night%20sky/2.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1659593399/Woxo/Instagram%20reels/medias/night%20sky/3.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1659593399/Woxo/Instagram%20reels/medias/night%20sky/4.mp4'
    ]
  },
  {
    tags: ['nature', 'landscape', 'nature_landscapes'],
    medias: [
      'https://res.cloudinary.com/dakp804eh/video/upload/v1659593972/Woxo/Instagram%20reels/medias/landscape/1.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1659593972/Woxo/Instagram%20reels/medias/landscape/2.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1659593972/Woxo/Instagram%20reels/medias/landscape/4.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1659593972/Woxo/Instagram%20reels/medias/landscape/5.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1659593972/Woxo/Instagram%20reels/medias/landscape/6.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1659593972/Woxo/Instagram%20reels/medias/landscape/7.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1659593972/Woxo/Instagram%20reels/medias/landscape/8.mp4'
    ]
  },
  {
    tags: ['old movies'],
    medias: [
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671050465/Woxo%20Videos/Experiments/Backgrounds/Old%20Movies/v39.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671050460/Woxo%20Videos/Experiments/Backgrounds/Old%20Movies/v38.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671050451/Woxo%20Videos/Experiments/Backgrounds/Old%20Movies/v37.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671050446/Woxo%20Videos/Experiments/Backgrounds/Old%20Movies/V60.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671050431/Woxo%20Videos/Experiments/Backgrounds/Old%20Movies/v58.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671050429/Woxo%20Videos/Experiments/Backgrounds/Old%20Movies/v51.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671050420/Woxo%20Videos/Experiments/Backgrounds/Old%20Movies/v54.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671050408/Woxo%20Videos/Experiments/Backgrounds/Old%20Movies/v53.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671050393/Woxo%20Videos/Experiments/Backgrounds/Old%20Movies/v48.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671050390/Woxo%20Videos/Experiments/Backgrounds/Old%20Movies/v43.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671050386/Woxo%20Videos/Experiments/Backgrounds/Old%20Movies/v46.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671050385/Woxo%20Videos/Experiments/Backgrounds/Old%20Movies/v49.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671050382/Woxo%20Videos/Experiments/Backgrounds/Old%20Movies/v42.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671050348/Woxo%20Videos/Experiments/Backgrounds/Old%20Movies/v45.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671050341/Woxo%20Videos/Experiments/Backgrounds/Old%20Movies/v41.mp4'
    ]
  },
  {
    tags: ['real-people_emma'],
    medias: [
      'https://res.cloudinary.com/dakp804eh/video/upload/v1660564720/Woxo%20Videos/Woxo%20Ambassadors/Emm/01_two_fingers_up.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1660564722/Woxo%20Videos/Woxo%20Ambassadors/Emm/02_one_finger_up.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1660564723/Woxo%20Videos/Woxo%20Ambassadors/Emm/03_three_things.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1660564726/Woxo%20Videos/Woxo%20Ambassadors/Emm/04_five_things.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1660564730/Woxo%20Videos/Woxo%20Ambassadors/Emm/05_Thinking_hand_on_chin.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1660564735/Woxo%20Videos/Woxo%20Ambassadors/Emm/06_Realize_finger_up.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1660564741/Woxo%20Videos/Woxo%20Ambassadors/Emm/07_Thinking_smart.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1660564748/Woxo%20Videos/Woxo%20Ambassadors/Emm/08_You_can.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1660564756/Woxo%20Videos/Woxo%20Ambassadors/Emm/09_Yes_you_can.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1660564765/Woxo%20Videos/Woxo%20Ambassadors/Emm/10_Come_on_one_hand.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1660564775/Woxo%20Videos/Woxo%20Ambassadors/Emm/11_Come_on_two_hands.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1660564786/Woxo%20Videos/Woxo%20Ambassadors/Emm/12_Hey_awesome.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1660564798/Woxo%20Videos/Woxo%20Ambassadors/Emm/13_Watch_this_one_hand.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1660564812/Woxo%20Videos/Woxo%20Ambassadors/Emm/14_Watch_thi_two_hands.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1660564825/Woxo%20Videos/Woxo%20Ambassadors/Emm/15_Love_this.mp4'
    ]
  },
  {
    tags: ['real-people_megan'],
    medias: [
      'https://res.cloudinary.com/dakp804eh/video/upload/v1660567202/Woxo%20Videos/Woxo%20Ambassadors/Megan/IMG_1296.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1660567208/Woxo%20Videos/Woxo%20Ambassadors/Megan/IMG_1297.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1660567197/Woxo%20Videos/Woxo%20Ambassadors/Megan/IMG_1298.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1660567152/Woxo%20Videos/Woxo%20Ambassadors/Megan/IMG_1299.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1660567055/Woxo%20Videos/Woxo%20Ambassadors/Megan/IMG_1300.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1660567074/Woxo%20Videos/Woxo%20Ambassadors/Megan/IMG_1301.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1660567123/Woxo%20Videos/Woxo%20Ambassadors/Megan/IMG_1302.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1660567075/Woxo%20Videos/Woxo%20Ambassadors/Megan/IMG_1303.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1660567205/Woxo%20Videos/Woxo%20Ambassadors/Megan/IMG_1304.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1660566909/Woxo%20Videos/Woxo%20Ambassadors/Megan/IMG_1305.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1660566871/Woxo%20Videos/Woxo%20Ambassadors/Megan/IMG_1307.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1660567084/Woxo%20Videos/Woxo%20Ambassadors/Megan/IMG_1308.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1660566888/Woxo%20Videos/Woxo%20Ambassadors/Megan/IMG_1309.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1660567074/Woxo%20Videos/Woxo%20Ambassadors/Megan/IMG_1310.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1660567093/Woxo%20Videos/Woxo%20Ambassadors/Megan/IMG_1311.mp4'
    ]
  },
  {
    tags: ['real-people_willy'],
    medias: [
      'https://res.cloudinary.com/dakp804eh/video/upload/v1660565589/Woxo%20Videos/Woxo%20Ambassadors/Willy/VID_20220812_160304_0.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1660565574/Woxo%20Videos/Woxo%20Ambassadors/Willy/VID_20220812_155947_0.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1660565480/Woxo%20Videos/Woxo%20Ambassadors/Willy/VID_20220812_155731_0.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1660565921/Woxo%20Videos/Woxo%20Ambassadors/Willy/VID_20220812_165608_0.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1660565936/Woxo%20Videos/Woxo%20Ambassadors/Willy/VID_20220812_160156_0.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1660565549/Woxo%20Videos/Woxo%20Ambassadors/Willy/VID_20220812_165543_0.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1660565618/Woxo%20Videos/Woxo%20Ambassadors/Willy/Thinking_smart..mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1660565927/Woxo%20Videos/Woxo%20Ambassadors/Willy/VID_20220812_161506_0.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1660565669/Woxo%20Videos/Woxo%20Ambassadors/Willy/VID_20220812_160610_0.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1660565684/Woxo%20Videos/Woxo%20Ambassadors/Willy/VID_20220812_160640_0.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1660565916/Woxo%20Videos/Woxo%20Ambassadors/Willy/VID_20220812_160753_0.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1660573791/Woxo%20Videos/Woxo%20Ambassadors/Willy/VID_20220815_093242_0.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1660573745/Woxo%20Videos/Woxo%20Ambassadors/Willy/VID_20220815_092951_0.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1660565536/Woxo%20Videos/Woxo%20Ambassadors/Willy/Watch_thi_two_hands.mp4.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1660565669/Woxo%20Videos/Woxo%20Ambassadors/Willy/VID_20220812_161039_0.mp4'
    ]
  },
  {
    tags: ['real-people_nadielly'],
    medias: [
      'https://res.cloudinary.com/dakp804eh/video/upload/v1660566516/Woxo%20Videos/Woxo%20Ambassadors/Nadielly/VID_33330927_001445_612.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1660566764/Woxo%20Videos/Woxo%20Ambassadors/Nadielly/VID_33270721_194526_063.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1660566645/Woxo%20Videos/Woxo%20Ambassadors/Nadielly/VID_33350821_112114_028.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1660566500/Woxo%20Videos/Woxo%20Ambassadors/Nadielly/VID_33231003_165934_176.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1660566746/Woxo%20Videos/Woxo%20Ambassadors/Nadielly/VID_33220429_152252_859.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1660566673/Woxo%20Videos/Woxo%20Ambassadors/Nadielly/VID_33200905_085046_642.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1660566510/Woxo%20Videos/Woxo%20Ambassadors/Nadielly/VID_33181211_100755_822.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1660566656/Woxo%20Videos/Woxo%20Ambassadors/Nadielly/VID_33150618_101516_853.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1660566665/Woxo%20Videos/Woxo%20Ambassadors/Nadielly/VID_33130520_040456_620.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1660566801/Woxo%20Videos/Woxo%20Ambassadors/Nadielly/VID_33111013_080927_367.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1660566501/Woxo%20Videos/Woxo%20Ambassadors/Nadielly/VID_33090818_121142_947.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1660566827/Woxo%20Videos/Woxo%20Ambassadors/Nadielly/VID_33061004_135220_482.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1660566778/Woxo%20Videos/Woxo%20Ambassadors/Nadielly/VID_33050328_034300_701_2.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1660566698/Woxo%20Videos/Woxo%20Ambassadors/Nadielly/VID_33030706_072123_917.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1660566712/Woxo%20Videos/Woxo%20Ambassadors/Nadielly/15_Love_this.mp4'
    ]
  },
  {
    tags: ['real-people_williams'],
    medias: [
      'https://res.cloudinary.com/dakp804eh/video/upload/v1660564859/Woxo%20Videos/Woxo%20Ambassadors/Williams/G1.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1660564872/Woxo%20Videos/Woxo%20Ambassadors/Williams/G2.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1660564882/Woxo%20Videos/Woxo%20Ambassadors/Williams/G3.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1660564899/Woxo%20Videos/Woxo%20Ambassadors/Williams/G4.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1660564918/Woxo%20Videos/Woxo%20Ambassadors/Williams/G5.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1660564930/Woxo%20Videos/Woxo%20Ambassadors/Williams/G6.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1660564945/Woxo%20Videos/Woxo%20Ambassadors/Williams/G7.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1660564959/Woxo%20Videos/Woxo%20Ambassadors/Williams/G8.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1660564982/Woxo%20Videos/Woxo%20Ambassadors/Williams/G9.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1660564997/Woxo%20Videos/Woxo%20Ambassadors/Williams/G10.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1660565040/Woxo%20Videos/Woxo%20Ambassadors/Williams/G11.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1660565047/Woxo%20Videos/Woxo%20Ambassadors/Williams/G12.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1660565052/Woxo%20Videos/Woxo%20Ambassadors/Williams/G13.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1660565097/Woxo%20Videos/Woxo%20Ambassadors/Williams/G14.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1660565113/Woxo%20Videos/Woxo%20Ambassadors/Williams/G15.mp4'
    ]
  },
  {
    tags: ['real-people_dariel'],
    medias: [
      'https://res.cloudinary.com/dakp804eh/video/upload/v1648137369/woxo-videos-user-gallery/darieladmin/VID_20210119_132038_dzkphz.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1648137376/woxo-videos-user-gallery/darieladmin/IMG_3688_vkzzst.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1648137399/woxo-videos-user-gallery/darieladmin/IMG_3493_m6cn7l.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1648137484/woxo-videos-user-gallery/darieladmin/IMG_4196_fgiu2s.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1648137488/woxo-videos-user-gallery/darieladmin/IMG_1051_baepns.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1648137499/woxo-videos-user-gallery/darieladmin/VID_20201231_083251_rspjky.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1649887896/woxo-videos-user-gallery/darieladmin/IMG_5227_g7fmnj.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1649887896/woxo-videos-user-gallery/darieladmin/IMG_5228_uz9fd6.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1649887896/woxo-videos-user-gallery/darieladmin/IMG_5226_ugnn9x.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1649887896/woxo-videos-user-gallery/darieladmin/IMG_5225_tefdll.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1650719899/woxo-videos-user-gallery/darieladmin/WhatsApp_Video_2022-04-22_at_4.07.48_PM_pwlhnj.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1650940136/woxo-videos-user-gallery/darieladmin/WhatsApp_Video_2022-04-22_at_4.07.42_PM_u402iy.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1651089672/woxo-videos-user-gallery/darieladmin/WhatsApp_Video_2022-04-22_at_4.07.44_PM_w5cj6v.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671300130/Woxo%20Videos/Experiments/Backgrounds/People/Dariel/VID_20210119_133455.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671300128/Woxo%20Videos/Experiments/Backgrounds/People/Dariel/DJI_20220419_110247_420_video.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671300098/Woxo%20Videos/Experiments/Backgrounds/People/Dariel/VID_20210119_132605.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671300065/Woxo%20Videos/Experiments/Backgrounds/People/Dariel/IMG_6171.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671300017/Woxo%20Videos/Experiments/Backgrounds/People/Dariel/IMG_0089.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671300016/Woxo%20Videos/Experiments/Backgrounds/People/Dariel/IMG_6742.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671299983/Woxo%20Videos/Experiments/Backgrounds/People/Dariel/IMG_6760.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671299940/Woxo%20Videos/Experiments/Backgrounds/People/Dariel/IMG_6385.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671299929/Woxo%20Videos/Experiments/Backgrounds/People/Dariel/IMG_6735.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671299924/Woxo%20Videos/Experiments/Backgrounds/People/Dariel/IMG_4640.mp4'
    ]
  },
  {
    tags: ['memes-celebration'],
    medias: [
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671301101/Woxo%20Videos/Experiments/Backgrounds/Memes/Celebration/parslow-prance-celebration-dance.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671301081/Woxo%20Videos/Experiments/Backgrounds/Memes/Celebration/star-warch-fist-punch.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671301032/Woxo%20Videos/Experiments/Backgrounds/Memes/Celebration/snoop-dogg-rap.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671301009/Woxo%20Videos/Experiments/Backgrounds/Memes/Celebration/dance-party.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671300981/Woxo%20Videos/Experiments/Backgrounds/Memes/Celebration/celebration-dance.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671300954/Woxo%20Videos/Experiments/Backgrounds/Memes/Celebration/celebration-confetti.mp4'
    ]
  },
  {
    tags: ['tiktok_dances'],
    medias: [
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671305656/Woxo%20Videos/Experiments/Backgrounds/Tiktok/Dances/download_1.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671305656/Woxo%20Videos/Experiments/Backgrounds/Tiktok/Dances/download_2.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671305655/Woxo%20Videos/Experiments/Backgrounds/Tiktok/Dances/download_5.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671305655/Woxo%20Videos/Experiments/Backgrounds/Tiktok/Dances/download_4.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671305655/Woxo%20Videos/Experiments/Backgrounds/Tiktok/Dances/download_3.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671305654/Woxo%20Videos/Experiments/Backgrounds/Tiktok/Dances/download_10.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671305655/Woxo%20Videos/Experiments/Backgrounds/Tiktok/Dances/download_8.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671305655/Woxo%20Videos/Experiments/Backgrounds/Tiktok/Dances/download_6.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671305655/Woxo%20Videos/Experiments/Backgrounds/Tiktok/Dances/download_7.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671305654/Woxo%20Videos/Experiments/Backgrounds/Tiktok/Dances/download_9.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671305653/Woxo%20Videos/Experiments/Backgrounds/Tiktok/Dances/download_11.mp4'
    ]
  },
  {
    tags: ['tiktok_origami'],
    medias: [
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671316091/Woxo%20Videos/Experiments/Backgrounds/Tiktok/Origami/download_9.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671316084/Woxo%20Videos/Experiments/Backgrounds/Tiktok/Origami/download_10.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671316083/Woxo%20Videos/Experiments/Backgrounds/Tiktok/Origami/download_4.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671316079/Woxo%20Videos/Experiments/Backgrounds/Tiktok/Origami/download_8.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671316079/Woxo%20Videos/Experiments/Backgrounds/Tiktok/Origami/download_3.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671316077/Woxo%20Videos/Experiments/Backgrounds/Tiktok/Origami/download_2.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671316076/Woxo%20Videos/Experiments/Backgrounds/Tiktok/Origami/download_7.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671316071/Woxo%20Videos/Experiments/Backgrounds/Tiktok/Origami/download_5.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671316063/Woxo%20Videos/Experiments/Backgrounds/Tiktok/Origami/download_1.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671316055/Woxo%20Videos/Experiments/Backgrounds/Tiktok/Origami/download.mp4'
    ]
  },
  {
    tags: ['tiktok_handcraft'],
    medias: [
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671316026/Woxo%20Videos/Experiments/Backgrounds/Tiktok/Handcraft/download_11.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671316017/Woxo%20Videos/Experiments/Backgrounds/Tiktok/Handcraft/download_10.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671315999/Woxo%20Videos/Experiments/Backgrounds/Tiktok/Handcraft/download_2.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671315985/Woxo%20Videos/Experiments/Backgrounds/Tiktok/Handcraft/download_4.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671315940/Woxo%20Videos/Experiments/Backgrounds/Tiktok/Handcraft/download_13.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671315931/Woxo%20Videos/Experiments/Backgrounds/Tiktok/Handcraft/download_8.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671315930/Woxo%20Videos/Experiments/Backgrounds/Tiktok/Handcraft/download_5.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671315930/Woxo%20Videos/Experiments/Backgrounds/Tiktok/Handcraft/download_9.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671315930/Woxo%20Videos/Experiments/Backgrounds/Tiktok/Handcraft/download_7.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671315925/Woxo%20Videos/Experiments/Backgrounds/Tiktok/Handcraft/download_6.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671315915/Woxo%20Videos/Experiments/Backgrounds/Tiktok/Handcraft/download_1.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671315914/Woxo%20Videos/Experiments/Backgrounds/Tiktok/Handcraft/download_12.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671315911/Woxo%20Videos/Experiments/Backgrounds/Tiktok/Handcraft/download_3.mp4'
    ]
  },
  {
    tags: ['christmas_tiktok'],
    medias: [
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671316069/Woxo%20Videos/Experiments/Backgrounds/Tiktok/Chrismas%20Tree/download_8.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671316066/Woxo%20Videos/Experiments/Backgrounds/Tiktok/Chrismas%20Tree/download_9.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671316066/Woxo%20Videos/Experiments/Backgrounds/Tiktok/Chrismas%20Tree/download_10.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671316065/Woxo%20Videos/Experiments/Backgrounds/Tiktok/Chrismas%20Tree/download_6.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671316050/Woxo%20Videos/Experiments/Backgrounds/Tiktok/Chrismas%20Tree/download_3.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671316047/Woxo%20Videos/Experiments/Backgrounds/Tiktok/Chrismas%20Tree/download_4.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671316044/Woxo%20Videos/Experiments/Backgrounds/Tiktok/Chrismas%20Tree/download_7.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671316041/Woxo%20Videos/Experiments/Backgrounds/Tiktok/Chrismas%20Tree/download_5.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671316040/Woxo%20Videos/Experiments/Backgrounds/Tiktok/Chrismas%20Tree/download_2.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671316035/Woxo%20Videos/Experiments/Backgrounds/Tiktok/Chrismas%20Tree/download_1.mp4'
    ]
  },
  {
    tags: ['nature_fancy'],
    medias: [
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671905859/Woxo%20Videos/Experiments/Backgrounds/Nature%20and%20Markups/ls08.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671905859/Woxo%20Videos/Experiments/Backgrounds/Nature%20and%20Markups/ls07.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671905858/Woxo%20Videos/Experiments/Backgrounds/Nature%20and%20Markups/ls09.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671905857/Woxo%20Videos/Experiments/Backgrounds/Nature%20and%20Markups/ls06.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671905856/Woxo%20Videos/Experiments/Backgrounds/Nature%20and%20Markups/ls05.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671905856/Woxo%20Videos/Experiments/Backgrounds/Nature%20and%20Markups/ls02.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671905855/Woxo%20Videos/Experiments/Backgrounds/Nature%20and%20Markups/ls04.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671905855/Woxo%20Videos/Experiments/Backgrounds/Nature%20and%20Markups/ls03.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671905855/Woxo%20Videos/Experiments/Backgrounds/Nature%20and%20Markups/ls20.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671905854/Woxo%20Videos/Experiments/Backgrounds/Nature%20and%20Markups/ls01.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671905853/Woxo%20Videos/Experiments/Backgrounds/Nature%20and%20Markups/ls19.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671905852/Woxo%20Videos/Experiments/Backgrounds/Nature%20and%20Markups/ls18.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671905850/Woxo%20Videos/Experiments/Backgrounds/Nature%20and%20Markups/ls17.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671905850/Woxo%20Videos/Experiments/Backgrounds/Nature%20and%20Markups/ls12.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671905850/Woxo%20Videos/Experiments/Backgrounds/Nature%20and%20Markups/ls16.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671905849/Woxo%20Videos/Experiments/Backgrounds/Nature%20and%20Markups/ls15.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671905848/Woxo%20Videos/Experiments/Backgrounds/Nature%20and%20Markups/ls13.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671905845/Woxo%20Videos/Experiments/Backgrounds/Nature%20and%20Markups/ls11.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671905844/Woxo%20Videos/Experiments/Backgrounds/Nature%20and%20Markups/ls10.mp4'
    ]
  },
  {
    tags: ['christmas_merry'],
    medias: [
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671905357/Woxo%20Videos/Experiments/Backgrounds/Chrismas/Christmas_2.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671905353/Woxo%20Videos/Experiments/Backgrounds/Chrismas/nav03.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671905352/Woxo%20Videos/Experiments/Backgrounds/Chrismas/Christmas_3.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671905352/Woxo%20Videos/Experiments/Backgrounds/Chrismas/Christmas_1.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671905350/Woxo%20Videos/Experiments/Backgrounds/Chrismas/nav02.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671905349/Woxo%20Videos/Experiments/Backgrounds/Chrismas/nav01.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1672222144/Woxo%20Videos/Experiments/Backgrounds/Chrismas/n08.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1672222147/Woxo%20Videos/Experiments/Backgrounds/Chrismas/n05.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1672222151/Woxo%20Videos/Experiments/Backgrounds/Chrismas/n06.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1672222152/Woxo%20Videos/Experiments/Backgrounds/Chrismas/n04.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1672222157/Woxo%20Videos/Experiments/Backgrounds/Chrismas/n07.mp4'
    ]
  },
  {
    tags: ['food'],
    medias: [
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671905525/Woxo%20Videos/Experiments/Backgrounds/Food/food11.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671905525/Woxo%20Videos/Experiments/Backgrounds/Food/food10.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671905524/Woxo%20Videos/Experiments/Backgrounds/Food/food09.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671905524/Woxo%20Videos/Experiments/Backgrounds/Food/food08.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671905523/Woxo%20Videos/Experiments/Backgrounds/Food/food02.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671905522/Woxo%20Videos/Experiments/Backgrounds/Food/food07.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671905522/Woxo%20Videos/Experiments/Backgrounds/Food/food05.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671905522/Woxo%20Videos/Experiments/Backgrounds/Food/food04.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671905521/Woxo%20Videos/Experiments/Backgrounds/Food/food06.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1671905521/Woxo%20Videos/Experiments/Backgrounds/Food/food03.mp4'
    ]
  }
];
